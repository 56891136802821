import React from 'react'
import { transaction } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'

import {
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField,
    withStyles
} from '@material-ui/core'

import { Api } from '../../components/shared/main'

import Layout from './components/Layout'
import Loader from '../../components/Loader'
import localize from '../../locale'
import UserNotification from '../../components/UserNotification'
import { disableAutocomplete } from '../../components/uifix'
import { withValidation } from '../../components/withValidation'

import { flow } from 'lodash'
import { resetUserScope, configureScopeWithUser } from '../../sentry'

import styles from './Login.styles'

const validationSchema = {
    email: ['required', 'email'],
    password: ['required']
};

class Login extends React.Component {
    state = {
        email: '',
        password: ''
    }

    componentDidMount() {
        resetUserScope();
    }

    onChange = e => {
        this.props.validation.reset();
        const value = e.target.name === "email" 
            ? e.target.value.toLowerCase() : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    }

    onChangePersist = e => {
        this.props.appState.persist = e.target.checked;
    }

    onChangeLang = e => {
        this.props.appState.lang = e.target.checked ? 'el' : 'en';
    }

    getErrorMessage = message => {
        const locale = localize(this.props.appState.lang).login;

        if (message === 'invalidCredentials') {
            return locale.INVALID_CREDENTIALS;
        }

        return message;
    }

    submit = async e => {
        e.preventDefault();

        if (!this.props.validation.update(this.state)) {
            return;
        }
        
        Loader.show();

        try {   
            const response = await Api.login({
                email: this.state.email,
                password: this.state.password
            });

            if (response.failed) {
                UserNotification.display(this.getErrorMessage(response.failed));
                return;
            }

            // fetch profile

            const api = new Api(response.token, response.profile);

            const profile = await api.getProfile();

            // set user scope

            configureScopeWithUser(profile);

            // update AppState

            transaction(() => {
                if(profile.lang) {
                    this.props.appState.lang = profile.lang;
                }
                this.props.appState.userToken = response.token;
                this.props.appState.profile = profile;
            });

            // redirect to dashboard
            this.props.history.push('/dashboard');
        } finally {
            Loader.hide();
        }
    }

    render() {
        const { classes, validation } = this.props;
        const locale = localize(this.props.appState.lang).login;
        const helperText = validation.errorText(locale.HELP);

        const actions = <Grid item xs={12} className={classes.loginFooter}>
            <Grid container direction="column" justify="center" alignItems="center">
                <p>{locale.FORGOT_PASSWORD}{' '}
                    <Link to="/forgot-password">{locale.FORGOT_PASSWORD_LINK}</Link>
                </p>
                <p>{locale.REGISTER}{' '}
                    <Link to="/register">{locale.REGISTER_LINK}</Link>
                </p>
            </Grid>
        </Grid>

        return <Layout actions={actions}>
            <form noValidate>
                <Grid container justify="center" className={classes.formWrap}>
                    <Grid item xs={12}>
                        <TextField name="email" type="email" required autoFocus
                            value={this.state.email} onChange={this.onChange}
                            label={locale.EMAIL_LABEL}
                            error={validation.error.email}
                            helperText={helperText.email}
                            margin="normal" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="password" type="password" required
                            value={this.state.password} onChange={this.onChange}
                            label={locale.PASSWORD_LABEL}
                            error={validation.error.password}
                            helperText={helperText.password}
                            margin="normal" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup row className={classes.flexRight}>
                            <FormControlLabel label={locale.LANGUAGE_TOGGLE} labelPlacement="start"
                                control={<Switch checked={this.props.appState.lang === 'el'} onChange={this.onChangeLang} color="secondary" />} />
                            <FormControlLabel label={locale.REMEMBER} labelPlacement="start"
                                control={<Switch checked={this.props.appState.persist} onChange={this.onChangePersist} color="secondary" />} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonWrap}>
                        <Button variant="contained"
                            type="submit" onClick={this.submit}>
                            {locale.SUBMIT}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Layout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema),
    disableAutocomplete
])(Login);
