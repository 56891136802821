import React from 'react'
import { debounce, flow } from 'lodash'
import { observer, inject } from 'mobx-react'

import axios from 'axios'

import Autosuggest from 'react-autosuggest'

import {
    Checkbox,
    Collapse,
    Fab,
    FormControlLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from '@material-ui/core'

import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Fastfood as FastFoodIcon,
    PinDrop as PinDropIcon,
    Search as SearchIcon,
    Store as StoreIcon
} from '@material-ui/icons'

import { LoadScript } from '@react-google-maps/api'

import AuthenticatedLayout from '../components/AuthenticatedLayout'
import Map from './Map'
import StoresList from './StoresList'
import StoreDialog from './StoreDialog'
import localize from '../../locale'

import cadhocImg from '../../assets/images/cadhoc-mini.png'
import cardImg from '../../assets/images/card-search.png'
import heroCorpImg from '../../assets/images/herocorp-mini.png'
import voucherImg from '../../assets/images/voucher-mini.png'

import styles from './Affiliates.styles'

const defaultLocation = {
    lat: 37.9755691,
    lng: 23.7361789
};

const autosuggestTheme = {
    input: {
        background: '#fff',
        borderRadius: '20px',
        width: '500px',
        padding: '10px 5px 10px 20px',
        borderWidth: 0,
        color: '#f59100'
    },
    inputOpen: {
        borderRadius: '20px 20px 0 0',
    },
    inputFocused: {
        outline: 'none'
    },
    suggestionsContainer: {
        position: 'absolute',
        background: '#fff',
        zIndex: 9999,
        width: '500px',
        borderRadius: '0 0 20px 20px'
    },
    suggestionsContainerOpen: {
        paddingBottom: '10px'
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    },
    suggestion: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)'
    },
    suggestionHighlighted: {
        background: 'lightgray'
    }
};

const libraries = [];

class Affiliates extends React.Component {
    state = {
        acceptsCadhoc: false,
        acceptsCard: false,
        acceptsVoucher: false,
        mapZoom: 18,
        markers: [],
        searchHereActive: false,
        searchType: '',
        searchKeyword: '',
        showStores: false,
        suggestions: [],
        userLocation: defaultLocation,
        value: '',
    }

    constructor() {
        super();
        this.storeDialog = React.createRef();
    }

    onStoreOpen = store => {
        if (store === null) {
            return;
        }
        this.storeDialog.current.open(store)
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(location => {
            this.setState({
                userLocation: {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude
                }
            });
        });
    }

    onChange = (event, { newValue }) => {
        if (newValue === '') {
            this.setState({
                searchHereActive: true,
                searchType: '',
                searchKeyword: '',
                suggestions: [],
                value: newValue
            });
        } else {
            this.setState({
                value: newValue
            });
        }
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        const type = this.state.suggestions[sectionIndex].section;
        this.setState({
            searchType: type,
            searchKeyword: suggestionValue
        }, this.onUiSearch)
    }

    debounceOnSuggestionsFetch = debounce(({ value }) => {
        this.onSuggestionsFetchRequested(value)
    }, 500, { maxWait: 3000 });

    onSuggestionsFetchRequested = async (value) => {
        if (value.length < 3) {
            return;
        }

        const {
            appState: {
                lang
            }
        } = this.props;

        const serviceUrl = window.env.REACT_APP_ADMIN_SERVICE_URL;
        const url = `${serviceUrl}/api/stores/suggest/${lang}/${value}`;

        const response = await axios.get(url);

        const suggestions = response.data.map(section => {
            return {
                section: section.title,
                suggestions: section.suggestions
            }
        });

        this.setState({
            suggestions
        });
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    onUiSearch = () => {
        const { searchType, searchKeyword } = this.state;
        if (!searchType || !searchKeyword) {
            return null;
        }

        this.onSearch();
    }

    onSearch = async (newSearch = false, currentZoom) => {
        const {
            acceptsCadhoc,
            acceptsCard,
            acceptsVoucher,
            heroCorp,
            searchType,
            searchKeyword,
            userLocation
        } = this.state;

        const bounds = this.map.getBounds();

        const serviceUrl = window.env.REACT_APP_ADMIN_SERVICE_URL;
        const url = `${serviceUrl}/api/stores/find/`;

        const response = await axios.post(url, {
            type: searchType,
            keyword: searchKeyword,
            acceptsCadhoc,
            acceptsCard,
            acceptsVoucher,
            heroCorp,
            origin: {
                latitude: userLocation.lat,
                longitude: userLocation.lng
            },
            bounds: bounds
        });

        this.setState({
            markers: response.data.map(item => {
                return {
                    ...item,
                    lat: parseFloat(item.latitude),
                    lon: parseFloat(item.longitude)
                }
            })
        });

        if (newSearch) {
            this.setState({
                mapZoom: currentZoom
            }, () => this.expandSearch(response.data));
        } else {
            this.expandSearch(response.data);
        }
    }

    expandSearch = data => {
        const { mapZoom } = this.state;
        if (data.length === 0 && mapZoom >= 8) {
            this.setState({
                mapZoom: mapZoom - 2
            }, this.onSearch);
        }
    }

    getSuggestionValue = suggestion => suggestion.name || suggestion;

    renderSuggestion = suggestion => {
        let suggestionIcon = null;

        switch (suggestion.type) {
            case 'categories':
                suggestionIcon = <FastFoodIcon />
                break;
            case 'stores':
                suggestionIcon = <StoreIcon />
                break;
            case 'cities':
                suggestionIcon = <PinDropIcon />
                break;
            case 'tags':
                suggestionIcon = <FastFoodIcon />
                break;
            default:
                suggestionIcon = <StoreIcon />
                break;
        }

        return <ListItem button>
            <ListItemIcon>
                {suggestionIcon}
            </ListItemIcon>
            <ListItemText primary={suggestion.name} />
        </ListItem>
    }

    getSectionSuggestions = item => {
        return item.suggestions.map(x => {
            return { type: item.section, name: x }
        });
    }

    // must be implemented
    renderSectionTitle = item => null;

    openStoresList = () => {
        this.setState({
            showStores: !this.state.showStores
        });
    }

    onChangeCheckbox = (event) => {
        this.setState({
            [event.target.id]: event.target.checked
        }, this.onSearch);
    }

    canSearchHere = () => {
        this.setState({
            searchHereActive: true
        });
    }

    searchHere = () => {
        this.setState({
            searchHereActive: false
        }, () => this.onSearch(true, this.map.getZoomLevel()));
    }

    render() {
        const { appState, classes } = this.props;
        const {
            mapZoom,
            markers,
            suggestions,
            value,
            searchHereActive,
            showStores,
            userLocation
        } = this.state;
        const locale = localize(appState.lang).affiliates;

        const inputProps = {
            placeholder: locale.SEARCH_PLACEHOLDER,
            value,
            onChange: this.onChange
        };

        const key = window.env.REACT_APP_GOOGLE_MAP_KEY;

        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>

                    <h5>{locale.AFFILIATES_TITLE}</h5>

                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <span className={classes.searchTitle}>{locale.SEARCH_TITLE}</span>
                            <div className={classes.searchContainer}>
                                <Autosuggest
                                    multiSection={true}
                                    suggestions={suggestions}
                                    onSuggestionSelected={this.onSuggestionSelected}
                                    onSuggestionsFetchRequested={this.debounceOnSuggestionsFetch}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    renderSectionTitle={this.renderSectionTitle}
                                    getSectionSuggestions={this.getSectionSuggestions}
                                    inputProps={inputProps}
                                    theme={autosuggestTheme}
                                />
                                <Fab onClick={this.onUiSearch} color="secondary" className={classes.searchButton} size="small">
                                    <SearchIcon />
                                </Fab>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={classes.filters}>
                    <div>
                        <FormControlLabel
                            control={<Checkbox
                                style={{ color: "#fff", paddingRight: 0 }}
                                id="acceptsCard"
                                onChange={this.onChangeCheckbox} />}
                            label={<div className={classes.filterLabel}>
                                <img src={cardImg} alt="GoforEat Card" />
                                <span>go for EAT</span>
                            </div>}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                style={{ color: "#fff", paddingRight: 0 }}
                                id="acceptsVoucher"
                                onChange={this.onChangeCheckbox} />}
                            label={<div className={classes.filterLabel}>
                                <img src={voucherImg} alt="Chèque Déjeuner" />
                                <span>Chèque Déjeuner</span>
                            </div>}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                style={{ color: "#fff", paddingRight: 0 }}
                                id="acceptsCadhoc"
                                onChange={this.onChangeCheckbox} />}
                            label={<div className={classes.filterLabel}>
                                <img src={cadhocImg} alt="Cadhoc" />
                                <span>Cadhoc</span>
                            </div>}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                style={{ color: "#fff", paddingRight: 0 }}
                                id="heroCorp"
                                onChange={this.onChangeCheckbox} />}
                            label={<div className={classes.filterLabel}>
                                <img src={heroCorpImg} alt="HeroCorp" />
                                <span>HeroCorp</span>
                            </div>}
                            labelPlacement="end"
                        />
                    </div>
                </div>

            </div>

            <div className={classes.searchHereContainer}
                style={showStores ? { bottom: '35%' } : { bottom: '5%' }}>
                {searchHereActive && <Fab onClick={this.searchHere}
                    variant="extended"
                    size="large"
                    className={classes.searchHereButton}>
                    <SearchIcon />
                    {locale.SEARCH_HERE}
                </Fab>}

                {markers.length > 0 && <Fab
                    onClick={this.openStoresList}
                    color="secondary"
                    className={classes.expandButton}
                    size="small">
                    {showStores ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </Fab>}
            </div>

            <LoadScript googleMapsApiKey={key} libraries={libraries}>
                <Map
                    canSearchHere={this.canSearchHere}
                    ref={ref => this.map = ref}
                    search={this.onSearch}
                    flex={!showStores ? 1 : 3}
                    mapZoom={mapZoom}
                    markers={markers}
                    onClick={this.onClick}
                    userLocation={userLocation}
                    onStoreOpen={this.onStoreOpen}
                    isStoreMap={false} />

                <StoreDialog innerRef={this.storeDialog} appState={appState} />
            </LoadScript>

            <Collapse in={showStores}
                className={showStores ? classes.storesCollapse : null}
                classes={{
                    wrapper: classes.storesCollapseWrapper,
                    wrapperInner: classes.storesCollapseWrapper
                }}>
                <StoresList stores={markers} onStoreOpen={this.onStoreOpen} />
            </Collapse>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(Affiliates);
