import { action, computed, decorate, observable } from 'mobx'

import { find, reject } from 'lodash'

import detectBrowserLanguage from 'detect-browser-language'

const languages = ['el', 'en'];

const browserLang = detectBrowserLanguage().split('-')[0];

class AppState {
    persist = true;

    lang = languages.includes(browserLang) ? browserLang : 'el';

    userToken = null;
    profile = null;

    pushRegistrationAuthToken = null;

    get isAuthenticated() {
        return !!this.userToken;
    }

    get shouldForceAcceptTerms() {
        const profile = this.profile;
        return !!profile && 
            (!profile.terms || !profile.privacy 
                || !profile.terms.active || !profile.privacy.active);
    }

    get shouldSaveLanguage() {
        return !!this.profile && !this.profile.lang;
    }

    addCard(account) {
        this.profile.accounts.push(account);
    }

    toggleCard(account, active) {
        const found = find(this.profile.accounts, 
            x => x.id === account.id);
        found.card.active = active;
    }

    deleteCard(account) {
        this.profile.accounts = reject(this.profile.accounts,
            x => x.id === account.id);
    }

    logout() {
        this.userToken = null;
        this.profile = null;
        this.pushRegistrationAuthToken = null;
    }
}

decorate(AppState, {
    persist: observable,
    lang: observable,
    userToken: observable,
    profile: observable,
    pushRegistrationAuthToken: observable,
    isAuthenticated: computed,
    disableCard: action.bound,
    deleteCard: action.bound,
    logout: action
});

export default AppState;
