export default theme => ({
    container: {
        display: 'block',
        width: '80%',
        [theme.breakpoints.down('520')]: {
            width: '100%',
        },
    },
    listItem: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('520')]: {
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    logoAvatar: {
        margin: 10,
        width: 70,
        height: 70,
        background: '#fff',
        [theme.breakpoints.down('520')]: {
            width: 50,
            height: 50,
        },
    },
    iconAvatar: {
        margin: 10,
        width: 70,
        height: 70,
        background: '#485756',
        color: '#fff',
        [theme.breakpoints.down('520')]: {
            width: 50,
            height: 50,
        },
    },
    itemRoot: {
        padding: 0,
        borderBottom: '2px solid rgb(0,0,0,0.1)'
    },
    primary: {
        fontSize: '22px',
        fontWeight: 'bold',
        lineHeight: 1.4,
        [theme.breakpoints.down('520')]: {
            fontSize: '18px',
        },
    },
    secondary: {
        fontSize: '18px',
        [theme.breakpoints.down('520')]: {
            fontSize: '15px',
        },
    },
    distance: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginRight: 15,
        [theme.breakpoints.down('520')]: {
            fontSize: '20px',
        },
    }
});