import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    withStyles
} from '@material-ui/core'

import {
    AcUnit as DisableCardIcon,
    Cancel as DeleteCardIcon,
    KeyboardArrowRight as ArrowRight
} from '@material-ui/icons'

import Slider from 'react-slick'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'
import { ErrorModal } from '../../components/ui/main'

import AddCardDialog from './components/AddCardDialog'
import { NextArrow, PrevArrow } from './components/Arrows'
import AuthenticatedLayout from '../components/AuthenticatedLayout'
import ConfirmDialog from '../../components/ConfirmDialog'
import CreditCard from './components/ManageCreditCard'
import Loader from '../../components/Loader'
import localize from '../../locale'
import UserNotification from '../../components/UserNotification'

import styles from './ManageCards.styles'
import headingImageSun from '../../assets/images/graphic-sun.png'
import headingImageWhiteCloud from '../../assets/images/graphic-whitecloud.png'
import headingImageOrangeCloud from '../../assets/images/graphic-orangecloud.png'

import {
    AddCircleOutline as AddIcon,
    //Wifi as DisableContactlessIcon
} from '@material-ui/icons'

class ManageCards extends React.Component {
    state = {
        cardIndex: 0,
        accounts: []
    }

    constructor(props) {
        super(props);

        this.addCardDialog = React.createRef();
        this.confirmDialog = React.createRef();
        this.slider = React.createRef();
    }

    componentDidMount = async () => {
        const accounts = await this.getAccounts();
        let cardIndex = 0;

        if (this.props.match.params.active === 'add') {
            cardIndex = accounts.length;
        }

        this.setState({ accounts, cardIndex }, () => {
            this.slider.current.slickGoTo(cardIndex);
        });
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.match.params.active !== prevProps.match.params.active) {
            const { length } = this.state.accounts;
            if (this.props.match.params.active === 'add') {
                this.slider.current.slickGoTo(length);
            } else {
                this.slider.current.slickGoTo(0);
            }
        }
    }

    getAccounts = async () => {
        try {
            Loader.show();
            const api = new Api(this.props.appState);
            const profile = await api.getProfile();
            return profile.accounts;
        } finally {
            Loader.hide();
        }
    }

    getErrorMessage = (code) => {
        const locale = localize(this.props.appState.lang).manageCards;

        if (code === 'AS03') {
            return locale.AS03_ACCOUNT_ALREADY_USED;
        }

        if (code === 'AS06') {
            return locale.AS06_NO_USER_FOUND;
        }

        if (code === 'AS11') {
            return locale.AS11_CARD_BLOCKED;
        }

        return locale.UNEXPECTED_ERROR;
    }

    getCurrentCard = () => {
        if (this.state.cardIndex >= this.state.accounts.length) {
            return null;
        }

        return this.state.accounts[this.state.cardIndex];
    }

    adjustRoute = (prev, next) => {
        if (next >= this.state.accounts.length) {
            this.props.history.push('/manage/add');
        } else {
            this.props.history.push('/manage');
        }
    }

    onCardChange = cardIndex => {
        this.setState({ cardIndex });
    }

    onToggleCurrentCard = async active => {
        const appState = this.props.appState;
        const api = new Api(appState);
        const account = this.getCurrentCard();

        Loader.show();

        try {
            const response = await api.toggleCard(account.card, active);
            appState.toggleCard(account, response.active);

            this.setState({
                accounts: appState.profile.accounts
            });
        } finally {
            Loader.hide();
        }
    }

    onToggleContactless = async active => {
        const appState = this.props.appState;
        const api = new Api(appState);
        const account = this.getCurrentCard();

        Loader.show();

        try {
            const response = await api.toggleCard(account.card, active);
            appState.toggleCard(account, response.active);

            this.setState({
                accounts: appState.profile.accounts
            });
        } finally {
            Loader.hide();
        }
    }

    onDeleteCurrentCard = async () => {
        const appState = this.props.appState;
        const locale = localize(appState.lang).manageCards;

        this.confirmDialog.current.open(async model => {
            const api = new Api(appState);
            const account = this.getCurrentCard();

            Loader.show();

            try {
                await api.deleteAccount(account);
                appState.deleteCard(account);

                UserNotification.display(locale.CARD_DELETE_SUCCESS);
                this.setState({
                    accounts: appState.profile.accounts
                });
            } finally {
                Loader.hide();
            }
        }, locale.DELETE_CARD_TITLE, locale.DELETE_CARD_QUESTION);
    }

    onAddCard = () => {
        this.addCardDialog.current.open(async model => {
            const appState = this.props.appState;
            const api = new Api(appState);
            const locale = localize(appState.lang).manageCards;

            Loader.show();

            try {
                const account = await api.addCard(model);
                appState.addCard(account);

                UserNotification.display(locale.CARD_ADD_SUCCESS);

                this.setState({
                    accounts: appState.profile.accounts
                });

                return true;
            } catch (error) {
                if (error.code) {
                    UserNotification.display(this.getErrorMessage(error.code));
                } else {
                    ErrorModal.invoke(error);
                }

                return false;
            } finally {
                Loader.hide();
            }
        });
    }

    renderCard = account => {
        const { classes } = this.props;
        const { card } = account;
        // first element here must be div for Slider to work
        return <div key={account.id} className={classes.slideWrap}>
            <CreditCard
                type={account.product}
                name={card.name}
                number={card.pan}
                expiry={card.expire}
                active={card.active}
            />
        </div>
    }

    renderAddCard = () => {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).manageCards;
        return <div>
            <div className={classes.newCardWrap}>
                <Button onClick={this.onAddCard}>
                    <div className={classes.addCard}>
                        <AddIcon className={classes.addIcon} />
                        <h4>{locale.ADD_CARD}</h4>
                    </div>
                </Button>
            </div>
        </div>
    }

    renderCardActions = () => {
        const account = this.getCurrentCard();

        if (!account) {
            return null;
        }

        const locale = localize(this.props.appState.lang).manageCards;
        const isActive = account.card.active;
        const toggleLabel = isActive
            ? locale.labels.DISABLE
            : locale.labels.ENABLE;
        // const toggleContactlessLbl = isActive
        //     ? locale.labels.DISABLE_CONTACTLESS
        //     : locale.labels.ENABLE_CONTACTLESS;

        return <List>
            <ListItem button onClick={() => this.onToggleCurrentCard(!isActive)}>
                <ListItemIcon>
                    <DisableCardIcon />
                </ListItemIcon>
                <ListItemText primary={toggleLabel} />
                <ArrowRight />
            </ListItem>
            {/* <ListItem button onClick={() => this.onToggleContactless(!isActive)}>
                <ListItemIcon>
                    <DisableContactlessIcon />
                </ListItemIcon>
                <ListItemText primary={toggleContactlessLbl} />
                <ArrowRight />
            </ListItem> */}
            <ListItem button onClick={() => this.onDeleteCurrentCard()}>
                <ListItemIcon>
                    <DeleteCardIcon />
                </ListItemIcon>
                <ListItemText primary={locale.labels.DELETE} />
                <ArrowRight />
            </ListItem>
        </List>
    }

    render() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).manageCards;
        const settings = {
            dots: true,
            infinite: true,
            draggable: false,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            beforeChange: this.adjustRoute
        };

        return <AuthenticatedLayout>
            <div>
                <div className={classes.headingRow}>
                    <div className={classes.container}>
                        <h5>{locale.MANAGE_CARDS}</h5>

                        <Slider {...settings} afterChange={this.onCardChange} ref={this.slider}>
                            {this.state.accounts.map(this.renderCard)}
                            {this.renderAddCard()}
                        </Slider>

                        <div className={classes.graphicSun}>
                            <img src={headingImageSun} alt="" />
                        </div>
                        <div className={classes.graphicWhiteCloud}>
                            <img src={headingImageWhiteCloud} alt="" />
                        </div>
                        <div className={classes.graphicOrangeCloud}>
                            <img src={headingImageOrangeCloud} alt="" />
                        </div>
                    </div>
                </div>
                <div className={classes.mainRow}>
                    <div className={classes.container}>
                        {this.renderCardActions()}
                    </div>
                </div>
            </div>
            <ConfirmDialog innerRef={this.confirmDialog} />
            <AddCardDialog innerRef={this.addCardDialog} />
        </AuthenticatedLayout>
    }
}

export default flow([
    withStyles(styles),
    observer,
    inject('appState'),
])(ManageCards);
