import React from 'react'
import ReactDOM from 'react-dom'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    paper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        position: 'fixed',
        top: 0,
        backgroundColor: '#808080bf',
        // eslint-disable-next-line
        backgroundColor: 'rgba(128, 128, 128, 0.51)',
        zIndex: 9999,
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

const modalRoot = document.getElementById('loader');

class Loader extends React.Component {
    state = {
        completed: 0,
        isOpen: false
    }

    componentDidMount() {
        this.timer = setInterval(this.progress, 20);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    progress = () => {
        const { completed } = this.state;
        this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
    }

    constructor(props) {
        super(props);

        modalRoot.addEventListener('show', () => {
            this.setState({
                isOpen: true
            });
        });

        modalRoot.addEventListener('hide', () => {
            this.setState({
                isOpen: false
            });
        });
    }

    static show() {
        modalRoot.dispatchEvent(new CustomEvent('show', {}));
    }

    static hide() {
        modalRoot.dispatchEvent(new CustomEvent('hide', {}));
    }

    render() {
        const { classes } = this.props;
        const modal = this.state.isOpen && <div className={classes.paper}>
            <CircularProgress
                size={60}
                thickness={4.5}
                className={classes.progress}
                variant="determinate"
                value={this.state.completed}
            />
        </div>

        return ReactDOM.createPortal(modal, modalRoot);
    }
}

export default withStyles(styles)(Loader);
