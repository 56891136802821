import * as Sentry from '@sentry/browser'
import React, { Component } from 'react'
import { Switch, Redirect, Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'

import { CssBaseline, MuiThemeProvider, withStyles } from '@material-ui/core'

import DateFnsUtils from '@date-io/date-fns'
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'

import { Api, AppState } from './components/shared/main'
import configure from './components/ui/bootstrap'
import persist from './components/mobx/persist'
import { ErrorModal } from './components/ui/main'

import Loader from './components/Loader'
import localize from './locale'
import UserNotification from './components/UserNotification'
import Views from './views'

import { flow } from 'lodash'

import initializeSentry, { resetUserScope } from './sentry'
import theme from './theme'
import styles from './App.styles'

initializeSentry();

const AuthenticatedRoute = ({ component: Component, isAuthenticated, ...other }) => {
    return <Route {...other} render={props => isAuthenticated || props.location.pathname === '/login'
        ? <Component {...props} />
        : <Redirect to="/login" />} />
}

const PublicOnlyRoute = ({ component: Component, isAuthenticated, ...other }) => {
    return <Route {...other} render={props => !isAuthenticated && props.location.pathname !== '/dashboard'
        ? <Component {...props} />
        : <Redirect to="/dashboard" />} />
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

class App extends Component {
    appState = persist(new AppState());

    state = {
        hasError: false
    }

    constructor(props) {
        super(props);
        configure.axiosInterceptors(this.onError, this.onUnauthorized);
    }

    onError = error => {
        ErrorModal.invoke(error);
    }

    onUnauthorized = async config => {
        const { appState } = this;

        if(appState.isAuthenticated && config.retryCount === 0) {
            try {
                const token = await Api.refreshToken({
                    email: appState.profile.email,
                    refreshToken: appState.userToken.refresh
                });

                if (token) {
                    appState.userToken = token;
                    if(config.headers.Authorization) {
                        config.headers.Authorization = `Bearer ${token.value}`;
                    } else if(config.headers['X-UP-BEARER-TOKEN']) {
                        config.headers['X-UP-BEARER-TOKEN'] = token.value;
                    }
                    return config;
                }
            } catch (e) {
                // Catch failed req/exc, if refresh token is deleted
            }
        }
        
        const locale = localize(appState.lang).session;
        
        UserNotification.display(locale.EXPIRED);
        
        appState.logout();
        
        resetUserScope();
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        Sentry.captureException(error);
    }

    renderRoutes() {
        const { appState } = this;
        const { isAuthenticated } = appState;
        return <Switch>
            <Redirect exact from='/' to={isAuthenticated ? '/dashboard' : '/login'} />

            <PublicOnlyRoute exact path='/register' component={Views.Register} isAuthenticated={isAuthenticated} />
            <PublicOnlyRoute exact path='/login' component={Views.Login} isAuthenticated={isAuthenticated} />
            <PublicOnlyRoute exact path='/forgot-password' component={Views.ForgotPassword} isAuthenticated={isAuthenticated} />
            <PublicOnlyRoute exact path='/forgot-password/:userId/:hash' component={Views.ForgotPasswordComplete} isAuthenticated={isAuthenticated} />

            <AuthenticatedRoute exact path='/account' component={Views.Account} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/account/change/email' component={Views.ChangeEmail} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/account/change/password' component={Views.ChangePassword} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/account/change/language' component={Views.ChangeLanguage} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/account/change/securityQuestion' component={Views.ChangeSecurityQuestion} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/dashboard' component={Views.Dashboard} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/manage/:active?' component={Views.ManageCards} isAuthenticated={isAuthenticated} />

            <AuthenticatedRoute exact path='/contact' component={Views.Contact} isAuthenticated={isAuthenticated} />
            <AuthenticatedRoute exact path='/faq' component={Views.Faq} isAuthenticated={isAuthenticated} />

            <Route exact path='/affiliates' component={Views.Affiliates} />
            <Route exact path='/privacy-policy' component={Views.PrivacyPolicy} />
            <Route exact path='/terms-card' component={Views.TermsCard} />
            <Route exact path='/terms-app' component={Views.TermsApp} />

            <Route exact path='/activate/:userId/:hash' component={Views.Activate} />
            <Route component={Views.NotFound} />
        </Switch>
    }

    render() {
        if (this.state.hasError) {
            return <Views.Error />
        }

        const { appState } = this;
        const locale = localize(appState.lang);
        const errorModalConfig = {
            networkError: locale.networkError.ALERT,
            unknownError: locale.errorAlert.SUBTITLE 
        };

        return <Provider appState={appState}>
            <MuiThemeProvider theme={theme}>
                <BrowserRouter basename={baseUrl}>
                    <CssBaseline>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {this.renderRoutes()}
                            <Loader />
                            <ErrorModal messages={errorModalConfig}/>
                            <UserNotification />
                        </MuiPickersUtilsProvider>
                    </CssBaseline>
                </BrowserRouter>
            </MuiThemeProvider>
        </Provider>
    }
}

export default flow([
    observer,
    withStyles(styles)
])(App);
