import React from 'react'

import arrowRight from '../../../assets/images/ico-arrow-right.png'
import arrowLeft from '../../../assets/images/ico-arrow-left.png'

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", width: 25, height: 38, backgroundImage: "url(" + arrowRight + ")" }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", width: 25, height: 38, backgroundImage: "url(" + arrowLeft + ")" }}
            onClick={onClick}
        />
    );
}

export {
    NextArrow,
    PrevArrow
}