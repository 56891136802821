import axios from 'axios'
import { last } from 'lodash'
import qs from 'qs'
import { format as formatDate } from 'date-fns'

import { encode as btoa } from 'base-64'

const baseUrl = window.env.REACT_APP_API_URL || window.env.REACT_NATIVE_API_URL;

const contentTypeApplicationJson = { 'Content-Type': 'application/json' };
const contentTypeFormUrlEncoded = { 'Content-Type': 'application/x-www-form-urlencoded' };

const apiCredentials = {
    username: window.env.REACT_APP_API_USER || window.env.REACT_NATIVE_API_USER,
    password: window.env.REACT_APP_API_PASSWORD || window.env.REACT_NATIVE_API_PASSWORD
};

const apiKey = {
    api_key: window.env.REACT_APP_API_KEY || window.env.REACT_NATIVE_API_KEY
}

const getAppAccess = function () {
    let token = null;
    let expiry = null;
    
    const isAuthenticated = () => {
        return !!token && Date.now() < expiry;
    }

    const fetch = async () => {
        if(!isAuthenticated()) {
            const url = `${baseUrl}/v1/tokens?grant_type=client_credentials&scope=mobileopen`;

            const payload = {};

            const response = await axios.post(url, payload, {
                headers: {
                    ...apiKey,
                    ...contentTypeFormUrlEncoded            
                },
                auth: apiCredentials,
                handleStatus: 401
            });

            const json = response.data;

            token = json.access_token;
            expiry = Date.now() + json.expires_in * 1000;
        }

        return token;
    }

    return fetch;
}();

const getAppBearer = async () => {
    const appAccess = await getAppAccess();
    const encoded = btoa(appAccess);
    return { Authorization: `Bearer ${encoded}` };
}

const parseUserId = link => {
    return parseInt(last(link.split('/')));
}

class Api {
    static getSecurityQuestions = async () => {
        const url = `${baseUrl}/v1/security_questions`;

        const config = {
            headers: {
                ...apiKey,
                ...await getAppBearer()
            }
        };

        const response = await axios.get(url, config);

        return response.data;
    }

    static register = async model => {
        try {
            const url = `${baseUrl}/v1/app_logins`;

            const payload = {
                email: model.email,
                pan: model.cardNumber.replace(/\s/g, ''),
                code: model.cardExpiry.replace('/', ''),
                password: model.password,
                security_question: {
                    id: model.securityQuestion,
                    answer: model.securityAnswer
                }
            };

            const config = {
                headers: {
                    ...apiKey,
                    ...contentTypeApplicationJson,
                    ...await getAppBearer()
                },
                handleStatus: 400
            };

            const response = await axios.post(url, payload, config);
        
            return response.data;
        } catch (exc) {
            if (exc.response && exc.response.data && exc.response.data.error_code) {
                const error = new Error('Registration failed');
                error.code = exc.response.data.error_code;
                throw error;
            }

            throw exc;
        }
    }

    static activate = async (userId, hash) => {
        const url = `${baseUrl}/v1/app_logins/${userId}`;

        const payload = {
            hash,
            active: true
        };

        const config = {
            headers: {
                ...apiKey,
                ...contentTypeApplicationJson,
                ...await getAppBearer()
            },
            handleStatus: [400, 404]
        }

        const response = await axios.put(url, payload, config);

        return response;
    }

    static login = async credentials => {
        const { email, password } = credentials;
        const unauthorized = [401, 403];

        try {
            const url = `${baseUrl}/v1/tokens`;

            const payload = qs.stringify({
                grant_type: 'password',
                scope: 'mobile',
                username: email,
                password: password
            });

            const config = {
                headers: {
                    ...apiKey,
                    ...contentTypeFormUrlEncoded,
                    ...await getAppBearer()
                },
                auth: apiCredentials,
                handleStatus: unauthorized
            }

            const response = await axios.post(url, payload, config);
            
            const token = response.data;
            const userId = parseUserId(response.data.link);

            return { 
                token: {
                    value: token.access_token,
                    refresh: token.refresh_token,
                    expires: Date.now() + token.expires_in * 1000
                },
                profile: {
                    id: userId,
                    email
                }
            };
        } catch(error) {
            if(error.response && unauthorized.includes(error.response.status)) {
                return { failed: 'invalidCredentials' };
            }

            throw error;
        }
    }

    static refreshToken = async (model) => {
        const { email, refreshToken } = model;

        const url = `${baseUrl}/v1/tokens`;

        const payload = qs.stringify({
            grant_type: 'refresh_token',
            scope: 'mobile',
            username: email,
            refresh_token: refreshToken
        });

        const config = {
            headers: {
                ...apiKey,
                ...contentTypeFormUrlEncoded,
                ...await getAppBearer()
            },
            auth: apiCredentials,
            handleStatus: 403
        }

        try {
            const response = await axios.post(url, payload, config);

            const token = response.data;

            return {
                value: token.access_token,
                refresh: token.refresh_token,
                expires: Date.now() + token.expires_in * 1000
            };
        } catch (error) {
            if(error.response && error.response.status === 403) {
                return null;
            }

            throw error;
        }
    }

    static forgotPassword = async model => {
        try {
            const url = `${baseUrl}/v2/app_passwords`;

            const payload = {
                email: model.email
            };

            const config = {
                headers: {
                    ...apiKey,
                    ...contentTypeApplicationJson,
                    ...await getAppBearer()
                },
                handleStatus: 400
            };

            const response = await axios.post(url, payload, config);
            return response.data;
        } catch (exc) {
            if (exc.response && exc.response.data && exc.response.data.error_code) {
                const error = new Error('ForgotPassword failed');
                error.code = exc.response.data.error_code;
                throw error;
            }

            throw exc;
        }
    }

    static forgotPasswordComplete = async (userId, hash, model) => {
        try {
            const url = `${baseUrl}/v2/app_passwords/${userId}`;

            const payload = {
                auth_password: hash,
                new_password: model.password
            };

            const config = {
                headers: {
                    ...apiKey,
                    ...contentTypeApplicationJson,
                    ...await getAppBearer()
                },
                handleStatus: 400
            };

            const response = await axios.put(url, payload, config);

            return response.data;
        } catch (exc) {
            if (exc.response && exc.response.data && exc.response.data.error_code) {
                const error = new Error('ForgotPasswordComplete failed');
                error.code = exc.response.data.error_code;
                throw error;
            }

            throw exc;
        }
    }

    static contactRequest = async model => {
        const serviceUrl = window.env.REACT_APP_NOTIFICATIONS_SERVICE_URL || window.env.REACT_NATIVE_NOTIFICATIONS_SERVICE_URL;
        const url = `${serviceUrl}/api/v2/contact`

        const payload = {
            phone: model.phone,
            message: model.message
        };

        const response = await axios.post(url, payload, {
            headers: {
                ...contentTypeApplicationJson
            }
        });

        return response.data;
    }

    constructor(...args) {
        if(args.length === 2) {
            this.token = args[0];
            this.profile = args[1];
        } else {
            this.token = args[0].userToken;
            this.profile = args[0].profile;
        }
    }

    getUserBearer = () => {
        const encoded = btoa(this.token.value);
        return { Authorization: `Bearer ${encoded}` };
    }

    getUserHeaders = () => {
        return {
            ...apiKey,
            ...this.getUserBearer()
        }
    }

    getUserConfig = () => {
        return {
            headers: this.getUserHeaders()
        }
    }

    getProfile = async () => {
        const url = `${baseUrl}/v2/beneficiaries/${this.profile.id}`;

        const config = this.getUserConfig();

        const response = await axios.get(url, config);

        return response.data;
    }

    updateProfile = async (payload, extraConfig) => {
        const url = `${baseUrl}/v2/beneficiaries/${this.profile.id}`;

        const config = {
            ...this.getUserConfig(),
            ...extraConfig
        }

        const response = await axios.put(url, payload, config);

        return response.data;
    }

    changeLanguage = lang => {
        return this.updateProfile({ 
            lang
        });
    }

    changeName = model => {
        const payload = {
            first_name: model.firstName,
            last_name: model.lastName
        };

        return this.updateProfile(payload);
    }

    changePassword = async model => {
        const payload = {
            current_password: model.password,
            new_password: model.newPassword
        };

        return this.updateProfile(payload, {
            handleStatus: 400
        });
    }

    changeEmail = model => {
        const payload = {
            email: model.email
        };

        return this.updateProfile(payload, {
            handleStatus: [400, 409]
        });
    }

    changeSecurityQuestion = model => {
        const payload = {
            security_question: {
                id: model.securityQuestion,
                answer: model.securityAnswer
            }
        };

        return this.updateProfile(payload);
    }

    changePermission = (permission, active) => {
        return this.updateProfile({
            [permission]: {
                active,
                date: active ? new Date().toISOString() : null
            }
        });
    }

    getAccount = async (accountId) => {
        const url = `${baseUrl}/v2/accounts/${accountId}`;

        const config = this.getUserConfig();

        const response = await axios.get(url, config);

        return response.data;
    }

    addCard = async card => {
        try {

            const url = `${baseUrl}/v2/accounts`;

            const payload = {
                card: {
                    pan: card.cardNumber.replace(/\s/g, ''),
                    expire: card.cardExpiry.replace('/', ''),
                }
            };

            const config = {
                ...this.getUserConfig(),
                handleStatus: 400
            }

            const response = await axios.post(url, payload, config);

            return response.data;
        } catch (exc) {
            if (exc.response && exc.response.data && exc.response.data.error_code) {
                const error = new Error('AddCard failed');
                error.code = exc.response.data.error_code;
                throw error;
            }

            throw exc;
        }
    }

    toggleCard = async (card, active) => {
        const url = `${baseUrl}/v1/cards/${card.id}`;

        const payload = {
            active: !!active
        };

        const config = {
            headers: {
                ...this.getUserHeaders(),
                ...contentTypeApplicationJson
            }
        }

        const response = await axios.put(url, payload, config);

        return response.data;
    }

    deleteAccount = async account => {
        const url = `${baseUrl}/v2/accounts/${account.id}`;

        const config = this.getUserConfig();

        await axios.delete(url, config);
    }

    getTransactions = async (accountId, options) => {
        let url = `${baseUrl}/v1/beneficiary_transactions/search?account_id=${accountId}&transaction_type=BOTH`;

        if(options.page) {
            url += `&page=${options.page}`;
        }

        if(options.perPage) {
            url += `&per_page=${options.perPage}`;
        }

        if(options.startDate) {
            const dt = formatDate(options.startDate, 'yyyyMMdd');
            url += `&start_date=${dt}`;
        }

        if(options.endDate) {
            const dt = formatDate(options.endDate, 'yyyyMMdd');
            url += `&end_date=${dt}`;
        }

        const config = this.getUserConfig();

        const response = await axios.get(url, config);

        return response.data;
    }

    getHeroCorpRewards = async accountIds => {
        const config = {
            headers: {
                'X-UP-BEARER-TOKEN': this.token.value,
                'X-UP-USER-ID': this.profile.id
            }
        };
        const url = `${window.env.REACT_APP_NOTIFICATIONS_SERVICE_URL}/api/loyalty/rewards?accountIds=${accountIds}`;
        const response = await axios.get(url, config);
        return response.data;
    }
}

export default Api;