import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    FormGroup,
    TextField,
    withStyles
} from '@material-ui/core'

import {
    Email as AccountEmailIcon
} from '@material-ui/icons'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'
import { ErrorModal } from '../../components/ui/main'

import AuthenticatedLayout from '../components/AuthenticatedLayout'
import Loader from '../../components/Loader'
import localize from '../../locale'
import NotificationDialog from '../components/NotificationDialog'
import { disableAutocomplete } from '../../components/uifix'
import { withValidation } from '../../components/withValidation'

import styles from './Account.styles'

const validationSchema = {
    currentEmail: ['required'],
    email: ['required', 'email', { mismatch: 'currentEmail', maxLength: 54 }]
}

class ChangeEmail extends React.Component {
    state = {
        currentEmail: this.props.appState.profile.email,
        email: ''
    }

    constructor(props) {
        super(props);
        this.notificationDialog = React.createRef();
    }

    onChange = e => {
        const value = e.target.name === "email" 
            ? e.target.value.toLowerCase() : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    }

    submit = async e => {
        e.preventDefault();

        if (!this.props.validation.update(this.state)) {
            return;
        }

        const appState = this.props.appState;
        const locale = localize(appState.lang).verifyEmail;
        const api = new Api(appState);

        Loader.show();

        try {           
            const profile = await api.changeEmail({
                email: this.state.email
            });

            this.notificationDialog.current.open(() => {
                appState.profile = profile;

                this.props.history.push('/account');
            }, locale.VERIFY_EMAIL_TITLE, locale.VERIFY_EMAIL_TEXT);
        } catch (error) {
            if (error.response.status === 409) {
                this.notificationDialog.current.open(() => {},
                    locale.EMAIL_ALREADY_REGISTERED_TITLE, locale.EMAIL_ALREADY_REGISTERED_CONTENT);
                return;
            }

            ErrorModal.invoke(error);
        } finally {
            Loader.hide();
        }
    }

    onCancel = () => this.props.history.goBack();

    render() {
        const { classes, validation } = this.props;
        const locale = localize(this.props.appState.lang).account;
        const helperText = validation.errorText(locale.HELP);
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>
                    <h5>{locale.CHANGE_EMAIL}</h5>
                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <AccountEmailIcon className={classes.accountIconFull} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mainRow}>
                <div className={classes.container}>
                    <div className={classes.containerNarrow}>
                        <form noValidate>
                            <FormGroup className={classes.formControl}>
                                <TextField name="email" type="email" required autoFocus
                                    label={locale.NEW_EMAIL} margin="normal"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    error={validation.error.email}
                                    helperText={helperText.email} />
                            </FormGroup>
                            <div className={classes.buttonsWrap}>
                                <Button className={classes.buttonReset} onClick={this.onCancel}>{locale.CANCEL}</Button>
                                <Button type="submit" className={classes.buttonSubmit} onClick={this.submit}>{locale.SUBMIT}</Button>

                                <NotificationDialog innerRef={this.notificationDialog} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema),
    disableAutocomplete
])(ChangeEmail);
