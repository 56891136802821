import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    FormGroup,
    TextField,
    withStyles
} from '@material-ui/core'

import {
    Lock as AccountLockIcon,
} from '@material-ui/icons'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'
import { ErrorModal } from '../../components/ui/main'

import AuthenticatedLayout from '../components/AuthenticatedLayout'
import Loader from '../../components/Loader'
import localize from '../../locale'
import UserNotification from '../../components/UserNotification'
import { disableAutocomplete } from '../../components/uifix'
import { withValidation } from '../../components/withValidation'

import styles from './Account.styles'

const validationSchema = {
    password: 'required',
    newPassword: ['required', 'password'],
    newPasswordConfirm: ['required', 'password', { match: 'newPassword' }]
}

class ChangePassword extends React.Component {
    state = {
        password: '',
        newPassword: '',
        newPasswordConfirm: ''
    }

    componentDidMount() {
        
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getErrorMessage = code => {
        const locale = localize(this.props.appState.lang).account;

        if (code === 'BS02') {
            return locale.BS02_INCORRECT_PASSWORD;
        }

        if (code === 'BS03') {
            return locale.BS03_INVALID_PASSWORD;
        }

        if (code === 'BS04') {
            return locale.BS04_PASSWORD_ALREADY_USED;
        }

        return locale.UNEXPECTED_ERROR;
    }

    submit = async e => {
        e.preventDefault();
        
        if (!this.props.validation.update(this.state)) {
            return;
        }

        const appState = this.props.appState;
        const locale = localize(appState.lang).account;
        const api = new Api(appState);

        Loader.show();

        try {
            await api.changePassword({
                password: this.state.password,
                newPassword: this.state.newPassword
            });

            UserNotification.display(locale.PASSWORD_CHANGE_SUCCESS);

            this.props.history.push('/account');
        } catch (error) {
            if (error.response.status === 400) {
                UserNotification.display(this.getErrorMessage(error.response.data.error_code));
                return;
            }

            ErrorModal.invoke(error);
        } finally {
            Loader.hide();
        }
    }

    onCancel = () => this.props.history.goBack();

    render() {
        const { appState, classes, validation } = this.props;
        const locale = localize(appState.lang).account;
        const helperText = validation.errorText(locale.HELP);
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>
                    <h5>{locale.CHANGE_PASSWORD}</h5>
                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <AccountLockIcon className={classes.accountIconFull} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mainRow}>
                <div className={classes.container}>
                    <div className={classes.containerNarrow}>
                        <form noValidate>
                            <FormGroup className={classes.formControl}>
                                <TextField name="password" type="password" required autoFocus
                                    label={locale.OLD_PASSWORD} margin="normal"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    error={validation.error.password}
                                    helperText={helperText.password} />

                                <TextField name="newPassword" type="password" required
                                    label={locale.NEW_PASSWORD} margin="normal"
                                    value={this.state.newPassword}
                                    onChange={this.onChange}
                                    error={validation.error.newPassword}
                                    helperText={helperText.newPassword} />

                                <TextField name="newPasswordConfirm" type="password" required
                                    label={locale.REPEAT_NEW_PASSWORD} margin="normal"
                                    value={this.state.newPasswordConfirm}
                                    onChange={this.onChange}
                                    error={validation.error.newPasswordConfirm}
                                    helperText={helperText.newPasswordConfirm} />
                            </FormGroup>
                            <div className={classes.buttonsWrap}>
                                <Button className={classes.buttonReset} onClick={this.onCancel}>{locale.CANCEL}</Button>
                                <Button type="submit" className={classes.buttonSubmit} onClick={this.submit}>{locale.SUBMIT}</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema),
    disableAutocomplete
])(ChangePassword);
