import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel
} from '@material-ui/core'
import { flow } from 'lodash'
import { observer, inject } from 'mobx-react'
import React from 'react'

import { Api } from '../../components/shared/main'
import Loader from '../../components/Loader'
import localize from '../../locale'

class ForceAcceptTermsDialog extends React.Component {
    state = {
        terms: { active: false },
        privacy: { active: false }
    }

    componentDidMount() {
        const { appState } = this.props;
        
        this.setState({
            terms: appState.terms || { active: false },
            privacy: appState.privacy || { active: false }
        });
    }

    onChange = e => {
        this.setState({
            [e.target.name]: {
                active: e.target.checked,
                date: e.target.checked ? new Date().toISOString() : null
            }
        });
    }

    onSubmit = async () => {
        Loader.show();

        try {
            const api = new Api(this.props.appState);
            this.props.appState.profile = await api.updateProfile({
                terms: this.state.terms,
                privacy: this.state.privacy
            });
        } finally {
            Loader.hide();
        }
    }

    render() {
        const locale = localize(this.props.appState.lang).forceAcceptTerms;

        return <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={null}>
            <DialogTitle>{locale.TITLE}</DialogTitle>
            <DialogContent>
                <p>{locale.PRETEXT}</p>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.terms.active}
                            onChange={this.onChange}
                            name="terms"
                        />
                    }
                    label={locale.ACCEPT_TERMS_LABEL}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.privacy.active}
                            onChange={this.onChange}
                            name="privacy"
                        />
                    }
                    label={locale.ACCEPT_PRIVACY_POLICY_LABEL}
                />
            </DialogContent>
            <DialogActions>
                <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                    <Button variant="contained" color="primary" 
                        disabled={!this.state.terms.active || !this.state.privacy.active}
                        onClick={this.onSubmit}>{locale.SUBMIT}</Button>
                </div>
            </DialogActions>
        </Dialog>
    }
}

export default flow([
    observer,
    inject('appState')
])(ForceAcceptTermsDialog);
