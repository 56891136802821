import React from 'react'
import { observer } from 'mobx-react'

import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'

const styles = theme => ({

});

class NotificationDialog extends React.Component {
    state = {
        open: false,
        title: '',
        content: ''
    }

    open = (onClose, title, content) => {
        this.setState({
            open: true,
            onClose,
            title,
            content
        });
    }

    close = () => {
        this.setState({
            open: false,
            title: '',
            content: ''
        });

        if(this.state.onClose) {
            this.state.onClose();
        }
    }

    render() {
        return <Dialog open={this.state.open} onClose={this.close}> 
            <DialogTitle>{this.state.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {this.state.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.close} variant="contained" color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    }   
}

export default flow([
    observer,
    withStyles(styles)
])(NotificationDialog);