import React from 'react'
import ReactDOM from 'react-dom'

import { 
    IconButton,
    Snackbar,
    SnackbarContent,
    withStyles
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/CloseOutlined'

import { flow } from 'lodash'

const styles = theme => ({
    action: {
        color: '#fff'
    }
});

const root = document.getElementById('user-notification');

class UserNotification extends React.Component {
    state = {
        open: false,
        message: ''
    }

    constructor(props) {
        super(props);
        
        this.state = {
            message: props.message,
            open: props.open
        }

        root.addEventListener('display', e => {
            this.setState({ 
                open: true,
                message: e.detail
            });
        });
    }

    onClose = () => {
        this.setState({
            open: false
        });
    }

    render() {
        const { classes } = this.props;

        const content = <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.open}
            onClose={this.onClose}
            message={this.state.message}>
            <SnackbarContent message={this.state.message}
                action={<IconButton onClick={this.onClose} className={classes.action}>
                    <CloseIcon />
                </IconButton>} />                
        </Snackbar>

        return ReactDOM.createPortal(content, root);
    }

    static display = message => {
        root.dispatchEvent(new CustomEvent('display', { detail: message }));
    }
}

export default flow([
    withStyles(styles)
])(UserNotification);
