import React from 'react'
import ReactDOM from 'react-dom'

import { 
    Button,
    Modal,
    Typography,
    withStyles 
} from '@material-ui/core'

const modalRoot = document.getElementById('error-modal');

const styles = theme => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    button: {
        marginTop: theme.spacing.unit
    }
});

class ErrorModal extends React.Component {
    state = { 
        isOpen: false
    }

    constructor(props) {
        super(props);

        modalRoot.addEventListener('show', e => {
            this.setState({ 
                isOpen: true,
                message: e.message || this.getMessage(e.detail)
            });
        });
    }

    static invoke(error, message) {
        const e = new CustomEvent('show', { 
            detail: error,
            message
        });
        modalRoot.dispatchEvent(e);
    }
    
    close = () => {
        this.setState({ 
            isOpen: false
        })
    }

    getMessage = error => {
        const messages = this.props.messages || {};

        if(!error.response) {
            return messages.networkError || 'The application could not access the network. Please check your connection.'
        }

        return messages.unknownError || 'Looks like we are having some issues. Please try again.'
    }

    render() {
        const { classes } = this.props;
        const modal = <Modal open={this.state.isOpen} onClose={this.close}>
            <div className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                    Oops!
                </Typography>
                <Typography variant="subtitle1">
                    <p>An error has occurred :(</p>
                </Typography>
                <Typography>
                    {this.state.message}
                </Typography>
                <Typography>
                    If the error persists, please contact support.
                </Typography>

                <Button variant="outlined" size="small" className={classes.button} onClick={this.close}>
                    Close
                </Button>
            </div>
        </Modal>

        return ReactDOM.createPortal(modal, modalRoot);
    }
}

export default withStyles(styles)(ErrorModal);
