export default theme => ({
    infoWindow: {
        display: 'flex',
        alignItems: 'center'
    },
    infoStoreName: {
        borderBottom: '0.3px solid rgb(0,0,0,0.5)',
        paddingBottom: '5px',
        marginTop: 0,
        marginBottom: '10px'
    },
    infoStoreButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: '10px',
        borderLeft: '3px solid rgb(0,0,0,0.5)'
    },
    infoStoreButton: {
        minWidth: '10px',
        padding: 0,
        marginLeft: '5px',
        height: 90
    },
    storeAvatar: {
        margin: 10,
        width: 70,
        height: 70,
        '& img': {
            height: 'auto',
            display: 'block',
            maxWidth: '100%'
        }
    }
})