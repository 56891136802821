import React from 'react'
import { observer, inject } from 'mobx-react'
import { flow } from 'lodash'

import { sortBy } from 'lodash'

import {
    Avatar,
    List,
    ListItem,
    ListItemText,
    withStyles
} from '@material-ui/core'

import {
    ChevronRight as ChevronRightIcon,
    Store as StoreIcon
} from '@material-ui/icons'

import localize from '../../locale'

import styles from './StoresList.styles'

class StoresList extends React.Component {
    render() {
        const { appState, classes, stores, onStoreOpen } = this.props;
        const isEn = appState.lang === 'en';
        const locale = localize(appState.lang).affiliates;

        const storesByDistance = sortBy(stores, ['distance']);

        return <List className={classes.container}>
            {storesByDistance.map(store => {
                const storeType = isEn ? store.categoryEn : store.categoryGr;

                const distance = store.distance >= 1000
                    ? `${(store.distance / 1000).toFixed(1)}${locale.KMETERS}`
                    : `${Math.round(store.distance)}${locale.METERS}`;

                return <ListItem key={store.id} button onClick={() => onStoreOpen(store)} className={classes.listItem}>
                    {store.logo && <Avatar alt={store.name} src={store.logo} className={classes.logoAvatar} />}
                    {!store.logo && <Avatar alt={store.name} className={classes.iconAvatar}>
                        <StoreIcon fontSize="large" />
                    </Avatar>}
                    <ListItemText
                        primary={store.name}
                        secondary={storeType}
                        classes={{
                            root: classes.root,
                            primary: classes.primary,
                            secondary: classes.secondary
                        }} />
                    <span className={classes.distance}>{distance}</span>
                    <ChevronRightIcon color="primary" fontSize="large" />
                </ListItem>
            })}
        </List>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(StoresList);