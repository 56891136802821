import headingBack from '../Login/assets/back-pattern.png'

export default theme => ({
    headingRow: {
        minHeight: 393,
        position: 'relative',
        backgroundColor: '#ececec',
        borderBottom: '3px solid #ccb7ac',
        backgroundImage: "url(" + headingBack + ")",
        backgroundRepeat: "repeat",
    },
    mainRow: {
        position: 'relative',
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
        '& div.slick-slider': {
            zIndex:11,
            [theme.breakpoints.down('xs')]: {
                margin: '0 !important',
            },
            '& div.slick-next:before': {
                display:'none'
            },
            '& div.slick-prev:before': {
                display:'none'
            },
            '& div.slick-next': {
                [theme.breakpoints.down('xs')]: {
                    top: 'auto',
                    bottom: '-90px',
                    right: 0,
                },
            },
            '& div.slick-prev': {
                [theme.breakpoints.down('xs')]: {
                    top: 'auto',
                    bottom: '-90px',
                    left: 0,
                },
            },
            [theme.breakpoints.down('lg')]: {
                margin: '0 30px',
            },
        },
        '& div.slick-slider *': {
            outline: 'none',
        },
        '& ul.slick-dots li': {
            width: 10,
            height:10,
        },
        '& ul.slick-dots li button': {
            width: 10,
            height:10,
            border: '2px solid ' + theme.palette.primary.main,
            borderRadius: '100%',
            lineHeight: 1,
        },
        '& ul.slick-dots li.slick-active button': {
            backgroundColor: theme.palette.primary.main,
        },
        '& ul.slick-dots li button:before': {
            content: 'none',
        },
    },
    tContainer: {
        maxWidth: 1280,
        margin: '0 auto',
        overflow: 'hidden',
        '& div': {
            display: 'flex',
            flexWrap: 'wrap',
            '& p': {
                boxSizing: 'border-box',
                flex: 1,
                padding: '12px 40px',
                borderBottom: '1px solid #dfdfe2',
                overflow: 'hidden',
                '& span': {
                    display: 'block',
                }
            }
        },
    },
    slickNext: {
        display: 'block',
        
    },
    slideWrap: {
        marginTop: 70,
        marginBottom: 10,
        '& div': {
            margin: '0 auto',
        },
    },
    newCardWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:54,
        marginBottom:10,
    },
    addCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: 414,
        height: 264,
        padding: 30,
        border: '5px solid #bababa',
        borderRadius: 20,
        [theme.breakpoints.down('xs')]: {
            width: 340,
            height: 217,
        },            
        '& h4': {
            color: '#5b5b5b',
            fontSize: 24,
            margin: 0,
            fontWeight: 500,
        }
    },
    addIcon: {
        width:70,
        height:70,
        color: theme.palette.primary.main,
    },
    graphicSun: {
        position: 'absolute',
        top: 15,
        left: '26%',
        zIndex: 10,
    },
    graphicWhiteCloud: {
        position: 'absolute',
        top: 190,
        left: '16%',
        zIndex: 10,
    },
    graphicOrangeCloud: {
        position: 'absolute',
        top: 60,
        right: '12%',
        zIndex: 10,
    },
})