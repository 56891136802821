export default theme => ({
    formWrap: {
        padding: '0 20px 30px 20px'
    },
    buttonWrap: {
        paddingTop: 20,
        textAlign: 'center',
        '& button': {
            fontSize: 18,
            fontWeight: 400,
            boxShadow: 'none',
            padding: '10px 44px',
            
            '&:hover': {
                backgroundColor: '#f59100'
            }
        }
    },
    loginFooter: {
        paddingTop: 20,
        color: '#fff',
        letterSpacing: '-0.027em',
        '& a': {
            color: '#fff',
            fontWeight: '700'
        }
    },
    textPanel: {
        margin: '10px 0'
    },
    textCenter: {
        textAlign: 'center'
    }
});
