import React from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router'

import {
    AppBar,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    withStyles
} from '@material-ui/core'

import classNames from 'classnames'
import { flow, replace } from 'lodash'

import { Api } from '../../components/shared/main'
import BottomBar from './BottomBar'
import SideBar from './Sidebar'
import Topbar from './Topbar'
import logo from '../../assets/images/logo-inner.png'

import {
    Close as CloseSidebarIcon,
    Menu as MenuIcon
} from '@material-ui/icons'

import ForceAcceptTermsDialog from '../components/ForceAcceptTermsDialog'
import { configureScopeWithUser } from '../../sentry'

import styles from './AuthenticatedLayout.styles'

class AuthenticatedLayout extends React.Component {
    state = {
        open: false
    }

    componentDidMount = () => {
        const { appState } = this.props;
        const { 
            shouldSaveLanguage,
            lang
        } = appState;

        if(shouldSaveLanguage) {
            const api = new Api(appState);
            api.changeLanguage(lang)
                .then(profile => appState.profile = profile);
        }

        configureScopeWithUser(appState.profile);
    }

    toggleOpen = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        const {
            appState: {
                isAuthenticated,
                shouldForceAcceptTerms
            },
            classes,
            location: {
                pathname
            },
            sideBar = true,
            bottomBar = true
        } = this.props;

        const isPublicMap = !isAuthenticated && pathname === '/affiliates';

        return <div className={classNames(classes.root, `page-${replace(pathname, /\//g, '-')}`)}>
            <AppBar
                position="relative"
                className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
            >
                {<div className={classes.logo}>
                    <img src={logo} alt="Up Hellas" />
                </div>}
                <Toolbar disableGutters={!this.state.open}>
                    {sideBar && !isPublicMap && <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={this.toggleOpen}
                        className={classNames(classes.leftMargin, this.state.open && classes.hide)}
                    >
                    
                        <MenuIcon />
                        </IconButton>}
                    <Topbar />
                </Toolbar>
            </AppBar>
            {sideBar && <Drawer 
                ModalProps={{ 
                    onBackdropClick: this.toggleOpen 
                }}
                variant="temporary"
                classes={{
                    paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                }}
                open={this.state.open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={this.toggleOpen}>
                        <CloseSidebarIcon />
                    </IconButton>
                </div>
                <Divider />
                <SideBar />
            </Drawer>}
            <main className={classNames(classes.main, !isAuthenticated && classes.public)}>
                <div className={classes.toolbar} />
                {this.props.header && <div className={classes.header}>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h5" className={classes.headerText}>
                                {this.props.header}
                            </Typography>
                        </Grid>
                        {this.props.actions && <Grid item xs={6}>
                            <Grid container spacing={16} alignItems="center" justify="flex-end" className={classes.headerActions}>
                                {this.props.actions}
                            </Grid>
                        </Grid>}
                    </Grid>
                </div>}
                {this.props.children}
                {shouldForceAcceptTerms && <ForceAcceptTermsDialog appState={this.props.appState} />}
            </main>

            {bottomBar && <BottomBar />}
        </div>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withRouter
])(AuthenticatedLayout);
