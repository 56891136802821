export default theme => ({
    dialogRoot: {
        padding: 0
    },
    header: {
        height: 175,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        position: 'relative'
    },
    socialIcons: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        '& img': {
            display: 'inline-block',
            marginRight: '10px'
        }
    },
    closeIcon: {
        position: 'absolute',
        zIndex: 2,
        top: '15px',
        right: '20px',
        '& button': {
            color: '#ffffff'
        },
    },
    logoAvatar: {
        top: 45,
        width: 150,
        height: 150,
        BoxShadow: '0px 0px 8px 0px rgba(0,0,0,0.5)',
        MozBoxShadow: '0px 0px 8px 0px rgba(0,0,0,0.5)',
        boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.5)'
    },
    title: {
        marginTop: 60,
        marginBottom: 30,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    mainInfo: {
        display: 'flex',
        marginTop: '15px',
        [theme.breakpoints.down('991')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
    infoSection: {
        flex: 1,
        textAlign: 'center',
        marginTop: 0,
        padding: '0 20px 0 20px',
        [theme.breakpoints.down('991')]: {
            flex: '0 0 33%',
            justifyContent: 'center',
            marginBottom: '40px',
            minWidth: '250px',
            flexGrow: 1,
        },
        '&:not(:first-child)': {
            borderLeft: '2px solid #f9f9f9'
        },
        '& h2': {
            color: '#485756',
            marginTop: 0,
            whiteSpace: 'nowrap'
        }
    },
    hours: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    accepts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#f59100',
        textAlign: 'center',
        marginTop: '15px',
        '& h2': {
            width: '50%',
            paddingBottom: '5px',
            borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
            color: '#f59100',
            marginTop: 0
        }
    },
    features: {
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 0 20px 0',
        background: '#ececec',
        [theme.breakpoints.down('520')]: {
            flexDirection: 'column',
        },
    },
    feature: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '70px',
        textAlign: 'center',
        '&:not(:first-child)': {
            marginLeft: '30px',
            [theme.breakpoints.down('520')]: {
                marginLeft: 0,
            },
        },
        [theme.breakpoints.down('520')]: {
            maxWidth: '100%',
            '&:not(:last-child)': {
                marginBottom: 20,
            }
        },
    },
    accepted: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '15px',
        flexWrap: 'wrap',
        padding: '5px'
    },
    cardImg: {
        width: '108px',
        height: '72px',
        margin: '5px'
    },
    voucherImg: {
        width: '150px',
        height: '72px',
        margin: '5px'
    },
    icons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        background: '#f59100',
        width: '70px',
        height: '70px',
        borderRadius: '50%'
    },
    featureIcon: {
        width: '2em',
        height: '2em'
    },
    deliveryIcon: {
        width: '3em',
        height: '2em'
    },
    mobilePosIcon: {
        width: '2em',
        height: '3em'
    },
    featureText: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginTop: '12px'
    }
})