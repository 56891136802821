import React from 'react'
import MaskedInput from 'react-text-mask'

const CreditCardExpiryInput = ({ inputRef, ...other }) => (
    <MaskedInput 
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, '/', /\d/, /\d/]}
        guide={false} />
)

export default CreditCardExpiryInput;