import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'
import AuthenticatedLayout from '../components/AuthenticatedLayout'

import localize from '../../locale'

import headingBack from '../Login/assets/back-pattern.png'

const styles = theme => ({
    root: {
        justifyContent: 'center',
    },
    headingRow: {
        minHeight: 300,
        position: 'relative',
        backgroundColor: '#ececec',
        borderBottom: '3px solid #ccb7ac',
        backgroundImage: "url(" + headingBack + ")",
        backgroundRepeat: "repeat",
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
    },
    mainRowStatic: {
        position: 'relative',
        '& > div': {
            marginTop: '-200px',
        }
    },
    panelText: {
        fontSize: 16,
        fontWeight: 400,
        color: '#757575',
        backgroundColor: '#fff',
        padding: '50px 40px',
        '& ul > li': {
            marginBottom: 20,
        },
        '& ul > li li': {
            marginBottom: 10,
        },
        '& ol > li': {
            marginBottom: 20,
        },
        '& ol > li li': {
            marginBottom: 10,
        },
    },
});

class TermsCard extends React.Component {
    renderText = () => {
        const { appState, classes, content } = this.props;
        const locale = localize(appState.lang).terms_content;
        return <div className={classes.panelText}>
            {locale[content]}
        </div>
    }

    render() {
        const { appState, classes, title } = this.props;
        const locale = localize(appState.lang).terms;
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>

                    <h5>{locale[title]}</h5>

                </div>
            </div>
            <div className={classes.mainRowStatic}>
                <div className={classes.container}>
                    {this.renderText()}
                </div>
            </div>

        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(TermsCard);
