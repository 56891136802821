import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    withStyles
} from '@material-ui/core'

import { 
    ExpandMore as ExpandIcon
} from '@material-ui/icons';

import { flow } from 'lodash'
import AuthenticatedLayout from './components/AuthenticatedLayout'

import localize from '../locale'

import headingBack from './Login/assets/back-pattern.png'

const styles = theme => ({
    root: {
        justifyContent: 'center',
    },
    headingRow: {
        minHeight: 300,
        position: 'relative',
        backgroundColor: '#ececec',
        borderBottom: '3px solid #ccb7ac',
        backgroundImage: "url(" + headingBack + ")",
        backgroundRepeat: "repeat",
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
    },
    mainRowStatic: {
        position: 'relative',
        '& > div': {
            marginTop: '-200px',
        }
    },
    panelHeading: {
        fontSize: 20,
        fontWeight: 500,
    },
    panelText: {
        fontSize: 20,
        fontWeight: 400,
        color: '#757575',
    },
});

class Faq extends React.Component {
    renderItem = (it, index) => {
        const { classes } = this.props;
        return <ExpansionPanel key={index}>
            <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
                <Typography className={classes.panelHeading}>{it.q}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography className={classes.panelText}>{it.a}</Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    }

    render() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).faqText;
        const faqs = localize(appState.lang).faq;
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>
                
                    <h5>{locale.FAQ_TITLE}</h5>

                </div>
            </div>
            <div className={classes.mainRowStatic}>
                <div className={classes.container}>
                    {faqs.map(this.renderItem)}
                </div>
            </div>
            
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(Faq);
