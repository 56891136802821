import headingBack from '../Login/assets/back-pattern.png'

export default theme => ({
    root: {
        justifyContent: 'center',
    },
    headingRow: {
        position: 'relative',
        backgroundColor: '#ececec',
        borderBottom: '3px solid #ccb7ac',
        backgroundImage: "url(" + headingBack + ")",
        backgroundRepeat: "repeat",
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
    },
    mainRow: {
        position: 'relative',
    },
    headingContainer: {
        display: 'inline-block',
        width: '100%',
    },
    headingWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:'column',
        marginTop:54,
        marginBottom:10,
        '& h1': {
            fontSize: 32,
            fontWeight: 500,
            color: '#485756',
            '& span': {
                fontWeight:600,
            },
        },
    },
    accountIconFull: {
        color: theme.palette.primary.main,
        width: 123,
        height: 123,
    },
    formSwitch: {
        justifyContent: 'center',
    },
    containerNarrow: {
        maxWidth: 380,
        margin: '40px auto 0 auto',
    },
    buttonsWrap: {
        marginTop: 30,
        '& button': {
            padding: '4px 12px',
            width: '47.5%',
            boxShadow: 'none',
        },
        '& button:first-child': {
            marginRight: '5%',
        }
    },
    buttonSubmit: {
        backgroundColor: '#f99c24 !important',
        color: '#000 !important',
        '&:hover': {
            backgroundColor: '#eab46d !important',
        }
    },
    buttonReset: {
        backgroundColor: '#485756',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#ccc',
        }
    },
});
