import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    FormGroup,
    TextField,
    withStyles
} from '@material-ui/core'

import {
    Chat as AccountChatIcon,
} from '@material-ui/icons'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'

import AuthenticatedLayout from '../components/AuthenticatedLayout'
import Loader from '../../components/Loader'
import localize from '../../locale'
import SecurityQuestionSelect from '../components/SecurityQuestionSelect'
import UserNotification from '../../components/UserNotification'
import { disableAutocomplete } from '../../components/uifix'
import { withValidation } from '../../components/withValidation'

import styles from './Account.styles'

const validationSchema = {
    securityQuestion: 'required',
    securityAnswer: ['required', { minLength: 3, maxLength: 100 }]
}

class ChangeSecurityQuestion extends React.Component {
    state = {
        securityQuestion: this.props.appState.profile.security_question.id,
        securityAnswer: ''
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    submit = async e => {
        e.preventDefault();
        
        const appState = this.props.appState;
        const locale = localize(appState.lang).account;

        if (!this.props.validation.update(this.state)) {
            return;
        }

        const api = new Api(appState);

        Loader.show();

        try {
            const profile = await api.changeSecurityQuestion({
                securityQuestion: this.state.securityQuestion,
                securityAnswer: this.state.securityAnswer
            });
            
            appState.profile = profile;
            
            UserNotification.display(locale.SECURITY_QUESTION_SUCCESS);

            this.props.history.push('/account');
        } finally {
            Loader.hide();
        }
    }

    onCancel = () => this.props.history.goBack();

    render() {
        const { classes, validation } = this.props;
        const locale = localize(this.props.appState.lang).account;
        const helperText = validation.errorText(locale.HELP);
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>
                    <h5>{locale.CHANGE_QUESTION}</h5>
                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <AccountChatIcon className={classes.accountIconFull} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mainRow}>
                <div className={classes.container}>
                    <div className={classes.containerNarrow}>
                        <form noValidate>
                            <FormGroup className={classes.formControl}>
                                <SecurityQuestionSelect
                                    value={this.state.securityQuestion} onChange={this.onChange} />

                                <TextField name="securityAnswer" required
                                    label={locale.SECURITY_ANSWER_LABEL}
                                    value={this.state.securityAnswer} onChange={this.onChange}
                                    error={validation.error.securityAnswer}
                                    helperText={helperText.securityAnswer}
                                    margin="normal" fullWidth />
                            </FormGroup>
                            <div className={classes.buttonsWrap}>
                                <Button className={classes.buttonReset} onClick={this.onCancel}>{locale.CANCEL}</Button>
                                <Button type="submit" className={classes.buttonSubmit} onClick={this.submit}>{locale.SUBMIT}</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema),
    disableAutocomplete
])(ChangeSecurityQuestion);
