import { toJS, autorun, set } from 'mobx'

export default function(_this) {   
    // if the store is persisted, load it
    
    const json = localStorage.getItem('store');

    if (json) {
        const store = JSON.parse(json);

        if(store.lang === 'gr') {
            store.lang = 'el';
        }

        set(_this, store);
    }

    autorun(() => {
        // auto-save
        
        if(_this.persist) {
            const json = JSON.stringify(toJS(_this));
            localStorage.setItem('store', json);
        } else {
            localStorage.removeItem('store');
        }
    });
    
    return _this;
}
