import React from 'react'
import ReactDOM from 'react-dom'

import { filter, forEach } from 'lodash'

const disableAutocompleteApplyFix = component => {
    const src = ReactDOM.findDOMNode(component.current ? component.current : component);

    if(!src) {
        console.error('uifix: Disable autocomplete fix not applied because node not mounted');
        return;
    }

    forEach(src.getElementsByTagName('form'),
        form => { 
            form.setAttribute('autocomplete', 'no');

            const hidden = document.createElement('input');
            hidden.setAttribute('type', 'hidden');
            hidden.setAttribute('value', 'autofill-disable');
             
            form.appendChild(hidden);
        });            

    // apply to inputs

    const inputs = filter(src.getElementsByTagName('input'),
        input => input.type !== 'radio' && input.type !== 'checkbox');
    
    forEach(inputs, input => input.setAttribute('autocomplete', 'no'));
}

const disableAutocomplete = (Component) => {
    class DisableAutocomplete extends React.Component {
        componentDidMount() {
            disableAutocompleteApplyFix(this);
        }   

        render() {
            const { forwardedRef, ...props } = this.props;
            return <Component ref={forwardedRef} {...props} />
        }
    }
    
    return React.forwardRef((props, ref) => <DisableAutocomplete {...props} forwardedRef={ref} />);
}

export {
    disableAutocompleteApplyFix,
    disableAutocomplete
}