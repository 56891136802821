import React from 'react'

import {
    Avatar,
    Dialog,
    DialogContent,
    IconButton,
    withStyles
} from '@material-ui/core'

import {
    Close as CloseIcon,
    CreditCard as CreditCardIcon,
    Phonelink as PhonelinkIcon,
    Store as StoreIcon
} from '@material-ui/icons'

import { GoogleMap } from '@react-google-maps/api'

import MapMarker from './MapMarker'

import localize from '../../locale'
import mapOptions from './mapOptions'

import styles from './StoreDialog.styles'

import cadhocImg from '../../assets/images/cadhoc-big.png'
import cardImg from '../../assets/images/card-mini.png'
import heroCorpImg from '../../assets/images/herocorp-medium.png'
import deliveryImg from '../../assets/images/delivery.png'
import mobilePosImg from '../../assets/images/mobilepos.png'
import voucherImg from '../../assets/images/voucher-medium.png'
import fbIconImg from '../../assets/images/ico-fb.png'
import instagramIconImg from '../../assets/images/ico-in.png'


class StoreDialog extends React.Component {
    state = {
        open: false,
        store: {},
        isInfoOpen: true
    }

    open = store => {
        this.setState({
            open: true,
            store
        });
    }

    close = () => {
        this.setState({
            open: false,
            store: {}
        });
    }

    onMarkerClick = () => {
        const { isInfoOpen } = this.state;
        this.setState({
            isInfoOpen: !isInfoOpen,
        });
    }

    render() {
        const { appState, classes } = this.props;
        const { open, isInfoOpen, store } = this.state;
        const locale = localize(appState.lang).storeDialog;
        const isEn = appState.lang === 'en';

        const hasSocial =
            store.facebookUrl || store.linkedInUrl;

        const hasFeatures =
            store.delivery || store.onlineOrder || store.pos || store.mobilePos;

        const accepts = store.goForEatCard || store.chequesDejeuner || store.giftVoucher;

        let businessHours = isEn ? store.businessHoursEn : store.businessHoursGr;
        businessHours = businessHours ? businessHours.split(',').join('\n') : '';

        const headerBg = store.header ? `url(${store.header}) center` : 'gray';

        let topAddress;
        let bottomAddress;

        if (isEn) {
            topAddress = [store.regionEn, store.cityEn]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
            bottomAddress = [store.addressEn, store.zip]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
        } else {
            topAddress = [store.regionGr, store.cityGr]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
            bottomAddress = [store.addressGr, store.zip]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
        }

        return <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={this.close}>
            <DialogContent className={classes.dialogRoot} style={{ paddingTop: 0 }}>
                <div className={classes.closeIcon}>
                    <IconButton edge="start" onClick={this.close} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.header} style={{ background: headerBg }}>
                    {hasSocial && <div className={classes.socialIcons}>
                        {store.facebookUrl && <a href={store.facebookUrl} target="_blank" rel="noopener noreferrer">
                            <img src={fbIconImg} alt="Facebook" className={classes.fbIcon} />
                        </a>}
                        {store.linkedInUrl && <a href={store.linkedInUrl} target="_blank" rel="noopener noreferrer">
                            <img src={instagramIconImg} alt="Instagram" className={classes.inIcon} />
                        </a>}
                    </div>}
                    {store.logo && <Avatar alt={store.name} src={store.logo} className={classes.logoAvatar} />}
                    {!store.logo && <Avatar alt={store.name} className={classes.logoAvatar}>
                        <StoreIcon style={{ fontSize: 72 }} />
                    </Avatar>}
                </div>
                <div className={classes.title}>
                    <h1>{store.name}</h1>
                </div>
                <div className={classes.mainInfo}>
                    <div className={classes.infoSection}>
                        <h2>{locale.OFFERS}</h2>
                        {isEn ? store.categoryEn : store.categoryGr}
                    </div>
                    <div className={classes.infoSection}>
                        <h2>{locale.ADDRESS}</h2>
                        {topAddress}<br />{bottomAddress}
                    </div>
                    <div className={classes.infoSection}>
                        <h2>{locale.PHONE}</h2>
                        {store.telephone}
                    </div>
                    <div className={classes.infoSection}>
                        <h2>{locale.HOURS}</h2>
                        <pre className={classes.hours}>{businessHours}</pre>
                    </div>
                    <div className={classes.infoSection}>
                        <h2>{locale.WEBSITE}</h2>
                        <a href={store.websiteUrl} target="_blank" rel="noopener noreferrer">{store.websiteUrl}</a>
                    </div>
                </div>
                {accepts && <div className={classes.accepts}>
                    <h2>{locale.ACCEPTS}</h2>

                    <div className={classes.accepted}>
                        {store.goForEatCard && <img src={cardImg} className={classes.cardImg} alt="GoforEat Card" />}
                        {store.chequesDejeuner && <img src={voucherImg} className={classes.voucherImg} alt="Cheques Dejeuner" />}
                        {store.giftVoucher && <img src={cadhocImg} className={classes.voucherImg} alt="Cadhoc Voucher" />}
                        {store.heroCorp && <img src={heroCorpImg} className={classes.voucherImg} alt="HeroCorp" />}
                    </div>
                </div>}
                {hasFeatures && <div className={classes.features}>
                    {store.delivery && <div className={classes.feature}>
                        <div className={classes.icons}>
                            <img src={deliveryImg} alt="Delivery" className={classes.deliveryIcon} />
                        </div>
                        <span className={classes.featureText}>{locale.DELIVERY}</span>
                    </div>}
                    {store.onlineOrder && <div className={classes.feature}>
                        <div className={classes.icons}>
                            <PhonelinkIcon className={classes.featureIcon} />
                        </div>
                        <span className={classes.featureText}>{locale.ONLINE_ORDER}</span>
                    </div>}
                    {store.pos && <div className={classes.feature}>
                        <div className={classes.icons}>
                            <CreditCardIcon className={classes.featureIcon} />
                        </div>
                        <span className={classes.featureText}>{locale.POS}</span>
                    </div>}
                    {store.mobilePos && <div className={classes.feature}>
                        <div className={classes.icons}>
                            <img src={mobilePosImg} alt="Mobile POS" className={classes.mobilePosIcon} />
                        </div>
                        <span className={classes.featureText}>{locale.MOBILE_POS}</span>
                    </div>}
                </div>}

                {store.id && <div style={{ height: '400px', display: 'flex' }}>
                    <GoogleMap
                        options={mapOptions}
                        center={{
                            lat: parseFloat(store.latitude),
                            lng: parseFloat(store.longitude)
                        }}
                        zoom={18}
                        mapContainerStyle={{ flex: 1 }}>
                        <MapMarker
                            key={store.id}
                            markerData={store}
                            isSelected={true}
                            isInfoOpen={isInfoOpen}
                            onClick={this.onMarkerClick}
                        />
                    </GoogleMap>
                </div>}
            </DialogContent>
        </Dialog>
    }
}

export default withStyles(styles)(StoreDialog);