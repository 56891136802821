import React from 'react'

import { isEqual } from 'lodash'

import { Fab } from '@material-ui/core'

import { MyLocation as MyLocationIcon } from '@material-ui/icons'

import { GoogleMap, Marker } from '@react-google-maps/api'

import MapMarkerCluster from './MapMarkerCluster'

import mapOptions from './mapOptions'

import userMarkerIcon from '../../assets/images/userMarker.png'

class Map extends React.Component {
    state = {
        initLoad: true,
        ne: {
            lat: null,
            lon: null
        },
        sw: {
            lat: null,
            lon: null
        },
        zoom: 18
    }

    googleMap = React.createRef();

    componentDidUpdate(prevProps) {
        const { mapZoom, userLocation } = this.props;

        if (!isEqual(prevProps.userLocation, userLocation)) {
            this.setState({
                initLoad: true
            });
        }

        if (prevProps.mapZoom !== mapZoom) {
            this.setState({
                zoom: mapZoom
            });
        }
    }

    onMapMounted = map => {
        this.setState({
            googleMap: map
        });
    }

    onMapIdle = () => {
        const { search } = this.props;
        const { initLoad } = this.state;

        if (initLoad) {
            this.setState({
                initLoad: false
            }, search);
        }
    }

    onZoomChanged = () => {
        this.setState({
            zoom: this.state.googleMap.getZoom()
        });
    }

    onBoundsChanged = () => {
        const { canSearchHere } = this.props;
        const { initLoad } = this.state;

        if (!initLoad) {
            canSearchHere();
        }
    }

    getBounds = () => {
        const { googleMap } = this.state;
        const mapBounds = googleMap.getBounds();

        if (mapBounds) {
            const newNE = mapBounds.getNorthEast();
            const newSW = mapBounds.getSouthWest();

            const neLat = newNE.lat();
            const neLon = newNE.lng();
            const swLat = newSW.lat();
            const swLon = newSW.lng();
            return {
                ne: {
                    lat: neLat,
                    lon: neLon
                },
                sw: {
                    lat: swLat,
                    lon: swLon
                }
            };
        }

        return null;
    }

    getZoomLevel = () => {
        return this.state.zoom;
    }

    goToUserLocation = () => {
        this.state.googleMap.panTo(this.props.userLocation);
    }

    render() {
        const {
            flex,
            mapZoom,
            markers,
            onStoreOpen,
            userLocation,
        } = this.props;

        return <div style={{ position: 'relative', flex: flex, display: 'flex' }}>
            <Fab onClick={this.goToUserLocation}
                style={{
                    position: 'absolute',
                    background: 'rgba(0, 0, 0, 0.4)',
                    color: 'rgba(255, 255, 255, 0.7)',
                    right: 10,
                    top: 10,
                    zIndex: 999
                }}
                size="small">
                <MyLocationIcon />
            </Fab>
            <GoogleMap
                options={mapOptions}
                center={userLocation}
                zoom={mapZoom}
                onLoad={this.onMapMounted}
                onIdle={this.onMapIdle}
                onBoundsChanged={this.onBoundsChanged}
                onZoomChanged={this.onZoomChanged}
                mapContainerStyle={{ flex: flex }}>
                <MapMarkerCluster markers={markers} onStoreOpen={onStoreOpen} />
                <Marker zIndex={-1} icon={{ url: userMarkerIcon }}
                    position={{
                        lat: userLocation.lat,
                        lng: userLocation.lng
                    }} />
            </GoogleMap>
        </div>
    }
}

export default Map;