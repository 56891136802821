import React from 'react'

import {
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'

const styles = theme => {

}

class NotFound extends React.Component {
    render() {
        return <div />
    }
}

export default flow([
    withStyles(styles)
])(NotFound);
