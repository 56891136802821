import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import {
    Button,
    withStyles
} from '@material-ui/core'

import {
    AccountCircle
} from '@material-ui/icons'

import { flow } from 'lodash'

const styles = theme => ({
    flex: {
        flex: 1
    },

    tenant: {
        ...theme.typography.button,
        color: theme.palette.common.white
    },
    accountButton: {
        color: '#485756',
        textAlign: 'left',
    },
    accountIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
        color: '#485756',
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
    },
    accountName: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 140,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }

});

class Topbar extends React.Component {
    state = {
        anchor: null
    }

    onMenuOpen = e => this.setState({ anchor: e.currentTarget });

    onMenuClose = () => this.setState({ anchor: null })

    render() {
        const { appState, classes } = this.props;
        const { profile } = appState;
        const gotoAccount = () => this.props.history.push('/account');
        return <React.Fragment>
            <div className={classes.flex} />

            {appState.isAuthenticated && <div>
                <Button className={classes.accountButton} onClick={gotoAccount}>
                    <AccountCircle className={classes.accountIcon} />
                    <span className={classes.accountName}>{profile.first_name} {profile.last_name}<br />
                        {profile.email}</span>
                </Button>
            </div>}
        </React.Fragment>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withRouter
])(Topbar);
