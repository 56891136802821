export default theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
        },
    },
    appBar: {
        backgroundColor: '#fff',
        padding: '0 24px 0 28px',
        boxShadow: 'none',
        borderBottom: '7px solid #485756',
        [theme.breakpoints.down('sm')]: {
            padding: '0 14px 0 18px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 4px 0 8px',
        },
    },
    logo: {
        position: 'absolute',
        top: 20,
        left: 0,
        right: 0,
        '& img': { margin: '0 auto'}
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        marginBottom: '68px',
        position: 'relative',
        overflowY: 'auto',
        '& h5': {
            position: 'absolute',
            top: 0,
            left: 0,
            margin:0,
            padding: '0px 18px 7px 18px',
            borderRadius: '0 0 6px 6px',
            backgroundColor: '#485756',
            color: '#fff',
            fontSize: 16,
            fontWeight: 400,
            zIndex: 9,
        }
    },
    public: {
        marginBottom: '0 !important',
    }
});