import headingBack from './Login/assets/back-pattern.png'

export default theme => ({
    root: {
        justifyContent: 'center',
    },
    headingRow: {
        position: 'relative',
        backgroundColor: '#ececec',
        borderBottom: '3px solid #ccb7ac',
        backgroundImage: "url(" + headingBack + ")",
        backgroundRepeat: "repeat",
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
        '& > p': {
            fontSize: 15,
            textAlign: 'center',
            '& span': {
                color: theme.palette.primary.main,
            }
        }
    },
    mainRow: {
        position: 'relative',
        '& > div': {
            maxWidth: 800,
            paddingTop: 30,
            margin: '0 auto',
        }
    },
    headingContainer: {
        display: 'inline-block',
        width: '100%',
    },
    headingWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        marginBottom: 20,
    },
    accountIconFull: {
        color: theme.palette.primary.main,
        width: 120,
        height: 95,
    },
    panelText: {
        fontSize: 16,
        fontWeight: 400,
        color: '#757575',
        backgroundColor: '#fff',
        padding: '50px 40px',
    },
    formControl: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        margin: '0 -10px',
        '& div': {
            flex: 1,
            padding: '0 10px',
            '& div': {
                minHeight: 32,
            },
        },
        '& label': {
            left: 10
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    formMessage: {
        marginTop: 20,
        width: '100%',
    },
    buttonsWrap: {
        marginTop: 20,
        textAlign: 'center',
        '& button': {
            padding: '8px 60px',
            boxShadow: 'none',
        },
    },
});