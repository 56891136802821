import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    withStyles,
    Divider
} from '@material-ui/core'

import { flow } from 'lodash'

import AuthenticatedLayout from '../components/AuthenticatedLayout'
import localize from '../../locale'
import Loader from '../../components/Loader'
import { Api } from '../../components/shared/main'

import styles from './Account.styles'

import {
    Accessibility as PermissionsIcon,
    AccountCircle as AccountIconFull,
    Lock as AccountLockIcon,
    ModeComment as LanguageIcon,
    Email as AccountEmailIcon,
    Chat as AccountChatIcon,
    KeyboardArrowRight as ArrowRight,
} from '@material-ui/icons'

class Account extends React.Component {
    state = {
        permissionsOpen: false,
        advertise: { active: false },
        survey: { active: false },
    }

    constructor(props) {
        super(props);

        this.api = new Api(props.appState);
    }

    openPermissions = () => {
        this.setState({
            permissionsOpen: true
        });
    }

    closePermissions = () => {
        this.setState({
            permissionsOpen: false
        });
    }

    onChangePermission = async event => {
        const name = event.target.name;
        const appState = this.props.appState;
        const active = event.target.checked;

        if(appState.profile[name].active !== active) {
            // only reset timestamp if different
            Loader.show();

            try {
                appState.profile = 
                    await this.api.changePermission(name, active);
            } finally {
                Loader.hide();
            }
        }

        this.setState({
            [name]: { active }
        });
    };

    renderPermissionsDialog = () => {
        const { appState } = this.props;
        const {
            advertise,
            permissionsOpen,
            survey
        } = this.state;
        const locale = localize(appState.lang).account.PERMISSIONS;

        return <Dialog fullWidth={true} maxWidth="sm" open={permissionsOpen} onClose={this.closePermissions}>
            <DialogTitle>{locale.TITLE}</DialogTitle>
            <Divider />
            <DialogContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={advertise.active}
                            onChange={this.onChangePermission}
                            name="advertise"
                        />
                    }
                    label={locale.MARKETING_EMAILS}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={survey.active}
                            onChange={this.onChangePermission}
                            name="survey"
                        />
                    }
                    label={locale.SURVEYS}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.setState({ permissionsOpen: false })}>{locale.CLOSE_DIALOG}</Button>
            </DialogActions>
        </Dialog>
    }

    render() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).account;
        const { profile } = appState;
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>
                    <h5>{locale.MY_ACCOUNT}</h5>
                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <AccountIconFull className={classes.accountIconFull} />
                            <h1>{locale.HELLO} <span>{profile.first_name}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mainRow}>
                <div className={classes.container}>
                    <List>
                        <ListItem button onClick={() => this.props.history.push('/account/change/password')}>
                            <ListItemIcon>
                                <AccountLockIcon />
                            </ListItemIcon>
                            <ListItemText primary={locale.CHANGE_PASSWORD} />
                            <ArrowRight color="primary" />
                        </ListItem>
                        <ListItem button onClick={() => this.props.history.push('/account/change/email')}>
                            <ListItemIcon>
                                <AccountEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={locale.CHANGE_EMAIL} />
                            <ArrowRight color="primary" />
                        </ListItem>
                        <ListItem button onClick={() => this.props.history.push('/account/change/securityQuestion')}>
                            <ListItemIcon>
                                <AccountChatIcon />
                            </ListItemIcon>
                            <ListItemText primary={locale.CHANGE_QUESTION} />
                            <ArrowRight color="primary" />
                        </ListItem>
                        <ListItem button onClick={() => this.props.history.push('/account/change/language')}>
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            <ListItemText primary={locale.CHANGE_LANGUAGE} />
                            <ArrowRight color="primary" />
                        </ListItem>
                        <ListItem button onClick={this.openPermissions}>
                            <ListItemIcon>
                                <PermissionsIcon />
                            </ListItemIcon>
                            <ListItemText primary={locale.CHANGE_PERMISSIONS} />
                            <ArrowRight color="primary" />
                        </ListItem>
                    </List>
                </div>
                {this.renderPermissionsDialog()}
            </div>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(Account);
