import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    FormGroup,
    TextField,
    MenuItem,
    withStyles
} from '@material-ui/core'

import {
    Person as AccountNameIcon
} from '@material-ui/icons'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'

import AuthenticatedLayout from '../components/AuthenticatedLayout'
import Loader from '../../components/Loader'
import localize from '../../locale'

import styles from './Account.styles'

class ChangeLanguage extends React.Component {
    state = {
        lang: 'el'
    }

    constructor(props) {
        super(props);

        this.api = new Api(this.props.appState);
    }

    componentDidMount() {
        this.setState({
            lang: this.props.appState.lang
        });
    }

    onChangeLanguage = e => {
        this.props.appState.lang = e.target.value;
    }

    submit = async () => {
        Loader.show();

        try {
            const {
                lang
            } = this.props.appState;

            this.props.appState.profile =
                await this.api.changeLanguage(lang);

            Loader.hide();
            this.props.history.goBack();
        } catch(error) {
            Loader.hide();

            throw error;
        }
    }

    onCancel = () => {
        const { lang } = this.state;
        this.props.appState.lang = lang;
        this.props.history.goBack();
    }

    render() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).account;

        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>
                    <h5>{locale.CHANGE_LANGUAGE}</h5>
                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <AccountNameIcon className={classes.accountIconFull} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mainRow}>
                <div className={classes.container}>
                    <div className={classes.containerNarrow}>
                        <form noValidate>
                            <FormGroup className={classes.formControl}>
                                <TextField name="lang" select value={appState.lang} onChange={this.onChangeLanguage} margin="normal" fullWidth>
                                    <MenuItem value="el">{locale.LANG_GR}</MenuItem>
                                    <MenuItem value="en">{locale.LANG_EN}</MenuItem>
                                </TextField>
                            </FormGroup>
                            <div className={classes.buttonsWrap}>
                                <Button className={classes.buttonReset} onClick={this.onCancel}>{locale.CANCEL}</Button>
                                <Button className={classes.buttonSubmit} onClick={this.submit}>{locale.SUBMIT}</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(ChangeLanguage);
