import React from 'react'
import { observer, inject } from 'mobx-react'
import { flow } from 'lodash'

import {
    InfoWindow,
    Marker
} from '@react-google-maps/api'

import {
    Avatar,
    Button,
    withStyles
} from '@material-ui/core'

import {
    ChevronRight as ChevronRightIcon
} from '@material-ui/icons'

import markerIcon from '../../assets/images/marker.png'
import styles from './MapMarker.styles'

class MapMarker extends React.Component {
    state = {
        mapMarker: null
    }

    onClick = () => {
        const {
            isInfoOpen,
            isSelected,
            markerData,
            onClick
        } = this.props;

        const isOpen = isSelected ? !isInfoOpen : true;

        onClick(isOpen, markerData.id);
    }

    onLoad = mapMarker => {
        this.setState({
            mapMarker
        });
    }

    renderInfoWindow = () => {
        const {
            appState,
            classes,
            isInfoOpen,
            isSelected,
            markerData,
            onStoreOpen
        } = this.props;

        const {
            mapMarker
        } = this.state;

        if (!isInfoOpen || !isSelected || !mapMarker) {
            return null;
        }

        const isEn = appState.lang === 'en';

        let topAddress;
        let bottomAddress;

        if (isEn) {
            topAddress = [markerData.regionEn, markerData.cityEn]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
            bottomAddress = [markerData.addressEn, markerData.zip]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
        } else {
            topAddress = [markerData.regionGr, markerData.cityGr]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
            bottomAddress = [markerData.addressGr, markerData.zip]
                .filter(s => s != null && s.trim() !== '')
                .join(', ');
        }

        return <InfoWindow position={{
            lat: markerData.lat,
            lng: markerData.lon
        }}>
            <div className={classes.infoWindow}>
                {markerData.logo && <Avatar alt={markerData.name} src={markerData.logo} className={classes.storeAvatar} />}
                <div>
                    <h3 className={classes.infoStoreName}>{markerData.name}</h3>
                    <span className={classes.infoStoreAddress}>{topAddress}<br />{bottomAddress}</span>
                </div>
                {onStoreOpen && <div className={classes.infoStoreButtonContainer}>
                    <Button onClick={() => onStoreOpen(markerData)} className={classes.infoStoreButton}>
                        <ChevronRightIcon />
                    </Button>
                </div>}
            </div>
        </InfoWindow>
    }

    render() {
        const {
            clusterer,
            markerData,
        } = this.props;

        return <Marker
            clusterer={clusterer}
            onLoad={this.onLoad}
            onClick={this.onClick}
            icon={{ url: markerIcon }}
            key={markerData.id}
            position={{
                lat: markerData.lat,
                lng: markerData.lon
            }}>
            {this.renderInfoWindow()}
        </Marker>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(MapMarker);