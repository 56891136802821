import axios from 'axios'
import { isArray } from 'lodash'

const axiosInterceptors = (onError, onUnauthorized) => {
    axios.interceptors.response.use(r => r,
        error => {
            // if there is a response, check the status

            if (error.response) {

                // if the status is handled, just pass the error on

                if (isArray(error.config.handleStatus)
                    && error.config.handleStatus.includes(error.response.status)) {
                    return Promise.reject(error);
                }

                if (error.config.handleStatus === error.response.status) {
                    return Promise.reject(error);
                }

                // if 401/403, call onUnauthorized, if defined

                if (error.response.status === 401 || error.response.status === 403) {
                    if (onUnauthorized) {
                        // init retryCount

                        error.config.retryCount = error.config.retryCount || 0;

                        const promise = onUnauthorized(error.config);

                        // if onUnauthorized returns a promise, retry the request

                        if (promise) {
                            return promise.then(retryConfig => {
                                if (retryConfig) {
                                    // increment on the retryCount

                                    retryConfig.retryCount++;

                                    // retry

                                    return axios(retryConfig);
                                }

                                // not interested in retrying, reject

                                return Promise.reject(error);
                            });
                        }
                    }

                    return Promise.reject(error);
                }
            }

            if (onError) {
                onError(error);
            }

            return Promise.reject(error);
        });
}

export default {
    axiosInterceptors
}