import React from 'react'

import {
    Grid,
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'

import cardTopImage from '../assets/login-top.png'
import logo from '../assets/logo.png'
import bgImage from '../assets/back-pattern.png'

const styles = theme => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: "url(" + bgImage + ")",
        backgroundRepeat: "repeat",
        backgroundPosition: "top left",
        backgroundColor: "#f3a227"
    },
    container: {
        width: 460,
        margin: '0 10px',
        '& img': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
        }
    },
    loginCard: {
        backgroundColor: '#fff',
        borderRadius: 10
    },
    loginLogo: {
        paddingTop: 25,
        textAlign: 'center',
        '& img': {
            display: 'inline-block'
        }
    },
});

class Layout extends React.Component {
    render() {
        const { classes} = this.props;
        return <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <img src={cardTopImage} alt="" />
                </Grid>
                <Grid item xs={12}>
                    <Grid className={classes.loginCard}>
                        <div className={classes.loginLogo}>
                            <img src={logo} alt="Up Hellas" />
                        </div>
                        {this.props.children}
                    </Grid>
                </Grid>
                {this.props.actions}
            </Grid>
        </div>
    }
}

export default flow([
    withStyles(styles)
])(Layout);