import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core'

import { flow } from 'lodash'

import localize from '../../locale'
import { resetUserScope } from '../../sentry'

import {
    CreditCard as AddCardIcon,
    Announcement as FaqIcon,
    Description as TermsIcon,
    PermPhoneMsg as ContactIcon,
    PinDrop as PinDropIcon,
    PowerSettingsNew as LogoutIcon,
    AccountCircle as LoginIcon
} from '@material-ui/icons'

const Link = withRouter(props => {
    const onClick = () => props.history.push(props.to);
    return <ListItem button onClick={props.onClick || onClick}>
        <ListItemIcon>
            <props.icon />
        </ListItemIcon>
        <ListItemText primary={props.children} />
    </ListItem>
})

const Sidebar = props => {
    const appState = props.appState;

    const logout = () => {
        appState.logout();
        resetUserScope();
        props.history.push('/');
    }
    
    const locale = localize(appState.lang).sidebar;
    const { isAuthenticated } = appState;
    return <List component="nav">
        {!isAuthenticated && <Link to="/login" icon={LoginIcon}>{locale.LOGIN}</Link>}
        {isAuthenticated && <Link to="/manage/add" icon={AddCardIcon}>{locale.ADD_CARD}</Link>}
        {isAuthenticated && <Link to="/faq" icon={FaqIcon}>{locale.FAQ}</Link>}
        <Link to="/affiliates" icon={PinDropIcon}>{locale.MAP}</Link>
        <Link to="/privacy-policy" icon={TermsIcon}>{locale.PRIVACY_POLICY}</Link>
        <Link to="/terms-app" icon={TermsIcon}>{locale.TERMS_APP}</Link>
        <Link to="/terms-card" icon={TermsIcon}>{locale.TERMS_CARD}</Link>
        {isAuthenticated && <Link to="/contact" icon={ContactIcon}>{locale.CONTACT}</Link>}
        {isAuthenticated && <Link onClick={logout} icon={LogoutIcon}>{locale.LOGOUT}</Link>}
    </List>
}

export default flow([
    observer,
    inject('appState'),
    withRouter
])(Sidebar);