import React from 'react'
import { observer, inject } from 'mobx-react'

import { flow } from 'lodash'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles
} from '@material-ui/core'

import localize from '../locale'

const styles = theme => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    button: {
        marginTop: theme.spacing.unit
    }
});

class ConfirmDialog extends React.Component {
    state = {
        open: false,
        title: '',
        content: ''
    }

    open = (onConfirmAction, title, content) => {
        this.setState({
            open: true,
            onConfirmAction,
            title,
            content
        });
    }

    close = () => {
        this.setState({
            open: false
        });
    }

    confirmAndClose = () => {
        this.setState({
            open: false,
            title: '',
            content: ''
        });

        if (this.state.onConfirmAction) {
            this.state.onConfirmAction();
        }
    }

    render() {
        const locale = localize(this.props.appState.lang).confirmationDialog;

        return <div>
            <Dialog open={this.state.open} onClose={this.close}>
                <DialogTitle>{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.state.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close} variant="contained" color="secondary">
                        {locale.NO}
                    </Button>
                    <Button onClick={this.confirmAndClose} variant="contained" color="primary">
                        {locale.YES}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

export default flow([
    withStyles(styles),
    observer,
    inject('appState')
])(ConfirmDialog);
