import * as Sentry from '@sentry/browser'

export function getUserScope() {
    return new Promise(resolve => {
        Sentry.withScope(scope => resolve(scope._user));
    });
}

export function resetUserScope() {
    Sentry.configureScope(scope => scope.setUser(null));
}

export function configureScopeWithUser(user) {
    if(!user) {
        return resetUserScope();
    }

    Sentry.configureScope(scope => {
        scope.setUser({
            id: user.id,
            username: user.email
        });
    });
}

export default function() {
    if (process.env.NODE_ENV !== 'development') {
        Sentry.init({ 
            dsn: 'https://dd5ab3d926364101935fa0b46f4d0794@sentry.io/2792471',
            ignoreErrors: [
                /Network Error/,
                /Request failed with status code/,
                /Request aborted/
            ]
        });
    }
}