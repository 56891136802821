import React from 'react'

import markerIcon from '../../assets/images/marker.png'

import {
    MarkerClusterer
} from '@react-google-maps/api'

import MapMarker from './MapMarker'

class MapMarkerCluster extends React.Component {
    state = {
        isInfoOpen: false,
        selectedMarkerId: null,
        noOfClusters: null
    }

    onClick = (isInfoOpen, selectedMarkerId) => {
        this.setState({
            isInfoOpen,
            selectedMarkerId
        });
    }

    onClusteringEnd = clusterer => {
        const { noOfClusters } = this.state;
        const totalClusters = Object.keys(clusterer.clusters).length;
    
        if (noOfClusters !== totalClusters) {
          this.setState({
            isInfoOpen: false,
            selectedMarkerId: null,
            noOfClusters: totalClusters
          });
        }
    }

    render() {
        const { markers, onStoreOpen } = this.props;
        const { isInfoOpen, selectedMarkerId } = this.state;

        return <MarkerClusterer
            onClusteringEnd={this.onClusteringEnd}
            styles={[{
                url: markerIcon,
                minWidth: 38,
                width: 38,
                height: 55,
                textColor: '#fff',
                textSize: 16,
                anchorText: [-6, 0] // positioning of label (y-x offsets)
            }]}
            averageCenter
            enableRetinaIcons
            gridSize={60}>
            {(clusterer) => markers.map(markerData =>
                <MapMarker
                    key={markerData.id}
                    clusterer={clusterer}
                    markerData={markerData}
                    isSelected={markerData.id === selectedMarkerId}
                    isInfoOpen={markerData.id === selectedMarkerId && isInfoOpen}
                    onClick={this.onClick}
                    onStoreOpen={onStoreOpen} />
            )}
        </MarkerClusterer>
    }
}

export default MapMarkerCluster;