import React from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import {
    Grid,
    Button,
    TextField,
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'
import { ErrorModal } from '../../components/ui/main'

import Layout from './components/Layout'
import Loader from '../../components/Loader'
import localize from '../../locale'
import UserNotification from '../../components/UserNotification'
import { withValidation } from '../../components/withValidation'

import styles from './ForgotPasswordComplete.styles'

const validationSchema = {
    password: ['required', 'password'],
    passwordConfirm: ['required', 'password', { match: 'password' }]
}

class ForgotPasswordComplete extends React.Component {
    state = {
        password: '',
        passwordConfirm: ''
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getErrorMessage = code => {
        const locale = localize(this.props.appState.lang).forgotPasswordComplete;

        if (code === 'PS03') {
            return locale.PS03_INVALID_PASSWORD;
        }

        if (code === 'PS04') {
            return locale.PS04_PASSWORD_ALREADY_USED;
        }

        if (code === 'PS06') {
            return locale.PS06_TOKEN_NOT_FOUND;
        }

        return locale.UNEXPECTED_ERROR;
    }

    submit = async e => {
        e.preventDefault();

        if (!this.props.validation.update(this.state)) {
            return;
        }

        const { userId, hash } = this.props.match.params;
        const locale = localize(this.props.appState.lang).forgotPasswordComplete;

        Loader.show();
        
        try {
            await Api.forgotPasswordComplete(userId, hash, {
                password: this.state.password
            });

            UserNotification.display(locale.PASSWORD_UPDATED);

            this.props.history.push('/login');
        } catch (error) {
            if (error.code) {
                UserNotification.display(this.getErrorMessage(error.code));
                return;
            }

            ErrorModal.invoke(error);
        } finally {
            Loader.hide();
        }
    }

    render() {
        const { appState, classes, validation } = this.props;
        const locale = localize(appState.lang).forgotPasswordComplete;
        const helperText = validation.errorText(locale.HELP);
        const actions = <Grid item xs={12} className={classes.footer}>
            <Grid container justify="center">
                <Link to="/login">{locale.BACK_LABEL}</Link>
            </Grid>
        </Grid>

        return <Layout actions={actions}>
            <Grid container justify="center" className={classes.formWrap}>
                <Grid item xs={12}>
                    <p className={classes.textPanel}>{locale.PAGE_TEXT}</p>
                    <TextField type="password" name="password" required autoFocus margin="normal" fullWidth
                        label={locale.PASSWORD_LABEL}
                        value={this.state.password} onChange={this.onChange}
                        error={validation.error.password}
                        helperText={helperText.password} />

                    <TextField type="password" name="passwordConfirm" required margin="normal" fullWidth
                        label={locale.PASSWORD_CONFIRMATION_LABEL}
                        value={this.state.passwordConfirm} onChange={this.onChange}
                        error={validation.error.passwordConfirm}
                        helperText={helperText.passwordConfirm} />
                </Grid>
                <Grid item xs={12} className={classes.buttonWrap}>
                    <Button type="submit" onClick={this.submit}>
                        {locale.SUBMIT}
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema)
])(ForgotPasswordComplete);
