import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import {
    AppBar,
    Button,
    Toolbar,
    withStyles
} from '@material-ui/core'

import {
    Home as HomeIcon,
    CreditCard as CardIcon,
    Add as AddIcon,
    PinDrop as PinDropIcon

} from '@material-ui/icons'

import { flow } from 'lodash'

import localize from '../../locale'

const styles = theme => ({
    root: {
        top: 'auto',
        bottom: 0,
        position: 'fixed',
        backgroundColor: '#f9f9f9',
    },
    toolbar: {
        alignSelf: 'center',
        '& button': {
            backgroundColor:'#f9f9f9',
            color: '#8e8e93',
            boxShadow: 'none',
            width: '200',
            '&:hover': {
                backgroundColor: 'inherit',
                color: '#f59100',
            },
            '&:active': {
                backgroundColor: 'inherit',
                color: '#f59100',
                boxShadow: 'none',
            },
            '& span': {
                flexDirection: 'column',
            }
        }
    },
    iconLabel: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
});

const BottomBar = props => {
    const { appState, classes } = props;
    const locale = localize(appState.lang).bottomBar;
    const redirect = to => props.history.push(to);

    if(!appState.isAuthenticated) {
        return null;
    }

    return <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
            <Button label="buttonIcon" variant="contained" onClick={() => redirect('/')}>
                <span className={classes.topIcon}><HomeIcon /></span>
                <span className={classes.iconLabel}>{locale.HOME}</span>
            </Button>
            <Button variant="contained" onClick={() => redirect('/manage')}>
                <span className={classes.topIcon}><CardIcon /></span>
                <span className={classes.iconLabel}>{locale.MANAGE}</span>
            </Button>
            <Button variant="contained" onClick={() => redirect('/manage/add')}>
                <span className={classes.topIcon}><AddIcon /></span>
                <span className={classes.iconLabel}>{locale.ADD}</span>
            </Button>
            <Button variant="contained" onClick={() => redirect('/affiliates')}>
                <span className={classes.topIcon}><PinDropIcon /></span>
                <span className={classes.iconLabel}>{locale.MAP}</span>
            </Button>
        </Toolbar>
    </AppBar>
};


export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withRouter
])(BottomBar);