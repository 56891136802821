import React from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import {
    Button,
    withStyles
} from '@material-ui/core'

import DatePicker from 'material-ui-pickers/DatePicker'
import InfiniteScroll from 'react-infinite-scroller'
import Slider from 'react-slick'

import { format as formatDate } from 'date-fns'
import { flow } from 'lodash'

import { Api } from '../../components/shared/main'

import { PrevArrow, NextArrow } from './components/Arrows'
import Loader from '../../components/Loader'
import localize from '../../locale'
import AuthenticatedLayout from '../components/AuthenticatedLayout'
import CreditCard from './components/CreditCard'

import styles from './Dashboard.styles'

import {
    DateRange as CalendarIcon,
} from '@material-ui/icons'

import heroImageLeft from '../../assets/images/hero-image-left.png'
import heroImageRight from '../../assets/images/hero-image-right.png'
import heroImageRightMobile from '../../assets/images/hero-image-right-mobile.png'

const pageCount = 15;

const formatBalance = (balance) => {
    const parts = balance.toString().split('.');

    let cents = '00';
    if (parts[1] !== undefined) {
        cents = parts[1].length === 1 ? `${parts[1]}0` : parts[1].substring(0, 2);
    }

    return <h1>{parts[0]},<span>{cents}<i>&euro;</i></span></h1>;
}

class Dashboard extends React.Component {
    state = {
        accountIndex: 0,
        cashbackAmount: null,
        startDate: null,
        endDate: null,
        page: 1,

        transactions: [],
        hasMoreTransactions: true,

        accounts: []
    }

    componentDidMount = async () => {
        const accounts = await this.getAccounts();

        this.setState({
            accounts
        }, () => {
            this.fetchTransactions();
            this.fetchCashbackAmount();
        });
    }

    getAccounts = async () => {
        const api = new Api(this.props.appState);
        const profile = await api.getProfile();
        return profile.accounts;
    }

    fetchCashbackAmount = async () => {
        const { accounts } = this.state;

        const api = new Api(this.props.appState);
        const rewards = await api.getHeroCorpRewards(accounts.map(x => x.id));

        this.setState({
            cashbackAmount: rewards.totalCashbackAmount
        });
    }

    onAccountChange = accountIndex => {
        this.setState({
            accountIndex,
            page: 1,
            transactions: [],
            hasMoreTransactions: true
        }, () => {
            this.fetchTransactions();
            this.fetchCashbackAmount();
        });
    }

    onStartDateChange = startDate => {
        this.setState({ startDate });
    }

    onEndDateChange = endDate => {
        this.setState({ endDate });
    }

    onSearch = () => {
        this.setState({
            page: 1,
            transactions: [],
            hasMoreTransactions: true
        }, this.fetchTransactions);
    }

    fetchTransactions = async () => {
        const { accounts } = this.state;

        if (accounts.length === 0) {
            return null;
        }

        const api = new Api(this.props.appState);
        const account = accounts[this.state.accountIndex];

        Loader.show();

        try {
            const response = await api.getTransactions(account.id, {
                page: this.state.page,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                perPage: pageCount
            });

            var transactions = [...this.state.transactions, ...response.results];

            this.setState({
                transactions,
                hasMoreTransactions: transactions.length < response.count,
                page: this.state.page + 1
            });
        } finally {
            Loader.hide();
        }
    }

    renderAccount = (account) => {
        const { classes } = this.props;
        const { card } = account;
        const locale = localize(this.props.appState.lang).dashboard;
        // first element here must be div for Slider to work
        return <div key={account.id} className={classes.slideWrap}>
            <div className={classes.slideCard}>
                <CreditCard
                    type={account.product}
                    name={card.name}
                    number={card.pan}
                    expiry={card.expire}
                />
            </div>
            <div className={classes.slideCardData}>
                <div className={classes.slideCardBalance}>
                    <h2>{locale.MY_BALANCE}</h2>
                    {formatBalance(account.balance)}
                </div>
            </div>
        </div>
    }

    renderAccountBonus = (account) => { // to be inserted above renderAccount #130
        const { classes } = this.props;
        // eslint-disable-next-line
        const { card } = account;
        const locale = localize(this.props.appState.lang).dashboard;
        return <div className={classes.slideCardReturn}>
            <h6>{locale.MY_BONUS}</h6>
            <h3>+17,<span>05<i>&euro;</i></span></h3>
        </div>
    }

    renderTransaction = ts => {
        const { classes } = this.props;
        const sign = ts.transaction_type === 'CREDIT' ? '+' : '-';
        const isCashBack = ts.transaction_status === 'CASHBACK';
        const transactionType = isCashBack ? ts.transaction_status : ts.transaction_type;

        return <div key={ts.transaction_id} className={classes.tRow}>
            <p className={classes.tCellLeft}>
                {ts.affiliate.name}
                <span>{formatDate(ts.date, 'dd/MM/YYYY')}</span>
            </p>
            <p className={classes.tCellRight}>
                <span className={'tCell-' + transactionType}>{sign}{(ts.amount).toFixed(2)}&euro;</span>
            </p>
        </div>
    }

    renderCashback = () => {
        const { classes } = this.props;
        const { accounts, cashbackAmount } = this.state;
        const locale = localize(this.props.appState.lang).dashboard;

        if(!cashbackAmount) {
            return null;
        }

        return accounts.length > 0 && <div className={classes.heroCorpContainer}>
            <div>
                <h2>{locale.HEROCORP}:&nbsp;</h2>
                {formatBalance(cashbackAmount)}
            </div>
        </div>
    }

    render() {
        const { classes } = this.props;
        const { accounts } = this.state;
        const locale = localize(this.props.appState.lang).dashboard;
        const settings = {
            dots: true,
            infinite: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };

        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classNames(classes.container, classes.headerContainer)}>
                    <h5>{locale.MY_CARDS}</h5>

                    {accounts.length > 0 && <Slider {...settings} className={classes.cardsSlider} afterChange={this.onAccountChange}>
                        {accounts.map(this.renderAccount)}
                    </Slider>}
                    {this.renderCashback()}
                </div>
                <div className={classes.graphicLeft}>
                    <img src={heroImageLeft} alt="" />
                </div>
                <div className={classes.graphicRight}>
                    <img src={heroImageRight} alt="" />
                </div>
                <div className={classes.graphicRightMobile}>
                    <img src={heroImageRightMobile} alt="" />
                </div>
            </div>

            <div className={classes.mainRow}>
                <div className={classes.mainHead}>
                    <div className={classes.container}>
                        <h5>{locale.TRANSACTIONS}</h5>
                        <div className={classes.transactionsDateSelect}>
                            <span>
                                <CalendarIcon className={classes.iconCalendar} />
                            </span>

                            <DatePicker clearable disableFuture format='PP' emptyLabel={locale.FROM}
                                value={this.state.startDate}
                                onChange={this.onStartDateChange} />

                            <DatePicker clearable disableFuture format='PP' emptyLabel={locale.TO}
                                value={this.state.endDate}
                                onChange={this.onEndDateChange} />

                            <div className={classes.buttonWrap}><Button type="submit" onClick={this.onSearch}>{locale.SEARCH}</Button></div>
                        </div>
                    </div>
                </div>
                <div className={classes.tContainer}>

                    <InfiniteScroll initialLoad={false}
                        loadMore={this.fetchTransactions}
                        hasMore={this.state.hasMoreTransactions}>
                        {this.state.transactions.map(this.renderTransaction)}
                    </InfiniteScroll>

                </div>
            </div>
        </AuthenticatedLayout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(Dashboard)