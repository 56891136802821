export default theme => ({
    root: {
        height: '100vh',
        justifyContent: 'center',
    },
    container: {
        maxWidth: 1200,
        margin: '0 auto',
        padding: '0 15px',
    },
    header: {
        width: '100%',
        paddingTop: 20,
        display: 'block',
        textAlign: 'center',
    },
    logo: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 10,
    },
    panel: {
        backgroundColor: '#ececec',
        borderTop: '7px solid #485756',
        marginTop: '-26px',
        '& .container': {
            position: 'relative',
            paddingTop: 30
        },
        '& h5': {
            position: 'absolute',
            top: 0,
            left: 0,
            margin:0,
            padding: '0px 18px 7px 18px',
            borderRadius: '0 0 6px 6px',
            backgroundColor: '#485756',
            color: '#fff',
            fontSize: 16,
            fontWeight: 400,
        }
    },
    card: {
        marginTop: 40,
        textAlign: 'center',
        fontSize: 16,
        letterSpacing: '0.025em',
        '& img': {
            margin: '0 auto 16px auto',
        },
        '& p': {
            marginBottom: 20,
        },
        '& span': {
            display: 'inline-block',
            margin: '0 auto',
        }
    },
    regForm: {
        maxWidth: 1000,
        margin: '0 auto',
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: '#ccc',
    },
    formRow: {
        maxWidth: 380,
        margin: '10px auto 30px auto',
        '& > div': {
            marginBottom: 18,
        }
    },
    buttonsWrap: {
        marginTop: 30,
        '& button': {
            padding: '4px 12px',
            width: '47.5%',
            boxShadow: 'none',
        },
        '& button:first-child': {
            marginRight: '5%',
        }
    },
    buttonSubmit: {
        backgroundColor: '#f99c24 !important',
        color: '#000 !important',
        '&:hover': {
            backgroundColor: '#eab46d !important',
        }
    },
    buttonReset: {
        backgroundColor: '#485756',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#ccc',
        }
    },
    
});