import React from 'react'
import { 
    camelCase, fromPairs, get, 
    isArray, isString, isNil, isFunction, isObject, 
    keys, pickBy, some, snakeCase, toPairs 
} from 'lodash'

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
//const usPhoneRegex = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

const fieldHasError = (name, validators, value, o) => {
    validators = validators || [];

    if(!isArray(validators)) {
        validators = [validators];
    }

    return some(validators, validator => {
        if(validator === 'required') {
            if(!value) {
                return true;
            }
            
            if(isString(value)) {
                return value.trim() === '';
            }

            return false;
        }

        if(validator === 'email') {
            return !value || !emailRegex.test(value);
        }

        if(validator === 'password') { // always required
            return !value 
                || value.length < 4
                || /[^0-9a-zA-Z\d!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~£€]/.test(value);
        }

        if(validator === 'date') {
            if(!value) {
                return false; // allow no value, required is elsewhere
            }

            const d = isString(value) ? new Date(value) : value;
            return isNaN(d.getTime());
        }

        if(validator === 'number') {
            return isNil(value) || isNaN(value);
        }

        if(isObject(validator)) {
            if(validator.minLength && validator.maxLength) {
                return value.length < validator.minLength || value.length > validator.maxLength;
            }

            if (validator.minLength) {
                return value.length < validator.minLength;
            }
            
            if (validator.maxLength) {
                return value.length > validator.maxLength;
            }
            
            if(validator.match) {
                return value !== o[validator.match];
            }

            if(validator.mismatch) {
                return value === o[validator.mismatch];
            }
        }

        if(isFunction(validator)) {
            return validator(name, value, o);
        }

        throw new Error(`Unknown validator for field ${validator }`);
    });
}

const getErrors = (schema, model) => {
    const names = keys(schema);
    let errors = { };
    for(let i = 0; i < names.length; ++i) {
        const name = names[i];
        errors[name] = fieldHasError(name, schema[name], get(model, name), model);
    }
    return errors;
}

const withValidation = (schema) => {
    return Component => {
        class Validator extends React.Component {
            state = {
                model: {},
                error: {}
            }

            update = model => {
                const errors = getErrors(schema, model);
                this.setState({ 
                    model,
                    error: {
                        ...this.state.error,
                        ...errors
                    }
                });
                const withError = pickBy(errors, v => v === true);
                return keys(withError).length === 0;
            }

            errorText = (messages) => {
                const pairs = toPairs(this.state.error)
                    .filter(pair => !!pair[1])
                    .map(pair => {
                        const message = messages[snakeCase(pair[0]).toUpperCase()];
                        return [camelCase(pair[0]), message];
                    });

                return fromPairs(pairs);
            }

            render() {
                const { forwardedRef, ...rest } = this.props;

                const validation = {
                    error: this.state.error,
                    errorText: this.errorText,
                    reset: () => this.setState({ error: {} }),
                    update: this.update                    
                }

                return <Component validation={validation} 
                    ref={forwardedRef} innerRef={forwardedRef} {...rest} />
            }
        }

        return React.forwardRef((props, ref) => {
            return <Validator {...props} forwardedRef={ref} />;
        });
    }
}

export { withValidation };
