import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    MenuItem,
    TextField
} from '@material-ui/core'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'
import localize from '../../locale'

class SecurityQuestionSelect extends React.Component {
    state = {
        securityQuestions: null
    }

    async componentDidMount() {
        const securityQuestions = await Api.getSecurityQuestions();
        this.setState({ securityQuestions });
    }

    render() {
        if (!this.state.securityQuestions) {
            return null;
        }

        const locale = localize(this.props.appState.lang).securityQuestions;
        return <TextField name="securityQuestion" select required
            value={this.props.value} onChange={this.props.onChange}
            margin="normal" fullWidth>
            {this.state.securityQuestions.map(q => <MenuItem key={q.id} value={q.id}>
                {locale[q.id]}
            </MenuItem>)}
        </TextField>
    }
}

export default flow([
    observer,
    inject('appState')
])(SecurityQuestionSelect);