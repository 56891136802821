import giftCard from '../assets/images/card-empty-gift.png'
import holidaysCard from '../assets/images/card-empty-holidays.png'
import mealCard from '../assets/images/card-empty.png'
import giftCardBig from '../assets/images/card-empty-gift-large.png'
import holidaysCardBig from '../assets/images/card-empty-holidays-large.png'
import mealCardBig from '../assets/images/card-empty-large.png'

import holidaysBg from '../assets/images/card-holidays-bg.png'
import giftBg from '../assets/images/card-gift-bg.png'
import mealBg from '../assets/images/card-meal-bg.png'

const MEAL_CARD = 11
const GIFT_CARD = 12
const HOLIDAY_CARD = 13

const isGiftCard = product => product === GIFT_CARD
const isHolidaysCard = product => product === HOLIDAY_CARD

const getCardBgImg = type => {
    if (isGiftCard(type)) {
        return giftBg
    }

    if (isHolidaysCard(type)) {
        return holidaysBg
    }

    return mealBg
}

const getCardColouring = type => {
    if (isGiftCard(type)) {
        return '#424c4a'
    }

    if (isHolidaysCard(type)) {
        return '#47afd3'
    }

    return '#ff9e19'
}

const getCardImg = (type, size = 'standard') => {
    const isLarge = size === 'large'
    const isSmall = size === 'small'

    if (isGiftCard(type)) {
        return isLarge ? giftCardBig : giftCard
    }

    if (isHolidaysCard(type)) {
        return isLarge ? holidaysCardBig : holidaysCard
    }

    return isLarge ? mealCardBig : mealCard
}

export {
    MEAL_CARD,
    GIFT_CARD,
    HOLIDAY_CARD,
    getCardBgImg,
    getCardColouring,
    getCardImg,
    isGiftCard,
    isHolidaysCard
}
