import React from 'react'
import { observer, inject } from 'mobx-react'

import { flow } from 'lodash'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import localize from '../locale'

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        minHeight: 500
    },
    paper: {
        padding: 40
    }
})

class Error extends React.Component {
    render() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).errorAlert;
        return <Grid container justify="center" alignItems="center" className={classes.root}>
            <Grid item />
            <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <Typography variant="h1" gutterBottom>{locale.TITLE}</Typography>
                    <Typography variant="h4" gutterBottom>
                        {locale.SUBTITLE}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {locale.BODY1}
                    </Typography>
                    <Typography variant="body1">
                        {locale.BODY2}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item />
        </Grid>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(Error);