import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
          // light:,
          main: '#f59100'
          // dark:
        },
        secondary: {
          light: '#f9f9f9',
          main: '#485756'
          //dark: ,
          //contrastText:,
        },
        // error: will use the default color
      },
    typography: {
        useNextVariants: true,
    },
    // in this section you override the component styles
    // the styles for each component are in the CSS API section
    // of the docs - see button example below
    overrides: {
        MuiCssBaseline: {
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: 0
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 18,
                letterSpacing: '-0.05em',
                color: '#000',
                fontWeight: 300,
            }
        },
        MuiInput: {
            input: {
                fontSize: 16,
                letterSpacing: '-0.05em',
            },
            underline: {
                '&:before': {
                    borderBottomColor: '#ccc'
                },
                '&:after': {
                    borderBottomColor: '#f59100'
                }
            }
        },
        MuiSelect: {
            root: {
                marginTop: 30
            },
            select: {
                fontSize: 18,
                letterSpacing: 'normal',
                border: '1px solid #ccc',
                padding: '15px 0 12px 24px'
            },
            icon: {
                right: 17
            }
        },
        MuiSwitch: {
            root: {
                //marginTop: 4
            }
        },
        MuiButton: {
            label: {
                textTransform: 'none'
            }
        }
    }
});