import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    FormGroup,
    TextField,
    withStyles
} from '@material-ui/core'

import { flow, pick } from 'lodash'

import { Api } from '../components/shared/main'

import AuthenticatedLayout from './components/AuthenticatedLayout'
import Loader from '../components/Loader'
import localize from '../locale'
import UserNotification from '../components/UserNotification'
import { disableAutocomplete } from '../components/uifix'
import { withValidation } from '../components/withValidation'

import {
    Email as EmailIcon,
} from '@material-ui/icons'

import styles from './Contact.styles'

const validationSchema = {
    phone: 'required',
    message: 'required'
}

class Contact extends React.Component {
    state = {
        phone: '',
        message: ''
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    submit = async (e) => {
        e.preventDefault();

        if (!this.props.validation.update(this.state)) {
            return;
        }

        const locale = localize(this.props.appState.lang).contact;
        const model = pick(this.state,
            ['phone', 'message']);

        Loader.show();

        try {
            await Api.contactRequest(model);

            UserNotification.display(locale.MESSAGE_SENT);
        } finally {
            Loader.hide();
        }
    }

    render() {
        const { appState, classes, validation } = this.props;
        const locale = localize(appState.lang).contact;
        const helperText = validation.errorText(locale.HELP);
        return <AuthenticatedLayout>
            <div className={classes.headingRow}>
                <div className={classes.container}>

                    <h5>{locale.CONTACT_TITLE}</h5>

                    <div className={classes.headingContainer}>
                        <div className={classes.headingWrap}>
                            <EmailIcon className={classes.accountIconFull} />
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.mainRow}>
                <div className={classes.container}>
                    <p>{locale.CONTACT_TEXT}</p>
                    <div className={classes.formRow}>

                        <form noValidate>
                            <FormGroup className={classes.formControl}>
                                <TextField name="phone" required
                                    label={locale.PHONE} margin="normal"
                                    value={this.state.phone}
                                    onChange={this.onChange}
                                    error={validation.error.phone}
                                    helperText={helperText.phone} />
                            </FormGroup>
                            <TextField className={classes.formMessage} name="message" multiline rows={10} required fullwidth="true"
                                label={locale.MESSAGE} margin="normal" variant="outlined"
                                value={this.state.message}
                                onChange={this.onChange}
                                error={validation.error.message}
                                helperText={helperText.message} />

                            <div className={classes.buttonsWrap}>
                                <Button type="submit" onClick={this.submit}>{locale.SUBMIT}</Button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </AuthenticatedLayout>

    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema),
    disableAutocomplete
])(Contact);
