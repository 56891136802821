import React, { Fragment as F } from 'react'

const el = {
    forceAcceptTerms: {
        TITLE: 'Οι Όροι Χρήσης έχουν αλλάξει.',
        PRETEXT: 'Πριν συνεχίσετε πρέπει να διαβάσετε και να αποδεχτείτε τους νέους Όρους Χρήσης:',
        ACCEPT_TERMS_LABEL: (<F>
            Αποδέχομαι τους όρους χρήσης της <a href="/terms-app" target="_blank">εφαρμογής</a> και της <a href="/terms-card" target="_blank">κάρτας</a>
        </F>),
        ACCEPT_PRIVACY_POLICY_LABEL: (<F>
            Αποδέχομαι την <a href="/privacy-policy" target="_blank">πολιτική απορρήτου</a>
        </F>),
        SUBMIT: 'ΑΠΟΣΤΟΛΗ',
        HELP: {
            TERMS_AND_PRIVACY: 'Προκειμένου να περιηγηθείτε στην εφαρμογή θα πρέπει να αποδεχθείτε τους όρους χρήσης και την πολιτική απορρήτου'
        }
    },
    account: {
        MY_ACCOUNT: 'Ο Λογαριασμός Μου',
        HELLO: 'Γειά σου ',
        CHANGE_NAME: 'Αλλαγή Ονόματος',
        FIRST_NAME: 'Όνομα',
        LAST_NAME: 'Επώνυμο',
        CHANGE_PASSWORD: 'Αλλαγή Κωδικού',
        OLD_PASSWORD: 'Προηγούμενος Κωδικός',
        NEW_PASSWORD: 'Νέος Κωδικός',
        REPEAT_NEW_PASSWORD: 'Επανάληψη Νέου Κωδικού',
        BS02_INCORRECT_PASSWORD: 'Μη έγκυρος κωδικός πρόσβασης',
        BS03_INVALID_PASSWORD: 'Μη έγκυρος κωδικός πρόσβασης, πρέπει να περιέχει πάνω από 3 χαρακτήρες.',
        BS04_PASSWORD_ALREADY_USED: 'Αυτός ο κωδικός έχει ήδη χρησιμοποιηθεί.',
        PASSWORD_CHANGE_SUCCESS: 'Ο κωδικός σου άλλαξε!',
        CHANGE_EMAIL: 'Αλλαγή Email',
        NEW_EMAIL: 'Καινούριο Email',
        CHANGE_QUESTION: 'Αλλαγή Ερώτησης Ασφαλείας',
        SECURITY_QUESTION_SUCCESS: 'Η ερώτηση ασφαλείας άλλαξε!',
        CHANGE_LANGUAGE: 'Αλλαγή Γλώσσας',
        LANG_GR: 'Ελληνικά / Greek',
        LANG_EN: 'Αγγλικά / English',
        CHANGE_PERMISSIONS: 'Αλλαγή Αδειών Εφαρμογής',
        CANCEL: 'Ακύρωση',
        SUBMIT: 'Αποθήκευση',
        PERMISSIONS: {
            TITLE: 'Επιλέξτε ποιές από τις ακόλουθες άδειες θέλετε να αλλάξετε',
            LOCATION: 'Εντοπισµός της Τοποθεσίας µου',
            MARKETING_EMAILS: 'Επιθυµώ να Λαµβάνω Προωθητικά Emails',
            SURVEYS: 'Επιθυµώ να Συµµετέχω σε Έρευνες',
            CLOSE_DIALOG: 'Κλείσιμο'
        },
        UNEXPECTED_ERROR: 'Ουπς! Δυστυχώς παρουσιάστηκε κάποιο πρόβλημα. Η ομάδα μας επεξεργάζεται τη λύση του!',
        HELP: {
            EMAIL: 'Συμπληρωσε ενα εγκυρο e-mail, που να ειναι διαφορετικο απο αυτο που εχεις ηδη.',
            PASSWORD: 'Συμπλήρωσε τον κωδικό σου.',
            NEW_PASSWORD: 'Επίλεξε έναν κωδικό που έχει πάνω από 3 χαρακτήρες.',
            NEW_PASSWORD_CONFIRM: 'Συμπλήρωσε ξανά τον κωδικό σου.',
            SECURITY_ANSWER: 'Συμπλήρωσε την απάντηση στην ερώτηση ασφαλείας που έχεις επιλέξει από 3 εώς 100 χαρακτήρες.'
        }
    },
    activate: {
        FAILED: 'Ο σύνδεσμος επαλήθευσης δεν βρέθηκε ή η επαλήθευση απέτυχε. Δοκίμασε ξανά.',
        SUCCEED: 'Η επαλήθευση του email σου πραγματοποιήθηκε!',
        BUTTON_OK: 'OK',
    },
    addCard: {
        ADD_CARD_TITLE: 'Εισαγωγή νέας κάρτας',
        ADD_CARD_TEXT: 'Συμπλήρωσε τον αριθμό και την ημ/νία λήξης της νέας κάρτας όπως εμφανίζεται στην κάρτα.',
        CARD_NUMBER: 'Αριθμός κάρτας',
        CARD_EXPIRY: 'Ημ/νία λήξης κάρτας',
        CANCEL: 'Ακύρωση',
        ADD: 'Προσθήκη',
        HELP: {
            CARD_NUMBER: 'Συμπλήρωσε τον αριθμό της κάρτας σου.',
            CARD_EXPIRY: 'Συμπλήρωσε την ημ/νία λήξης της κάρτας σου.'
        }
    },
    affiliates: {
        AFFILIATES_TITLE: 'Χάρτης Εμπ. Συνεργατών',
        SEARCH_TITLE: 'Οι συνεργαζόμενες μας επιχειρήσεις',
        SEARCH_PLACEHOLDER: 'Αναζήτηση ανά περιοχή, είδος, όνομα καταστήματος',
        UP_CARD: 'Κάρτα GoforEat',
        UP_VOUCHER: 'Διατακτικές Cheques Dejeuner',
        METERS: 'μ',
        KMETERS: 'χλμ',
        SEARCH_HERE: 'Αναζητηση Εδω'
    },
    bottomBar: {
        HOME: 'Αρχική',
        MANAGE: 'Διαχείριση Καρτών',
        ADD: 'Προσθήκη Κάρτας',
        MAP: 'Χάρτης',
        //MAP: 'Χάρτης Συνεργαζόμενων Καταστημάτων',
    },
    confirmationDialog: {
        YES: 'Ναι',
        NO: 'Όχι',
    },
    contact: {
        CONTACT_TEXT: <F>Συμπληρώσε την παρακάτω φόρμα και ένας εκπρόσωπός της <span>Up Hellas</span> θα<br />
            επικοινωνήσει μαζί σου μέσα σε μία εργάσιμη ημέρα.
        </F>,
        PHONE: 'Τηλέφωνο',
        MESSAGE: 'Μήνυμα',
        CONTACT_TITLE: 'Επικοινωνία',
        SUBMIT: 'Αποστολή',
        MESSAGE_SENT: 'Το μήνυμά σου στάλθηκε επιτυχώς!',
        HELP: {
            PHONE: 'Συμπλήρωσε το τηλέφωνό σου.',
            MESSAGE: 'Συμπλήρωσε το μήνυμά σου.'
        }
    },
    crediCard: {
        VALID_THRU: 'VALID THRU',
    },
    dashboard: {
        HEROCORP: 'HeroCorp',
        MY_BALANCE: 'Το υπόλοιπό μου',
        MY_BONUS: 'Η επιστροφή μου',
        MY_CARDS: 'Οι Κάρτες μου',
        TRANSACTIONS: 'Οι Συναλλαγές μου',
        FROM: 'Aπό',
        TO: 'Μέχρι',
        SEARCH: 'Αναζήτηση',
    },
    errorAlert: {
        TITLE: 'Ουπς!',
        SUBTITLE: 'Δυστυχώς παρουσιάστηκε κάποιο πρόβλημα. Η ομάδα μας επεξεργάζεται τη λύση του!',
        BODY1: 'Ανανέωσε τη σελίδα και προσπάθησε ξανά.',
        BODY2: 'Εάν το σφάλμα επιμένει, επικοινώνησε μαζί μας.',
    },
    networkError: {
        ALERT: 'Η εφαρμογή δεν μπόρεσε να συνδεθεί στο διαδίκτυο. Παρακαλώ ελέγξτε την σύνδεση σας.'
    },
    faqText: {
        FAQ_TITLE: 'Συχνές Ερωτήσεις',
    },
    forgotPassword: {
        PAGE_TEXT: 'Συμπλήρωσε το email με το οποίο έχεις εγγραφεί στην υπηρεσία και θα σου στείλουμε ένα link εισαγωγής νέου κωδικού.',
        BACK_LABEL: 'Πίσω',
        SUBMIT: 'Αποστολή',

        PS01_EMAIL_NOT_FOUND: 'Το email σου δεν βρέθηκε.',
        FORGOT_PASSWORD_EMAIL_SENT: 'Στείλαμε ένα μήνυμα για την επαναφορά του κωδικού σου στο email σου!',
        HELP: {
            EMAIL: 'Παρακαλώ συμπληρώστε το e-mail σας.'
        }
    },
    forgotPasswordComplete: {
        PAGE_TEXT: 'Συμπλήρωσε το καινούριο σου κωδικό για είσοδο στην υπηρεσία.',
        PASSWORD_LABEL: 'Κωδικός',
        PASSWORD_CONFIRMATION_LABEL: 'Επανάληψη Κωδικού',
        BACK_LABEL: 'Επιστροφή',
        SUBMIT: 'Επιβεβαίωση',

        PS03_INVALID_PASSWORD: 'Μη έγκυρος κωδικός πρόσβασης, πρέπει να περιέχει πάνω από 3 χαρακτήρες.',
        PS04_PASSWORD_ALREADY_USED: 'Ο κωδικός χρησιμοποιείται ήδη.',
        PS06_TOKEN_NOT_FOUND: ' Ο σύνδεσμος επαλήθευσης δεν βρέθηκε.',
        UNEXPECTED_ERROR: ' Ουπς! Δυστυχώς παρουσιάστηκε κάποιο πρόβλημα. Η ομάδα μας επεξεργάζεται τη λύση του!',
        PASSWORD_UPDATED: 'Ο κωδικός σου άλλαξε!',

        HELP: {
            PASSWORD: 'Επίλεξε έναν κωδικό που έχει πάνω από 3 χαρακτήρες.',
            PASSWORD_CONFIRM: 'Συμπλήρωσε ξανά τον κωδικό σου.',
        }
    },
    login: {
        EMAIL_LABEL: 'Email',
        PASSWORD_LABEL: 'Κωδικός',
        LANGUAGE_TOGGLE: 'Ελληνικά / English',
        SUBMIT: 'Σύνδεση',
        FORGOT_PASSWORD: 'Ξέχασες τον κωδικό σου;',
        FORGOT_PASSWORD_LINK: 'Πάτα εδώ',
        REGISTER: 'Δεν έχεις λογαριασμό;',
        REGISTER_LINK: 'Δημιούργησε εδώ',
        REMEMBER: 'Κράτησε με συνδεδεμένο',
        INVALID_CREDENTIALS: 'Μη έγκυρα στοιχεία',
        HELP: {
            EMAIL: 'Συμπλήρωσε το e-mail σου.',
            PASSWORD: 'Συμπλήρωσε τον κωδικό σου.'
        }
    },
    manageCards: {
        labels: {
            ENABLE: 'Ξεκλείδωμα Κάρτας',
            DISABLE: 'Κλείδωμα Κάρτας',
            ENABLE_CONTACTLESS: 'Ενεργοποιήση Ανέπαφων Συν.',
            DISABLE_CONTACTLESS: 'Απενεργοποιήση Ανέπαφων Συν.',
            DELETE: 'Ακύρωση Κάρτας',
        },
        MANAGE_CARDS: 'Διαχείριση Καρτών',
        ADD_CARD: 'Προσθήκη Κάρτας',
        CARD_DELETE_SUCCESS: 'Η κάρτα σου έχει ακυρωθεί!',
        CARD_ADD_SUCCESS: 'Η κάρτα σου προστέθηκε!',
        DELETE_CARD_TITLE: 'Ακύρωση κάρτας',
        DELETE_CARD_QUESTION: <F>Η ακύρωση της κάρτας είναι οριστική και μη αναστρέψιμη! Δεν θα μπορείς να χρησιμοποιήσεις ξανά την κάρτα σου.<br />Είσαι σίγουρος ότι θέλεις να την ακυρώσεις?</F>,
        AS03_ACCOUNT_ALREADY_USED: 'Αυτός ο λογαριασμός χρησιμοποιείται ήδη.',
        AS06_NO_USER_FOUND: 'Δεν βρέθηκε χρήστης με αυτά τα στοιχεία.',
        AS11_CARD_BLOCKED: 'Η κάρτα αυτή είναι μπλοκαρισμένη.',
        UNEXPECTED_ERROR: 'Ουπς! Δυστυχώς παρουσιάστηκε κάποιο πρόβλημα. Η ομάδα μας επεξεργάζεται τη λύση του!'
    },
    register: {
        LANG_GR: 'Ελληνικά / Greek',
        LANG_EN: 'Αγγλικά / English',
        PAGE_TITLE: 'Δημιουργία Λογαριασμού',
        CARD_NOTE: (<F>
            Συμπλήρωσε τα παρακάτω στοιχεία, <br />
            όπως αυτά αναγράφονται στην κάρτα σου.
        </F>),
        CARD_NOTE_2: '',
        EMAIL_LABEL: 'Email',
        CARD_NUMBER_LABEL: 'Αριθμός Κάρτας',
        CARD_EXPIRY_LABEL: 'Ημ/νία Λήξης Κάρτας',
        PASSWORD_LABEL: 'Κωδικός',
        PASSWORD_CONFIRM_LABEL: 'Επανάληψη Κωδικού',
        SECURITY_QUESTION_LABEL: 'Ερώτηση Ασφαλείας',
        SECURITY_ANSWER_LABEL: 'Απάντηση Ασφαλείας',
        ACCEPT_TERMS_LABEL: (<F>
            Αποδέχομαι τους όρους χρήσης της <a href="/terms-app" target="_blank">εφαρμογής</a> και της <a href="/terms-card" target="_blank">κάρτας</a>
        </F>),
        ACCEPT_PRIVACY_POLICY_LABEL: (<F>
            Αποδέχομαι την <a href="/privacy-policy" target="_blank">πολιτική απορρήτου</a>
        </F>),
        SUBMIT: 'Εγγραφή',
        CANCEL: 'Ακύρωση',

        LS03_INVALID_PASSWORD: 'Μη έγκυρος κωδικός πρόσβασης, πρέπει να περιέχει πάνω από 3 χαρακτήρες.',
        LS05_EMAIL_ALREADY_USED: 'Αυτό το email χρησιμοποιείται ήδη.',
        LS06_NO_USER_FOUND: 'Δεν βρέθηκε χρήστης με αυτά τα στοιχεία.',
        LS07_USER_ALREADY_REGISTERED: 'Ο χρήστης έχει ήδη εγγραφεί.',
        LS10_CARD_ALREADY_REGISTERED: 'Αυτή η κάρτα χρησιμοποιείται ήδη.',
        LS11_CARD_BLOCKED: 'Η κάρτα αυτή είναι μπλοκαρισμένη.',
        UNEXPECTED_ERROR: 'Ουπς! Δυστυχώς παρουσιάστηκε κάποιο πρόβλημα. Η ομάδα μας επεξεργάζεται τη λύση του!',

        HELP: {
            EMAIL: 'Συμπλήρωσε το email σου.',
            CARD_NUMBER: 'Συμπλήρωσε τον αριθμό της κάρτας σου.',
            CARD_EXPIRY: 'Συμπλήρωσε την ημ/νία λήξης της κάρτας σου.',
            PASSWORD: 'Ο κωδικός σου πρέπει να έχει περισσότερους από 3 χαρακτήρες.',
            PASSWORD_CONFIRM: 'Συμπλήρωσε ξανά τον κωδικό σου.',
            SECURITY_ANSWER: 'Συμπλήρωσε την απάντηση στην ερώτηση ασφαλείας που έχεις επιλέξει από 3 εώς 100 χαρακτήρες.',
            TERMS_AND_PRIVACY: 'Προκειμένου να περιηγηθείτε στην εφαρμογή θα πρέπει να αποδεχθείτε τους όρους χρήσης και την πολιτική απορρήτου'
        }
    },
    securityQuestions: {
        1: 'Ποιά είναι η αγαπημένη σου ταινία;',
        2: 'Ποιό είναι το όνομα του πρώτου/τωρινού/αγαπημένου σου κατοικιδίου;',
        3: 'Ποιό είναι το αγαπημένο σου φαγητό;',
        4: 'Ποιό είναι το αγαπημένο σου μέρος για διακοπές;'
    },
    sidebar: {
        LOGIN: 'Σύνδεση',
        ADD_CARD: 'Προσθήκη Κάρτας',
        FAQ: 'Συχνές Ερωτήσεις',
        MAP: 'Χάρτης Εμπ. Συνεργατών',
        PRIVACY_POLICY: 'Πολιτική Απορρήτου',
        TERMS_APP: 'Όροι χρήσης Εφαρμογής',
        TERMS_CARD: 'Όροι χρήσης Κάρτας',
        CONTACT: 'Επικοινωνία',
        LOGOUT: 'Αποσύνδεση'
    },
    storeDialog: {
        OFFERS: 'ΤΙ ΠΡΟΣΦΕΡΕΙ;',
        ADDRESS: 'ΔΙΕΥΘΥΝΣΗ',
        PHONE: 'ΤΗΛΕΦΩΝΟ',
        HOURS: 'ΩΡΕΣ ΛΕΙΤΟΥΡΓΙΑΣ',
        WEBSITE: 'ΙΣΤΟΣΕΛΙΔΑ',
        ACCEPTS: 'ΔΕΧΟΝΤΑΙ',
        DELIVERY: 'Delivery',
        ONLINE_ORDER: 'Online Παραγγελίες',
        POS: 'POS',
        MOBILE_POS: 'Mobile POS'
    },
    terms: {
        PRIVACY_POLICY: 'Πολιτική Απορρήτου',
        TERMS_APP_TITLE: 'Όροι και Προϋποθέσεις Χρήσης Εφαρμογής',
        TERMS_CARD_TITLE: 'Όροι και Προϋποθέσεις Xρήσης Κάρτας',
    },
    verifyEmail: {
        EMAIL_ALREADY_REGISTERED_TITLE: 'Η Eγγραφή Aπέτυχε.',
        EMAIL_ALREADY_REGISTERED_CONTENT: 'Αυτό το email χρησιμοποιείται ήδη.',
        VERIFY_EMAIL_TITLE: 'Επιβεβαίωση Email',
        VERIFY_EMAIL_TEXT: 'Στείλαμε ένα μήνυμα επιβεβαίωσης στο email σου. Πάτα τον σύνδεσµο σε αυτό το μήνυμα και η αλλαγή θα ολοκληρωθεί.'
    },
    faq:
        [
            {
                q: 'Μόλις έλαβα την κάρτα μου, τι πρέπει να κάνω;',
                a: <F>
                    Το πρώτο πράγμα που πρέπει να κάνετε είναι να την υπογράψετε στο πίσω μέρος.<br />
                    Μετά από αυτό, η κάρτα σας είναι έτοιμη για χρήση.
                </F>
            },
            {
                q: 'Τι είδους κάρτα είναι αυτή;',
                a: <F>
                    Η κάρτα go for EΑΤ είναι μια προπληρωμένη κάρτα γεύματος. Μόνο ο εργοδότης σας μπορεί να τη φορτίσει.
                </F>
            },
            {
                q: 'Πώς ενεργοποιώ την κάρτα μου;',
                a: <F>
                    Η κάρτα σας θα ενεργοποιηθεί μόλις ο εργοδότης σας τη φορτίσει την πρώτη φορά.
                </F>
            },
            {
                q: 'Η κάρτα μου έχει PIN;',
                a: <F>
                    O τύπος της κάρτας που έχετε λάβει (με ή χωρίς PIN) έχει αποφασιστεί από τον εργοδότη σας.<br /><br />
                    Αν δεν έχετε λάβει κωδικό PIN μαζί με την κάρτα σας, τότε η κάρτα σας δεν έχει PIN.<br />
                    Στην περίπτωση αυτή, πληρώνοντας,  μπορεί να σας ζητηθεί να υπογράψετε την απόδειξη, επομένως μην ξαφνιαστείτε αν συμβεί αυτό.<br /><br />
                    Αν έχετε λάβει κωδικό PIN μαζί με την κάρτα σας, τότε θα σας ζητηθεί να πληκτρολογήσετε τον κωδικό PIN για συναλλαγές που δεν είναι ανέπαφες.
                </F>
            },
            {
                q: 'Μπορώ να χρησιμοποιήσω την κάρτα μου στο εξωτερικό;',
                a: <F>
                    Όχι, η συγκεκριμένη κάρτα είναι περιορισμένης χρήσης. Αυτό σημαίνει ότι μπορεί να χρησιμοποιηθεί μόνο για την αγορά τροφίμων και γευμάτων στην Ελλάδα.
                </F>
            },
            {
                q: 'Πώς κλειδώνω την κάρτα μου;',
                a: <F>
                    Μπορείτε να κλειδώσετε την κάρτα σας από την εφαρμογή του κινητού σας ή από την ιστοσελίδα μας <a href='https://app.uphellas.gr'>app.uphellas.gr</a><br />
                    Μπορείτε να βρείτε αυτή τη λειτουργία <a href='/manage'>εδώ</a>.
                </F>
            },
            {
                q: 'Πώς μπορώ να ακυρώσω την κάρτα μου;',
                a: <F>
                    Μπορείτε να ακυρώσετε την κάρτα σας από την εφαρμογή του κινητού σας ή από την ιστοσελίδα μας <a href='https://app.uphellas.gr'>app.uphellas.gr</a><br />
                    Μπορείτε να βρείτε αυτή τη λειτουργία <a href='/manage'>εδώ</a>.
                </F>
            },
            {
                q: 'Ποια είναι η διαφορά του να ακυρώσω την κάρτα μου από το να την κλειδώσω;',
                a: <F>
                    Το κλείδωμα της κάρτας σας επιτρέπει την προσωρινή απενεργοποίηση όλων των συναλλαγών.
                    Μπορείτε να ξεκλειδώσετε την κάρτα σας μόλις πατήσετε ξανά το κουμπί κλειδώματος/ξεκλειδώματος.
                    Μαζί θα ενεργοποιηθούν και όλες οι συναλλαγές. Αυτή η λειτουργία σας επιτρέπει να κλειδώσετε την κάρτα σας σε περίπτωση που την έχετε χάσει.<br /><br />
                    Το να ακυρώσετε την κάρτα σας είναι οριστικό. Θα πρέπει να χρησιμοποιήσετε αυτή την επιλογή αμέσως μόλις επιβεβαιώσετε ότι έχετε χάσει την κάρτα σας.
                    Αν ακυρωθεί η κάρτα σας δεν μπορεί να ξανά ενεργοποιηθεί. Θα πρέπει να επικοινωνήσετε με τον εργοδότη σας για την έκδοση νέας κάρτας.
                </F>
            },
            {
                q: 'Τι συμβαίνει αφού ακυρώσετε την κάρτα σας;',
                a: <F>
                    Θα πρέπει να ενημερώσετε σχετικά με αυτό τον εργοδότη σας. Μία νέα κάρτα θα εκδοθεί και το υπόλοιπο της χαμένης κάρτας σας θα μεταφερθεί σε αυτή.<br />
                    Η νέα κάρτα θα εμφανιστεί αυτόματα στην εφαρμογή, αντικαθιστώντας την παλιά.
                </F>
            },
            {
                q: 'Μπορώ να πραγματοποιήσω ανέπαφες συναλλαγές;',
                a: <F>
                    Όλες οι κάρτες  go for EΑΤ παρέχουν τη δυνατότητα ανέπαφων συναλλαγών. Απλά πλησιάστε την κάρτα σας στο POS μηχάνημα και πληρώστε.
                </F>
            },
            {
                q: 'Πού μπορώ να χρησιμοποιήσω την κάρτα μου;',
                a: <F>
                    Η κάρτα σας μπορεί να χρησιμοποιηθεί για πληρωμές γευμάτων σε εστιατόρια, ταβέρνες, fast food εστιατόρια, καφετέριες και για αγορά τροφίμων στα supermarkets,
                    με την προϋπόθεση ότι δέχονται MasterCard.<br /><br />
                    Ανακαλύψτε τις συνεργαζόμενες με εμάς επιχειρήσεις μας <a href='/affiliates'>εδώ</a>.
                </F>
            },
            {
                q: 'Μπορώ να χρησιμοποιήσω την κάρτα μου online;',
                a: <F>
                    Ναι! Παρόλο που υπάρχουν λίγα εστιατόρια / supermarkets στην Ελλάδα που δέχονται ηλεκτρονική πληρωμή, η δυνατότητα ηλεκτρονικής πληρωμής είναι ενεργοποιημένη στην κάρτα σας.<br /><br />
                    Μη διστάσετε να τοποθετήσετε τον αριθμό της κάρτας σας, πληκτρολογήστε τον κωδικό CVV και ξεκινήστε να παραγγέλνετε και να πληρώνετε το γεύμα σας!
                </F>
            },
            {
                q: 'Πρέπει να υπογράψω την απόδειξη;',
                a: <F>
                    Ναι, είναι πιθανό να σας ζητήσουν να υπογράψετε την απόδειξη, επομένως μην ξαφνιαστείτε αν συμβεί αυτό.
                </F>
            },
            {
                q: 'Γιατί μία συναλλαγή που έκανα εμφανίζεται δύο φορές;',
                a: <F>
                    Μην ανησυχείτε, αυτό μπορεί να συμβεί (αν και είναι σπάνιο).<br />
                    Τα συστήματά μας είναι ικανά να το εντοπίσουν, και στην περίπτωση αυτή θα σας επιστραφεί το επιπλέον ποσό που αφαιρέθηκε.
                    Ωστόσο, αν μετά από 2 μέρες δεν έχει επιστραφεί το πόσο, παρακαλούμε επικοινωνήστε μαζί μας.
                </F>
            },
            {
                q: 'Πώς μπορώ να επικοινωνήσω με την Up Hellas;',
                a: <F>
                    Μπορείτε να επικοινωνήσετε μαζί μας: <br />
                    Μέσω της εφαρμογής: Up Hellas<br />
                    Μέσω της ιστοσελίδας: <a href="https://www.uphellas.gr">www.uphellas.gr</a><br />
                    Μέσω τηλεφώνου: 210 321 1022<br /><br />

                    Ή πατώντας <a href='/contact'>εδώ</a>!
                </F>
            },
        ],
    terms_content: {
        PRIVACY_POLICY: <F>
            <p style={{ textDecoration: 'underline', textAlign: 'center' }}><strong>ΕΠΕΞΕΡΓΑΣΙΑ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ & ΔΗΛΩΣΗ ΕΜΠΙΣΤΕΥΤΙΚΟΤΗΤΑΣ</strong></p><br />
            <p>
                Η παρούσα Δήλωση Εμπιστευτικότητας για την επεξεργασία προσωπικών δεδομένων εφαρμόζεται σε όλες σας τις συνδιαλλαγές,
                με την ιδιότητά σας ως χρήστης, στην εφαρμογή μας Up Hellas, όπως περιγράφεται στο παρόν έγγραφο.
                Η εφαρμογή προορίζεται προς χρήση από κατόχους τουλάχιστον ενός από τα προϊόντα της Up Hellas, σε ηλεκτρονική μορφή.
            </p>
            <p>
                Τα προσωπικά σας δεδομένα αποτελούν αντικείμενο επεξεργασίας από την UP HELLAS S.A, ελληνική εταιρεία με έδρα επί της
                οδού Μητροπόλεως 3, 10557, Αθήνα, εγγεγραμμένη στο Εμπορικό Μητρώο υπ’ αριθμόν 13666600100, αριθμός φορολογικού μητρώου
                800691552 (εφεξής καλούμενη «η Εταιρεία» ή «UP HELLAS»).
            </p>
            <p>
                Η ασφάλεια και εμπιστευτικότητα των προσωπικών σας δεδομένων είναι υψίστης σημασίας για εμάς, την UP HELLAS.
                Επομένως, λαμβάνουμε όλα τα απαραίτητα και εύλογα μέτρα προκειμένου να διασφαλίσουμε ότι τα προσωπικά σας δεδομένα
                παραμένουν ασφαλή και εμπιστευτικά και ότι η επεξεργασία τους ολοκληρώνεται σύμφωνα με τις ισχύουσες νομοθετικές διατάξεις,
                αποκλειστικά για τους σκοπούς που αναφέρονται παρακάτω.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Α. Επεξεργασία Προσωπικών Δεδομένων</strong></p><br />
            <p>
                Σημειώστε ότι επεξεργαζόμαστε τα ακόλουθα δεδομένα ως μέρος των συνδιαλλαγών σας με την εφαρμογή:
            </p>
            <ol>
                <li>στοιχεία ταυτοποίησης (πλήρες όνομα όπως αναγράφεται στην κάρτα),</li>
                <li>στοιχεία επικοινωνίας (διεύθυνση ηλεκτ. ταχ.),</li>
                <li>αριθμός κάρτας (ενός εκ των προϊόντων ηλεκτρονικής μορφής της UP HELLAS που χρησιμοποιείτε),</li>
                <li>στοιχεία τοποθεσίας,</li>
                <li>δεδομένα για τις συναλλαγές που πραγματοποιήθηκαν (ποσά, πωλητές) και διαθέσιμο υπόλοιπο.</li>
            </ol>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Β. Συλλογή Προσωπικών Δεδομένων</strong></p><br />
            <p>
                Συλλέγουμε τα προσωπικά σας δεδομένα άμεσα από εσάς, είτε μέσω της εφαρμογής ή με την ιδιότητα της Εταιρείας
                ως εγκεκριμένος εκδότης διατακτικών- ως μέρος των διαδικασιών έκδοσης, διαχείρισης και αποζημίωσης της αξίας των διατακτικών.
                Στην τελευταία περίπτωση, σας υπενθυμίζουμε ότι το όνομα και επώνυμο σας μεταβιβάζονται σε εμάς από τον εργοδότη σας,
                για τον σκοπό της έκδοσης των προπληρωμένων καρτών για εσάς, σύμφωνα με τις ισχύουσες νομοθετικές διατάξεις.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Γ. Οι Σκοποί και οι Νομικοί Λόγοι της Επεξεργασίας</strong></p><br />
            <span style={{ textDecoration: 'underline' }}>Δημιουργία Λογαριασμού Χρήστη και Διαχείριση</span><br /><br />
            <p>
                Προκειμένου να μπορέσετε να δημιουργήσετε έναν λογαριασμό χρήστη στην εφαρμογή, επεξεργαζόμαστε τη διεύθυνσή του ηλεκτρονικού
                ταχυδρομείου σας, το κύριο όνομα και επώνυμο σας, την ερώτηση ασφαλείας σας και τον κωδικό που ορίζετε, ως μέρος μιας σύναψης
                συμφωνίας με εμάς. Σε αυτό το πλαίσιο, η συμφωνία με του όρους και προϋποθέσεις σημαίνει ότι έχετε επίσης αποδεχτεί τις
                προϋποθέσεις που ρυθμίζουν τη χρήση της εφαρμογής.
            </p>
            <p>
                Η δημιουργία λογαριασμού σημαίνει επίσης ότι πρέπει να πληκτρολογήσετε τον αριθμό της κάρτας σας (ενός από τα προϊόντα UP HELLAS του
                οποίου είστε κάτοχος). Αυτό νοείται, στην πραγματικότητα, ως μέτρο ασφαλείας για τη διασφάλιση ότι είστε το μόνο άτομο που έχει
                πρόσβαση στην εφαρμογή. Αυτά τα δεδομένα αποτελούν ήδη αντικείμενο επεξεργασίας από εμάς, δεδομένου ότι αποτελεί το νομικό καθήκον
                της Εταιρείας, λόγω του βασικού της τομέα επιχειρήσεων και αντικειμένου δραστηριοτήτων και σύμφωνα με την ειδική νομοθεσία που
                ισχύει στην περίπτωση έκδοσης κουπονιών αξίας, σε έντυπη μορφή και σε αντίστοιχη ηλεκτρονική μορφή.
            </p>
            <p>
                Από την ενότητα που αφορά τον λογαριασμό χρήστη σας, μπορείτε επίσης να αλλάξετε τα στοιχεία επικοινωνίας σας. Σε αυτή την περίπτωση
                τα νέα στοιχεία επικοινωνίας θα αντικαταστήσουν τις πληροφορίες που έχετε παράσχει προηγουμένως (κατά περίπτωση)
                - π.χ. συμπληρώνοντας ή αποστέλλοντας ειδοποιήσεις συναλλαγών και επιπλέον αποκτώντας τον αριθμό ΡΙΝ της κάρτας σας όταν ο
                εργοδότης σας έχει συνάψει σύμβαση για τις εν λόγω επιπλέον υπηρεσίες). Τα στοιχεία επικοινωνίας σας αποτελούν αντικείμενο
                επεξεργασίας προκειμένου να μας επιτρέψουν να σας αποστέλλουμε μηνύματα ηλεκτρονικού ταχυδρομείου σχετικά με τη δημιουργία του
                λογαριασμού σας και για την αποστολή ειδοποιήσεων σχετικά με τη συμπλήρωση των καρτών σας και τις συναλλαγές που πραγματοποιήθηκαν
                (δεδομένου ότι ο Εργοδότης σας έχει αιτηθεί την παροχή αυτής της επιπρόσθετης υπηρεσίας).
            </p>
            <p>
                Όταν δημιουργήσετε έναν λογαριασμό χρήστη μέσω της εφαρμογής Up Hellas, ένας τέτοιος λογαριασμός μπορεί να είναι προσβάσιμος
                είτε από την εφαρμογή Up Hellas ή τη διαδικτυακή πλατφόρμα Up (https://app.uphellas.gr/). Η έκφραση των προτιμήσεών σας σχετικά
                με τις ειδοποιήσεις που αποστέλλονται από την Up Hellas μπορεί να πραγματοποιηθεί με τη χρήση οποιουδήποτε από τους δύο τρόπους
                και η διαγραφή του λογαριασμού θα συνεπάγεται την αδυναμία πρόσβασης στον λογαριασμό σας είτε μέσω της εφαρμογής Up Hellas ή της
                διαδικτυακής πλατφόρμας Up.
            </p>
            <p>
                Σε κάθε περίπτωση, η απεγκατάσταση της εφαρμογής για κινητό δεν θα έχει κανένα αποτέλεσμα στην ύπαρξη του λογαριασμού χρήστη σας
                και των προτιμήσεών σας, όπως εκφράζονται από εσάς. Μπορείτε επίσης να έχετε πρόσβαση στον λογαριασμό σας ανά πάσα στιγμή
                χρησιμοποιώντας τη διαδικτυακή πλατφόρμα ή εγκαθιστώντας εκ νέου την εφαρμογή.
            </p>
            <span style={{ textDecoration: 'underline' }}>Δεδομένα για τις συναλλαγές που πραγματοποιήθηκαν και διαθέσιμο υπόλοιπο</span><br /><br />
            <p>
                Με την ιδιότητά μας ως εκδότης καρτών, έχουμε πρόσβαση στις συναλλαγές που πραγματοποιήθηκαν, τις οποία επεξεργαζόμαστε βάσει
                των απαιτήσεων που μας επιβάλλονται από την ισχύουσα νομοθεσία (δηλ. τις νομικές υποχρεώσεις), προκειμένου να πραγματοποιήσουμε
                τον ολοκληρωμένο κύκλο των κουπονιών αξίας, από την έκδοση έως την αποζημίωση. Αυτά τα δεδομένα τίθενται στη διάθεσή σας μέσω
                της εφαρμογής.
            </p>
            <span style={{ textDecoration: 'underline' }}>Ειδοποιήσεις Push </span><br /><br />
            <p>
                Η εφαρμογή παράγει ειδοποιήσεις push σχετικά με τις συναλλαγές σας, βάσει του έννομου συμφέροντός μας να σας κρατάμε ενημερωμένους
                σχετικά με τις εγκεκριμένες συναλλαγές και το διαθέσιμο υπόλοιπο στις κάρτες σας, προκειμένου να βελτιώνουμε την εμπειρία χρήστη.
                Επίσης, μέσω ειδοποιήσεων Push (Push Notifications), η Up Hellas μπορεί να αποστέλλει σε εσάς πληροφορίες σχετικά με τροποποιήσεις
                στους όρους και τις προϋποθέσεις της παρούσας Δήλωσης Εμπιστευτικότητας ή πληροφορίες σχετικά με τεχνικά ζητήματα της εφαρμογής
                ή των συστημάτων μας, βάσει του έννομου συμφέροντός μας να σας κρατάμε ενημερωμένους και να τηρούμε καλύτερα τα δικαιώματά σας
                όπως προβλέπεται από τον νόμο.
            </p>
            <p>
                Έχετε την επιλογή να απενεργοποιείτε τις ειδοποιήσεις push από τη συσκευή σας, πηγαίνοντας στην ενότητα που αφορά τις άδειες που
                χορηγούνται στην εφαρμογή.
            </p>
            <span style={{ textDecoration: 'underline' }}>Εμπορικές και Διαφημιστικές Επικοινωνίες </span><br /><br />
            <p>
                Επιπρόσθετα, επεξεργαζόμαστε τα στοιχεία επικοινωνίας σας προκειμένου να αποστέλλουμε εμπορικές και διαφημιστικές επικοινωνίες,
                εφόσον έχετε δώσει τη συγκατάθεσή σας, είτε κατά την εγκατάσταση της εφαρμογής είτε, δευτερευόντως, πηγαίνοντας στις
                Ρυθμίσεις →  Διαφήμιση στην εφαρμογή. Μπορείτε να επιλέξετε να λαμβάνετε τέτοιες ειδοποιήσεις σε οποιοδήποτε από τα τρία διαθέσιμα
                κανάλια: ειδοποιήσεις push, μήνυμα sms ή μήνυμα ηλεκτρονικού ταχυδρομείου (e-mail). Έχετε το δικαίωμα να αποσύρετε τη συγκατάθεσή
                σας είτε μέσω της πρόσβασης στην ίδια ενότητα της εφαρμογής, είτε μέσω της αποστολής αιτήματος χρησιμοποιώντας τα στοιχεία
                επικοινωνίας παρακάτω. Επιπλέον, όλα τα μηνύματα που λαμβάνονται μέσω μηνύματος ηλεκτρονικού ταχυδρομείου περιλαμβάνουν έναν
                σύντομο σύνδεσμο που μπορείτε να χρησιμοποιείτε για να διαγραφείτε.
            </p>
            <span style={{ textDecoration: 'underline' }}>Ψηφοφορίες</span><br /><br />
            <p>
                Ενδέχεται περιστασιακά να κληθείτε να λάβετε μέρος σε διάφορες ψηφοφορίες, μέσω ειδοποιήσεων push που παράγονται από την εφαρμογή,
                οι οποίες ωθούνται βάσει του έννομου συμφέροντός μας να εξακριβώσουμε πόσο ικανοποιημένοι είστε με τις υπηρεσίες που παρέχουμε.
                Η συμμετοχή στις ψηφοφορίες διεξάγεται χωρίς χρέωση, εθελοντικά και ανώνυμα.
            </p>
            <span style={{ textDecoration: 'underline' }}>Στοιχεία τοποθεσίας</span><br /><br />
            <p>
                Η εφαρμογή μπορεί να έχει πρόσβαση στην τοποθεσία σας, αλλά μόνο εφόσον έχετε δώσει τη συγκατάθεσή σας, μέσω των κατάλληλων
                ρυθμίσεων στη συσκευή σας. Τα στοιχεία τοποθεσίας δεν αποθηκεύονται ούτε στην εφαρμογή ούτε στους διακομιστές της Up.
                Αυτά τα δεδομένα χρησιμοποιούνται μόνο για να μπορείτε να επιλέξετε συνδεδεμένες με εμάς επιχειρήσεις ή να επιλέξετε μεταξύ
                προσφορών που μπορεί να είναι διαθέσιμες, να εμφανίσετε αποτελέσματα που είναι πιο σχετικά με εσάς, για τον σκοπό της παροχής
                μιας εμπειρίας χρήστη στην εφαρμογή, όσο το δυνατόν πιο ικανοποιητική και εύκολη.
            </p>
            <p>
                Μπορείτε να επιλέξετε ανά πάσα στιγμή να αποσύρετε την άδεια που χορηγείται στην εφαρμογή μας να χρησιμοποιεί τα δεδομένα
                τοποθεσίας σας. Οι επιλογές που αποτελούν αντικείμενο διαχείρισης που προηγείται μεταξύ των συνδεδεμένων με εμάς εταιρειών,
                καθώς και οι διαθέσιμες προσφορές τους, δεν θα είναι μετέπειτα δυνατές.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Δ. Διάρκεια Τήρησης</strong></p><br />
            <p>
                Ο λογαριασμός χρήστη είναι έγκυρος για όσο χρησιμοποιείτε την εφαρμογή. Σε περίπτωση που αντιληφθούμε ότι ο λογαριασμός σας είναι
                ανενεργός για τουλάχιστον 12 συνεχόμενους μήνες, θα απενεργοποιείται. Επιπλέον, μπορείτε να διαγράφετε τον λογαριασμό χρήστη ανά
                πάσα στιγμή, χρησιμοποιώντας την εφαρμογή. Η διαγραφή του λογαριασμού χρήστη συνεπάγεται την αδυναμία πρόσβασης σε αυτόν είτε
                μέσω της εφαρμογής για το κινητό είτε μέσω της διαδικτυακής πλατφόρμας.
            </p>
            <p>
                Ωστόσο, η διαγραφή του λογαριασμού σας δεν επιφέρει συνέπειες σχετικά με την επεξεργασία των δεδομένων σας που είναι απαραίτητα για
                την έκδοση κουπονιών αξίας για εσάς ή δεδομένα που μπορεί να απαιτούνται για την παροχή επιπρόσθετων υπηρεσιών ειδοποιήσεων, σύμφωνα
                με την επιλογή του Εργοδότη σας. Σε διαφορετική περίπτωση, η διαγραφή του λογαριασμού σας οδηγεί σε διαγραφή των δεδομένων της
                ηλεκτρονικής καταγραφής σας από τη βάση δεδομένων μας και σε διαγραφή σας από όλες τις εμπορικές επικοινωνίες που αποστέλλονται
                από την Up Hellas σε περίπτωση που έχετε επιλέξει προηγουμένως να λαμβάνετε τις εν λόγω επικοινωνίες.
            </p>
            <p>
                Για διαφημιστικούς σκοπούς, επεξεργαζόμαστε τα στοιχεία σας επικοινωνίας για μια περίοδο δύο ετών ή, σε περίπτωση που δεν συμφωνείτε
                πλέον να λαμβάνετε εμπορικά μηνύματα, μέχρι την απόσυρση της συγκατάθεσης που δόθηκε προηγουμένως. Μετά τη λήξη της περιόδου των δύο
                ετών, ενδέχεται να σας ζητήσουμε να ανανεώσετε τη συγκατάθεσή σας, προκειμένου να μπορούμε να σας αποστέλλουμε τέτοιου είδους
                μηνύματα.
            </p>
            <p>
                Τα δεδομένα τοποθεσίας που χρησιμοποιούνται από την εφαρμογή για το φιλτράρισμα αποτελεσμάτων σύμφωνα με τη συνάφεια των συνεργατών
                 μας και των προσφορών τους για εσάς δεν αποθηκεύονται καθόλου.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Ε. Τρίτα μέρη</strong></p><br />
            <p>
                Η Εταιρεία δεν μεταφέρει επί του παρόντος κανένα από τα προσωπικά σας δεδομένα σε νομικές οντότητες ή πρόσωπα εκτός εταιρείας.
                Σε τέτοια περίπτωση, ενημερώνεστε σχετικά με οποιαδήποτε μεταβίβαση προσωπικών δεδομένων, εκτός και αν μια τέτοια μεταβίβαση
                ή αποκάλυψη προβλέπονται ρητώς από τη νομοθεσία της Ευρωπαϊκής Ένωσης ή από την εθνική νομοθεσία και απορρέουν από το καθήκον
                μας για συμμόρφωση με ισχύουσες νομοθετικές διατάξεις.
            </p>
            <p>
                Εντούτοις, ο προγραμματιστής της εφαρμογής για το κινητό μπορεί να έχει πρόσβαση στα δεδομένα του λογαριασμού σας για λόγους
                συντήρησης - με αυτόν τον πάροχο υπηρεσιών που ενεργεί ως ο μεταποιητής της Up Hellas έχουμε συνάψει μια συμφωνία επεξεργασίας
                δεδομένων, διασφαλίζοντας την εμπιστευτικότητα και την ασφάλεια των προσωπικών σας δεδομένων.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>ΣΤ. Ασφάλεια Προσωπικών Δεδομένων</strong></p><br />
            <p>
                Η ασφάλεια, ακεραιότητα και εμπιστευτικότητα των προσωπικών σας δεδομένων είναι υψίστης σημασίας για εμάς. Η Εταιρεία, επομένως,
                λαμβάνει όλα τα οργανωτικά και τεχνικά μέτρα για τον σκοπό αυτό.
            </p>
            <p>
                Σε περίπτωση που ανακαλύψουμε οποιαδήποτε παραβίαση της ασφάλειας των προσωπικών δεδομένων που μπορεί να απορρέει σε κίνδυνο για
                τα δικαιώματα και ελευθερίες σας, ειδοποιούμε την Εθνική Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα («ΑΠΔΠΧ») εντός 72 ωρών. Μπορείτε επίσης να ενημερωθείτε για το σχετικό συμβάν ασφαλείας, σε περίπτωση που μπορεί να έχει ως αποτέλεσμα υψηλό κίνδυνο για τα δικαιώματα και τις ελευθερίες σας.</p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Ζ. Τα δικαιώματά σας</strong></p><br />
            <p>
                Έχετε τα ακόλουθα δικαιώματα σχετικά με την επεξεργασία των προσωπικών σας δεδομένων:
            </p>
            <ol>
                <li><strong>Δικαίωμα πρόσβασης σε ίδια προσωπικά δεδομένα.</strong> Έχετε το δικαίωμα αίτησης πρόσβασης στα προσωπικά δεδομένα που αποτελούν αντικείμενο επεξεργασίας. </li>
                <li><strong>Δικαίωμα διόρθωσης ή διαγραφής προσωπικών δεδομένων.</strong> Έχετε το δικαιώματα να αιτηθείτε διόρθωση μη ορθών και μη ενημερωμένων προσωπικών δεδομένων ή διαγραφή προσωπικών δεδομένων, παραδείγματος χάριν στην περίπτωση που η επεξεργασία δεν είναι απαραίτητη ή νόμιμη ή στην περίπτωση απόσυρσης της συγκατάθεσής σας για την επεξεργασίας ορισμένων δεδομένων, συγκατάθεση που έχετε προηγουμένως παρέχει. Τα προσωπικά Δεδομένα που τηρούνται σύμφωνα με τις ισχύουσες νομικές διατάξεις δεν μπορούν να διαγραφούν. </li>
                <li><strong>Δικαίωμα περιορισμού επεξεργασίας.</strong> Υπό ορισμένες προϋποθέσεις που ορίζονται στην ισχύουσα νομοθεσία, έχετε το δικαίωμα να εξασφαλίσετε τον περιορισμό της επεξεργασίας των προσωπικών σας δεδομένων.</li>
                <li><strong>Δικαίωμα στη φορητότητα των δεδομένων.</strong> Έχετε το δικαιώματα είτε να αιτηθείτε τα αντίγραφα των προσωπικών σας δεδομένων που η εταιρεία επεξεργάζεται να προωθούνται είτε σε εσάς είτε σε ελεγκτές άλλων δεδομένων, σε περίπτωση που η εν λόγω επεξεργασία βασίζεται στη συγκατάθεσή σας ή σε εκτέλεση οποιαδήποτε συμφωνίας που μπορεί να έχετε συνάψει με την Εταιρεία.</li>
                <li><strong>Δικαίωμα εναντιώσεως.</strong> Έχετε το δικαίωμα να εναντιωθείτε ανά πάσα στιγμή, για λόγους που σχετίζονται με την συγκεκριμένη κατάστασή σας, στην επεξεργασία των προσωπικών σας δεδομένων, όποτε η εν λόγω επεξεργασία βασίζεται στα έννομά σας συμφέροντα και μπορείτε να αιτηθείτε την παύση επεξεργασίας των προσωπικών σας δεδομένων.</li>
            </ol>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Η. Δικαίωμα άσκησης των Δικαιωμάτων σας</strong></p><br />
            <p>
                Σε περίπτωση που έχετε οποιεσδήποτε ερωτήσεις σχετικά με την επεξεργασία των προσωπικών σας δεδομένων ή σε περίπτωση που έχετε
                οποιαδήποτε αιτήματα ή επιθυμείτε να ασκήσετε οποιαδήποτε από τα δικαιώματά σας σχετίζονται με την επεξεργασία των προσωπικών
                σας δεδομένων, επικοινωνήστε με τον Υπεύθυνο Προστασίας Προσωπικών Δεδομένων στην ακόλουθη δ/νση ηλεκτρονικού ταχυδρομείου:
                gdpr@uphellas.gr. Κάθε αίτημα αναλύεται το συντομότερο δυνατό και μετά το πέρας του ενός μήνα.
            </p>
            <p>
                Σε περίπτωση που θεωρείται ότι δεν έχουμε ικανοποιήσει όλα σας τα αιτήματα ή δεν είστε ικανοποιημένοι με τις απαντήσεις μας,
                μπορείτε να προβείτε σε καταγγελία στην ΑΠΔΠΧ. Επιπλέον, μπορείτε να θέσετε το ζήτημα ενώπιον των αρμόδιων δικαστηρίων.
            </p>
            <p>
                Οποιαδήποτε αλλαγή των πληροφοριών που περιλαμβάνονται στην Παρούσα Δήλωση Εμπιστευτικότητας περιέρχονται άμεσα σε γνώση σας -
                καμία τροποποίηση της Δήλωσης Εμπιστευτικότητας δεν επηρεάζει με κανέναν τρόπο τα δικαιώματα που ορίζονται από τον νόμο.
            </p>
            <br />
            <p>[Έκδοση από Φεβρουάριο 2020]</p>
        </F>,
        TERMS_APP: <F>
            <p style={{ textDecoration: 'underline', textAlign: 'center' }}><strong>ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ ΤΗΣ ΚΙΝΗΤΗΣ ΚΑΡΤΑΣ UP HELLAS</strong></p><br />
            <p>
                Οι παρόντες Όροι και Προϋποθέσεις Χρήσης (εφεξής "Όροι και Προϋποθέσεις") καθορίζουν τα δικαιώματα και τις υποχρεώσεις των προσώπων που
                συνδέονται με την εφαρμογή Up Hellas App (εφεξής "Χρήστες").<br />
                Η Up Hellas App διατίθεται σε σας από την «UP HELLAS ΑΝΩΝΥΜΗ ΕΜΠΟΡΙΚΗ ΕΤΑΙΡΕΙΑ», με έδρα την Αθήνα (Μητροπόλεως 3), με ΑΦΜ 800691552 της ΔΟΥ ΦΑΕ Αθηνών (εφεξής «UP HELLAS»).<br />
                Χρησιμοποιώντας την Up Hellas App ο χρήστης δέχεται και δεσμεύεται να συμμορφώνεται με όλους τους όρους χρήσης της Εφαρμογής και των τροποποιήσεών τους.<br />
                Επιπλέον, χρησιμοποιώντας την Εφαρμογή, συναινεί στην επεξεργασία, σύμφωνα με το νόμο περί προσωπικών δεδομένων, των δεδομένων που κοινοποιεί προς την Up Hellas σε σχέση με τη χρήση της Κάρτας
                και για την εκπλήρωση οποιασδήποτε συμβατικής και νομικής υποχρέωση της Up Hellas σε οποιαδήποτε δημόσια και φορολογική αρχή και τρίτα πρόσωπα.<br />
                Η UP HELLAS διατηρεί το δικαίωμα να τροποποιήσει τους παρόντες Όρους Χρήσης οποτεδήποτε και θα ενημερωθείτε για την αλλαγή κατά την πρόσβαση στην εφαρμογή.
            </p>
            <ol>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Πρόσβαση στην Εφαρμογή Κινητής Τηλεφωνίας</span><br/><br/>
                    <p>Η UP Hellas App σας επιτρέπει:</p>
                    <ol>
                        <li>
                            Να δείτε την ονομασία και τη διεύθυνση των επιχειρήσεων που δέχονται την Κάρτα της UP HELLAS ως μέσο πληρωμής για υπηρεσίες και αγαθά. Οι επιχειρήσεις που
                            δέχονται τις Κάρτες, ανεξαρτήτως τύπου, καλούνται στο εξής "Συνεργάτες".
                        </li>
                        <li>
                            Να διαχειρίζεστε το λογαριασμό σας που σχετίζεται με τις κάρτες που εκδίδονται από την UP HELLAS, να ελέγχετε το υπόλοιπο και τις συναλλαγές σας με τις κάρτες,
                            να ενεργοποιείτε/μπλοκάρετε τις κάρτες, να λαμβάνετε ειδοποιήσεις σχετικά με τις συναλλαγές που έγιναν με την κάρτα, το εναπομείναν ποσό κλπ.
                        </li>
                    </ol>
                    <p>
                        Στις υπηρεσίες της Up Hellas App μπορούν να έχουν πρόσβαση μόνο οι χρήστες που έχουν λάβει κάρτες από τους εργοδότες τους, οι οποίοι πρέπει να πιστοποιήσουν την ταυτότητά τους
                        στην Up Hellas App. Η Up Hellas App εγκαθίσταται δωρεάν μέσω του App Store και του Google Play και μπορεί να χρησιμοποιηθεί και μέσω της ιστοσελίδας της UP HELLAS (<a href="https://app.uphellas.gr">app.uphellas.gr</a>).
                        Ως εκ τούτου, εάν έχετε λάβει από τον εργοδότη σας και είστε κάτοχος κάρτας που έχει εκδοθεί από την UP HELLAS, μπορείτε να συνδεθείτε εισάγοντας το όνομα και την επώνυμό σας, τον αριθμό
                        της κάρτας σας και την ηλεκτρονική σας διεύθυνση και ένα κωδικό πρόσβασης. Η αλλαγή του κωδικού πρόσβασης (συμπεριλαμβανομένων των αλλαγών κωδικών που έχουν ξεχασθεί) πραγματοποιείται
                        ακολουθώντας τα ειδικότερα βήματα που καθορίζονται στην Up Hellas App, με το νέο κωδικό πρόσβασης να εισάγεται/παραλαμβάνεται εντός της εφαρμογής.
                        Πρέπει να διατηρείτε την εμπιστευτικότητα των στοιχείων πρόσβασης στην Up Hellas App. Ως εκ τούτου, όλες οι συνδέσεις μέσα στην Up Hellas App υποτίθεται ότι προέρχονται από εσάς και θα
                        είστε αποκλειστικά υπεύθυνοι για τις συνέπειες που ενδέχεται να προκύψουν από μη εξουσιοδοτημένη πρόσβαση τρίτου στο λογαριασμό σας.
                    </p>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Πνευματική ιδιοκτησία</span><br/><br/>
                    <ol>
                        <li>
                            Η Up Hellas App, ο σχεδιασμός και το περιεχόμενό της, συμπεριλαμβανομένων όλων των στοιχείων της (κείμενα, εικόνες, εμπορικά σήματα, λογότυπα, λογισμικό, βάσεις δεδομένων)
                            ανήκουν στην UP HELLAS, στις εταιρείες που ανήκουν στον ίδιο όμιλο με την UP HELLAS ή στους εταίρους της και υπόκεινται στη νομοθεσία περί προστασίας δικαιωμάτων
                            πνευματικής ιδιοκτησίας.
                        </li>
                        <li>
                            Οποιαδήποτε χρήση εκτός της περιγραφόμενης στους παρόντες Όρους και Προϋποθέσεις Χρήσης και οποιαδήποτε τροποποίηση, αναπαραγωγή, μετάδοση ή διανομή της Up Hellas App ή/και
                            των εξαρτημάτων της απαγορεύεται και μπορεί να συνιστά παραβίαση των εφαρμοστέων νομικών διατάξεων, χωρίς προηγούμενη γραπτή εξουσιοδότηση της UP HELLAS.
                        </li>
                        <li>
                            Στην UP HELLAS ανήκουν επίσης όλες οι βάσεις δεδομένων που περιλαμβάνονται στην Up Hellas App. Αυτά τα δεδομένα προστατεύονται νομικά και απαγορεύεται η εξαγωγή,
                            η επαναχρησιμοποίηση, η αποθήκευση, η αναπαραγωγή, η αναπαράσταση ή η διατήρηση, άμεσα ή έμμεσα, σε οποιοδήποτε μέσο με οποιονδήποτε τρόπο και σε οποιαδήποτε μορφή,
                            οποιουδήποτε τμήματος των βάσεων δεδομένων που εμφανίζονται στην Εφαρμογή.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Eυθύνη</span><br/><br/>
                    <ol>
                        <li>
                            Η χρήση της Up Hellas App είναι δωρεάν.
                        </li>
                        <li>
                            Η UP HELLAS καταβάλλει κάθε προσπάθεια για να καταστήσει την Up Hellas App διαθέσιμη σε μόνιμη βάση, 24 ώρες την ημέρα, 7 ημέρες την εβδομάδα, εκτός 
                            από περιπτώσεις ανωτέρας βίας ή γεγονότος εκτός του ελέγχου της και υπόκειται σε τυχόν δυσλειτουργίες και συντήρηση για την ορθή λειτουργία την εφαρμογή 
                            κινητής τηλεφωνίας. Για το λόγο αυτό, η UP HELLAS έχει το δικαίωμα να διακόψει την πρόσβαση ή/και τη χρήση της Up Hellas App ή οποιουδήποτε τμήματος των 
                            υπηρεσιών της οποτεδήποτε χωρίς προηγούμενη ειδοποίηση και χωρίς καμία ευθύνη απέναντι σε εσάς και ιδιαίτερα για σκοπούς ασφάλειας ή συντήρησης που 
                            απαιτούνται για τη σωστή λειτουργία της Up Hellas App.
                            Θα ειδοποιηθείτε για αυτήν την αναστολή της πρόσβασης ή/και της χρήσης μέσω της Εφαρμογής και η επίδρασή της μπορεί να είναι άμεση. Θα ενημερωθείτε 
                            σχετικά με τη διάρκεια της αναστολής και θα σας ζητηθεί να επικοινωνήσετε σύντομα με την UP HELLAS. Η UP HELLAS διατηρεί το δικαίωμα, αν είναι απαραίτητο, 
                            να διερευνήσει αμέσως οποιαδήποτε χρήση της Up Hellas App, που τυχόν δημιουργεί υποψίες για κατάχρηση εκτός των κανόνων και των ορίων που καθορίζονται στο παρόν.
                        </li>
                        <li>
                            Η UP HELLAS καταβάλλει κάθε δυνατή προσπάθεια για να διασφαλίσει ότι οι πληροφορίες που δημοσιεύονται στην Up Hellas App είναι ακριβείς και ενημερωμένες.
                            Η UP HELLAS διατηρεί το δικαίωμα να διορθώνει ή να τροποποιεί την Εφαρμογή και τις πληροφορίες που περιέχει οποιαδήποτε στιγμή και χωρίς προειδοποίηση.
                            Η UP HELLAS δεν ευθύνεται για καμία από τις παρακάτω περιπτώσεις (ο κατάλογος αυτός δεν είναι περιοριστικός):
                            <ol style={{ listStyleType: 'lower-greek' }}>
                                <li>
                                    Χρήση της Up Hellas App, η οποία δεν συμμορφώνεται με τους Όρους Χρήσης (συμπεριλαμβανομένης της χρήσης που έχετε κάνει).
                                </li>
                                <li>
                                    Προβλήματα του Διαδικτύου και κάθε άλλου κινδύνου που συνδέεται με τη χρήση του Διαδικτύου (ιοί, πειρατεία ηλεκτρονικών υπολογιστών κ.λπ.).
                                </li>
                                <li>
                                    Σε περίπτωση απάτης ή παρόμοιων αδικημάτων ως συνέπεια της πράξης/παράλειψης τρίτου που απαιτεί τον τερματισμό/αναστολή του λογαριασμού
                                    (π.χ. ταυτόχρονη πρόσβαση σε πολλούς λογαριασμούς κλπ.).
                                </li>
                                <li>
                                    Σε περίπτωση παραβίασης της ασφάλειας του συστήματος UP HELLAS.
                                </li>
                                <li>
                                    Δυσλειτουργία ή ασυμβατότητα του εξοπλισμού που χρησιμοποιείται για την Εφαρμογή.
                                </li>
                                <li>
                                    Υπηρεσίες που παρέχονται από τρίτους, συμπεριλαμβανομένων των συνεργατών.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Προσωπικά δεδομένα</span><br/><br/>
                    <ol>
                        <li>
                            Η UP HELLAS δηλώνει ότι συμμορφώνεται με τον Γενικό Κανονισμό Προστασίας Δεδομένων (GDPR) και κάθε σχετική εθνική νομοθεσία που εφαρμόζεται στην επεξεργασία
                            προσωπικών δεδομένων και έχει την υποχρέωση να διαχειρίζεται τα προσωπικά σας δεδομένα με ασφάλεια και μόνο για τους σκοπούς που καθορίζονται στο παρόν κεφάλαιο.
                        </li>
                        <li>
                            Ορισμένα από τα δεδομένα που επεξεργάζονται στο πλαίσιο της Up Hellas App διαβιβάζονται στην UP HELLAS από τον εργοδότη σας για την έκδοση, χρήση, επεξεργασία
                            και διακανονισμό συναλλαγών μέσω ηλεκτρονικών καρτών, πράξεις που περιλαμβάνουν επίσης τη διαχείριση λογαριασμού που σχετίζεται με την κάρτα σας και ορισμένα
                            από τα δεδομένα που έχετε μεταβιβάσει απευθείας μέσω της εφαρμογής, όπως τα δεδομένα τοποθεσίας, εάν έχετε αποδεχθεί τη μεταβίβασή τους.
                        </li>
                        <li>
                            Τα προσωπικά σας δεδομένα, συμπεριλαμβανομένων των δεδομένων τοποθεσίας που υποβάλλονται σε επεξεργασία εντός και μέσω της Up Hellas App,
                            θα χρησιμοποιηθούν για τους ακόλουθους σκοπούς:
                            <ol style={{ listStyleType: 'lower-greek' }}>
                                <li>
                                    Διαχείριση του λογαριασμού που σχετίζεται με την κάρτα σας (έλεγχος του υπολοίπου και των συναλλαγών της κάρτας, εκτέλεση ενεργειών που σχετίζονται
                                    κατάστασης κάρτας: μπλοκάρισμα/ενεργοποίηση της κάρτας σας, αλλαγή του κωδικού πρόσβασης, λήψη ειδοποιήσεων συναλλαγών και εναπομείναν υπόλοιπο κ.λπ.).
                                </li>
                                <li>
                                    την υποβολή προσφορών και προωθητικών ενεργειών για τα προϊόντα και τις υπηρεσίες της UP HELLAS καθώς και των συνεργατών της UP HELLAS με την εμφάνιση
                                    τους στην Up Hellas App. Η επεξεργασία αυτή δεν θα πραγματοποιείται πλέον σε περιπτώσεις όπου δεν επιθυμείτε την επεξεργασία δεδομένων για εμπορικούς
                                    και διαφημιστικούς σκοπούς, σύμφωνα με τη σχετική διαδικασία για αυτές τις καταστάσεις.
                                </li>
                                <li>
                                    προβολή του Χάρτη των Συνεργατών της UP HELLAS.
                                </li>
                                <li>
                                    συγκέντρωση δεδομένων και στατιστικών, διαβίβαση ενημερωτικών δελτίων και νομοθετικές πληροφορίες.
                                    Η επεξεργασία δεδομένων πραγματοποιείται τόσο από την UP HELLAS όσο και από τους συμβατικούς εταίρους της εντός της Ευρωπαϊκής Ένωσης.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Σύνδεσμοι</span><br/><br/>
                    <ol>
                        <li>
                            Η Up Hellas App μπορεί να περιέχει συνδέσμους προς άλλες ιστοσελίδες του Διαδικτύου. Στις συνδέσεις με άλλες πηγές ενδέχεται να αποχωρήσετε
                            από την Εφαρμογή, οπότε η UP HELLAS δεν φέρει καμία ευθύνη για το περιεχόμενο και τη λειτουργία αυτών των ιστότοπων.
                        </li>
                        <li>
                            Η UP HELLAS δεν επιτρέπει τη δημιουργία υπερ-συνδέσμων στην Εφαρμογή χωρίς την προηγούμενη γραπτή συγκατάθεσή της.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Cookies</span><br/><br/>
                    <ol>
                        <li>
                            Η περιήγηση στην Up Hellas App ενδέχεται να προκαλέσει την εγκατάσταση cookies στο τηλέφωνό σας, προκειμένου να βελτιωθεί η λειτουργικότητα της Up Hellas App.
                        </li>
                        <li>
                            Τα cookies αποθηκεύονται μόνο κατά τη διάρκεια της λειτουργίας της Up Hellas App. Μπορείτε να ορίσετε το πρόγραμμα περιήγησης του τηλεφώνου σας να
                            εξαιρεθεί από την εγγραφή cookies, οπότε κάποιες υπηρεσίες κινητής εφαρμογής ενδέχεται να μην είναι διαθέσιμες ή να λειτουργούν ακατάλληλα.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Γενικά</span><br/><br/>
                    <ol>
                        <li>
                            Όλα τα δεδομένα που επεξεργάζονται μέσα στην Up Hellas App θα αποθηκεύονται στα συστήματα πληροφορικής της UP HELLAS και των εταιρειών του ίδιου ομίλου,
                            που βρίσκονται εντός της Ευρωπαϊκής Ένωσης υπό εύλογες συνθήκες ασφάλειας και θα θεωρούνται απόδειξη επικοινωνιών και λειτουργιών σας, και οποιωνδήποτε
                            άλλα αιτημάτων μεταξύ της UP HELLAS και εσάς.
                        </li>
                        <li>
                            Κάθε διαφορά σχετικά με την Up Hellas App υπόκειται στην ελληνική νομοθεσία και η διευθέτησή τους θα υπόκειται στην αποκλειστική αρμοδιότητα των δικαστηρίων
                            του κοινού δικαστηρίου της UP HELLAS.
                        </li>
                        <li>
                            Με την αποδοχή των Όρων και Προϋποθέσεων κατά την ταυτοποίηση στην Up Hellas App, συμφωνείτε με το περιεχόμενό τους συμπεριλαμβανομένης της επεξεργασίας
                            των προσωπικών δεδομένων για τους σκοπούς που αναφέρονται στο Κεφάλαιο 4 του παρόντος εγγράφου και συμφωνείτε ότι η συμφωνία αυτή έχει χαρακτήρα σύμβασης
                            μεταξύ υμών και της UP HELLAS. Θα ενημερώνεστε όποτε αλλάζουν οι Όροι Παροχής Υπηρεσιών και η χρήση της Up Hellas App μετά από ειδοποίηση ισοδυναμεί με
                            την αποδοχή τους. Αν δεν συμφωνείτε πλέον με τους Όρους και Προϋποθέσεις που περιγράφονται παραπάνω, δεν θα μπορείτε πλέον να χρησιμοποιείτε τις Υπηρεσίες 
                            που παρέχονται από την Up Hellas App.
                        </li>
                    </ol>
                </li>
            </ol>
        </F>,
        TERMS_CARD: <F>
            <p style={{ textDecoration: 'underline', textAlign: 'center' }}><strong>Γενικοί  Όροι και Προϋποθέσεις Χρήσης της Κάρτας “go for EAT” της UP Hellas</strong></p><br/>
            <p>
                Οι παρόντες όροι και προϋποθέσεις εφαρμόζονται και ισχύουν για την κάρτα “go for EAT” που εκδίδεται από την εταιρία με την επωνυμία “UP HELLAS ΑΝΩΝΥΜΗ 
                ΕΜΠΟΡΙΚΗ ΕΤΑΙΡΕΙΑ” (με ΑΦΜ: 800691552, έδρα Μητροπόλεως 3, Αθήνα T.K. 105 57, ΔΟΥ: ΦΑΕ ΑΘΗΝΩΝ) (στο εξής η “Κάρτα”) αλλά και σε οποιαδήποτε άλλη από 
                τις κάρτες που εκδίδονται από την UP Hellas και γίνονται δεκτές στο δίκτυο των συνεργαζόμενων με αυτή επιχειρήσεων.
            </p>
            <ol>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Ορισμοί</span><br/><br/>
                    Αριθµός Κάρτας: Ο αριθµός που αναγράφεται στην εμπρόσθια όψη της Κάρτας.<br/><br/>
                    Διαθέσιµη Αξία: Η αξία που είναι φορτισµένη στην Κάρτα και είναι διαθέσιµη για ανταλλαγή με γεύµατα ή τροφή στις Συμβεβληµένες Επιχειρήσεις του δικτύου της UP Hellas.<br/><br/>
                    Δίκτυο Up Hellas: Oι συμβεβλημένες επιχειρήσεις που δέχονται τις Κάρτες και τις διατακτικές που εκδίδει και αποπληρώνει η Up Hellas σύμφωνα με την παρούσα σύμβαση.<br/><br/>
                    Κάρτα “go for EAT”: Κάρτα πληρωμών που εκδίδεται από την UP Hellas και χορηγείται από εργοδότες προς τους εργαζομένους τους, προκειμένου οι τελευταίοι 
                    να τη χρησιμοποιήσουν στα καταστήματα του Δικτύου της UP Hellas. Η κάρτα είναι προπληρωμένη, ονομαστική, δυνάμενη να επαναπιστωθεί και λειτουργούσα 
                    άνευ επαφής (contactless). Η Κάρτα μπορεί, κατ’ επιλογήν του Πελάτη, να λειτουργεί είτε με προσωπικό κωδικό (PIN) είτε με υπογραφή του Κατόχου, εκδίδεται
                    βάσει σχετικής αδείας της MasterCard και δύναται να χρησιμοποιηθεί σε σημεία αγοράς εντός της Ελληνικής Δημοκρατίας (καταστήματα, υπεραγορές, σούπερ-μάρκετ,
                    εξειδικευμένα καταστήματα, εστιατόρια, καφέ, καντίνες) για την αγορά φαγητού και/ή για την πληρωμή γευμάτων.<br/><br/>
                    Κάτοχος Κάρτας: Είναι το πρόσωπο που έχει οριστεί από τον Συμβεβλημένο για τη χρήση της Κάρτας.<br/><br/>
                    Λογαριασµός: Είναι ο ηλεκτρονικός λογαριασµός που είναι συνδεδεμένος με την Κάρτα.<br/><br/>
                    Πελάτης: Τα φυσικά και νομικά πρόσωπα που προμηθεύονται τις Κάρτες και αγοράζουν Διατακτικές που εκδίδει η Up Hellas για να τις δώσουν στο προσωπικό 
                    τους ώστε να τις χρησιμοποιήσουν στο σύστημα Up Hellas.<br/><br/>
                    ΡΙΝ: Σηµαίνει τον Προσωπικό Αριθμό Αναγνώρισης της Κάρτας.<br/><br/>
                    Συμβεβλημένος: Κάθε επιχείρηση, συμπεριλαμβανομένων όλων των καταστημάτων και των σημείων πωλήσεών της, που παρέχει γεύματα, προϊόντα διατροφής,
                    έτοιμη τροφή προς κατανάλωση και είδη δώρων και έχει ενταχθεί στο Δίκτυο της Up Hellas. <br/><br/>
                    Συναλλαγή: Σηµαίνει οποιαδήποτε δικαιοπραξία στην οποία προβαίνουν με την Κάρτα οι Κάτοχοι αυτής.
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Χρήση της Κάρτας</span><br/><br/>
                    <ol>
                        <li>
                            Η Κάρτα δεν είναι πιστωτική κάρτα ούτε χρεωστική κάρτα. Η Κάρτα είναι µια επαναφορτιζόµενη προπληρωµένη κάρτα, που σηµαίνει ότι το διαθέσιµο 
                            υπόλοιπό της μειώνεται κατά την πραγματοποίηση συναλλαγών με αυτή. Προκειµένου να καταστεί εφικτή µια συναλλαγή θα πρέπει η αξία της να είναι 
                            µικρότερη ή ίση µε το διαθέσιµο υπόλοιπο στην Κάρτα.
                        </li>
                        <li>
                            Κύριος της Κάρτας είναι ο Κάτοχος αυτής.
                        </li>
                        <li>
                            Ο Κάτοχος δικαιούται να λάβει την Κάρτα και να κάνει χρήση αυτής µόνον αν έχει επιλεγεί και δηλωθεί ως Κάτοχος από τον Πελάτη προς την UP Hellas. 
                        </li>
                        <li>
                            Η Κάρτα ενεργοποιείται αυτομάτως μόλις πιστωθεί με χρήματα από τον Πελάτη, ο οποίος είναι και ο μόνος που δικαιούται να πιστώνει την Κάρτα. 
                            O Κάτοχος πρέπει να υπογράψει στο πεδίο υπογραφής στην οπίσθια όψη της Κάρτας. Με την πρώτη χρήση της Κάρτας ο Πελάτης και ο Κάτοχος της Κάρτας 
                            αποδέχονται και συναινούν και στους όρους και προϋποθέσεις της παρούσης.
                        </li>
                        <li>
                            Εφ’ όσον πρόκειται για Κάρτα που λειτουργεί με PIN, μαζί με την Κάρτα ο Κάτοχος θα λαμβάνει από τον Πελάτη σε ξεχωριστό φάκελο έναν τετραψήφιο ΡΙΝ, 
                            τον οποίο θα πρέπει να φυλάσσει πάντοτε σε ασφαλές μέρος και να µην αποκαλύψει ποτέ σε κανέναν, ούτε καν στους υπαλλήλους των συνδεδεμένων με το δίκτυο 
                            της UP Hellas επιχειρήσεων. 
                        </li>
                        <li>
                            Η Κάρτα µπορεί να χρησιμοποιηθεί σε επιχειρήσεις κάθε είδους που προσφέρουν γεύµατα ή τροφή έτοιµη για κατανάλωση οι οποίες έχουν συµβληθεί µε το δίκτυο 
                            της UP Hellas.
                        </li>
                        <li>
                            H UP Hellas μπορεί να περιορίσει, αναστείλει, ακυρώσει, να αρνηθεί ή να διακόψει την εξουσιοδότηση για χρήση της Κάρτας αν η χρήση της Κάρτας προκαλεί 
                            υποψίες ότι παραβιάζει τους όρους και προϋποθέσεις της παρούσης ή αν έχει λόγους να υποψιάζεται πως είτε ο Κάτοχος της Κάρτας ή οποιοδήποτε τρίτο µέρος 
                            έχει διαπράξει ή πρόκειται να διαπράξει κάποιο αδίκημα που σχετίζεται µε την Κάρτα. Στην περίπτωση που η UP Hellas αναστείλει ή ακυρώσει κάποια Κάρτα 
                            κατά τα ανωτέρω θα ενημερώσει σχετικά τον Κάτοχο μέσω του Πελάτη. Εφόσον η Κάρτα έχει ακυρωθεί ή έχει ανασταλεί η χρήση της, τότε απαγορεύεται ρητά η χρήση της. 
                        </li>
                        <li>
                            Σε περίπτωση που χρειαστεί να διερευνηθεί µια συναλλαγή που έγινε με χρήση της Κάρτας ο Πελάτης και Κάτοχος θα πρεπει να συνεργαστεί µε την UP Hellas ή 
                            µε όποιο άλλο εξουσιοδοτηµένο πρόσωπο, εφόσον τούτο ζητηθεί.
                        </li>
                        <li>
                            Ο Κάτοχος δεν θα επiτρέπει σε οποιοδήποτε τρίτο πρόσωπο τη χρήση της Κάρτας.
                        </li>
                        <li>
                            Ο Κάτοχος είναι υπεύθυνος για όλες τις συναλλαγες τις οποίες εξουσιοδοτεί για χρήση µε την υπογραφή του, με το ΡΙΝ ή με on-line σύνδεση. 
                            Ημερομηνία της συναλλαγής είναι η ημέρα κατά την οποία αυτή καταγράφεται στο τερματικό POS, στο οποίο χρησιμοποιήθηκε η Κάρτα. 
                        </li>
                        <li>
                            Ο Κάτοχος οφείλει να ενημερώνει άμεσα την UP Hellas σε κάθε περίπττωση απώλειας, κλοπής, απάτης ή άλλου κινδύνου µη εξουσιοδοτηµένης χρήσης της 
                            Κάρτας ή αν η Κάρτα καταστραφεί ή λειτουργεί πληµµελώς ή λανθασµένα.
                        </li>
                        <li>
                            Η Κάρτα προορίζεται για χρήση αποκλειστικά και µόνο εντός της Ελλάδας. Οποιαδήποτε συναλλαγή σε χώρα εκτός της Ελλάδας είναι απαγορευµένη.
                        </li>
                        <li>
                            Η UP Hellas διατηρεί το δικαίωµα να τροποποιεί µονοµερώς τους παρόντες γενικούς όρους και προϋποθέσεις προβαίνοντας σε σχετική ανάρτησης στο 
                            διαδικτυακό της τόπο ή στη σχετική εφαρμογή της UP για κινητά τηλέφωνα. 
                        </li>
                        <li>
                            Εφαρμογή UP Hellas: σε σχέση με την Κάρτα του ο Κάτοχος μπορεί να κάνει χρήση της σχετικής εφαρμογής για κινητά τηλέφωνα UP Hellas, η οποία είναι 
                            διαθέσιμη δωρεάν μέσω του App Store, του Google Play αλλά και του ιστότοπου <a href="https://app.uphellas.gr">app.uphellas.gr</a>. Μέσω της εφαρμογής ο Κάτοχος δύναται να ελέγχει το 
                            διαθέσιμο υπόλοιπο της Κάρτας, το ιστορικό συναλλαγών της Κάρτας, να ακυρώνει οριστικά ή να αναστέλλει προσωρινά τη λειτουργία της Κάρτας και να 
                            την επαναφέρει σε κανονική λειτουργία. 
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Λήξη, καταγγελία και παράδοση της Κάρτας</span><br/><br/>
                    <ol>
                        <li>
                            Η ηµεροµηνία λήξης της Κάρτας είναι τυπωµένη στην εµπρόσθια όψη της. Η Κάρτα δεν δύναται να χρησιµοποιηθεί μετά τη λήξη της.
                        </li>
                        <li>
                            Η UP Hellas δικαιούται να ακυρώσει την Κάρτα οποτεδήποτε µε άµεσο αποτέλεσμα, εφόσον ο Κάτοχος παραβιάσει τoυς παρόντες γενικούς όρους και 
                            προϋποθέσεις ή αν ο Κάτοχος χρησιµοποιήσει ή προτίθεται να χρησιμοποιήσει την Κάρτα µε τρόπο αμελή ή για δόλιους ή άλλους παράνοµους σκοπούς. 
                            Η UP Hellas δικαιούται επίσης να ακυρώσει την Κάρτα οποτεδήποτε µε άµεσο αποτέλεσμα, εφόσον λήξει καθ’ οιονδήποτε τρόπο η συμβατική της σχέση με τον Πελάτη.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Περιορισμός ευθύνης της UP Hellas</span><br/><br/>
                    <ol>
                        <li>
                            Η UP Hellas δεν έχει ευθύνη για οποιαδήποτε απώλεια κερδών ή εµπορικής δραστηριότητας ή για ευθείες ή έµµεσες ζημίες ή για διαφυγόντα κέρδη καθώς επίσης 
                            και για οποιαδήποτε ειδική ή αποζηµιωτική ευθύνη έναντι του Πελάτη ή του Κατόχου της Κάρτας.
                        </li>
                        <li>
                            Εάν κάποια Κάρτα είναι ελαττωματική ή δεν λειτουργεί καλά για οποιονδήποτε λόγο η ευθύνη της UP Hellas περιορίζεται στην υποχρέωση αντικατάστασης της Κάρτας.
                            Σε περίπτωση που για οποιονδήποτε λόγο υπάρξει αντικατάσταση Κάρτας εκ μέρους της UP Hellas, τότε οποιοδήποτε ποσόν υπάρχει ως υπόλοιπο πίστωσης στην καταργούμενη Κάρτα μεταφέρεται αυτομάτως στη νέα Κάρτα.
                        </li>
                        <li>
                            Σε περίπτωση που ο Κάτοχος έχει οποιαδήποτε διαφωνία σε σχεση µε Συναλλαγες που πραγµατοποίησε χρησιμοποιώντας την Κάρτα θα πρεπει να τις επιλύσει µε την
                            Συµβεβληµένη Επιχείρηση. Η UP Hellas δεν έχει ευθύνη για την ποιότητα, ασφάλεια, νοµιµότητα ή για οποιοδήποτε άλλο λόγο για τα αγαθά ή και τις υπηρεσίες
                            που αποκτούνται µε την Κάρτα από τις Συμβεβλημένες με το Δίκτυο της UP Hellas επιχειρήσεις.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Προσωπικά δεδομένα</span><br/><br/>
                    <ol>
                        <li>
                            Με την ενεργοποίηση της Κάρτας ο Πελάτης και ο Κάτοχος της Κάρτας αποδέχονται και συναινούν να τηρεί και επεξεργάζεται η UP Hellas προσωπικές 
                            πληροφορίες που αφορούν τον Πελάτη και τον Κάτοχο της Κάρτας για τους σκοπούς που σχετίζονται με την παρούσα.
                        </li>
                        <li>
                            Η UP Hellas δηλώνει ότι συμμορφώνεται με τον Γενικό Κανονισμό Προστασίας Δεδομένων (GDPR) και οποιαδήποτε σχετική εθνική νομοθεσία που ισχύει 
                            για την επεξεργασία προσωπικών δεδομένων σε σχέση με δεδομένα που που λαμβάνονται από τον Πελάτη και τον Κάτοχο της Κάρτας.
                        </li>
                        <li>
                            Η UP Hellas δηλώνει ότι εκτός αν απαιτείται από τον νόμο δεν θα αποκαλύψει τα προσωπικά δεδομένα και πληροφορίες του Κατόχου πέραν των αναγκαίων 
                            για τη λειτουργία της παρούσης, χωρίς τη συγκατάθεση του Κατόχου της Κάρτας.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Γενικά</span><br/><br/>
                    <ol>
                        <li>
                            Η ανοχή ή η καθυστέρηση εκ μέρους της UP Hellas δεν συνεπάγεται σε καμία περίπτωση την τροποποίηση της παρούσας και η UP Hellas διατηρεί το 
                            δικαίωμα να απαιτήσει οποτεδήποτε και χωρίς προειδοποίηση την εφαρμογή και την πλήρη εκτέλεση των όρων και προϋποθέσεων της παρούσης. 
                        </li>
                        <li>
                            Αν οποιαδήποτε διάταξη της παρούσας κριθεί παράνοµη ή ανεφόρµοστη οι υπόλοιπες διατάξεις θα συνεχίσουν σε πλήρη ισχύ και να παράγουν αποτελέσµατα. 
                        </li>
                        <li>
                            H παρούσα διέπεται αποκλειστικά από το Ελληνικό Δίκαιο και υπόκειται στη δικαιοδοσία των ελληνικών δικαστηρίων. 
                        </li>
                        <li>
                            Συμφωνείται ρητά ότι κάθε διαφορά σχετικά με την ερμηνεία ή την εκτέλεση της παρούσας και η οποία δεν μπορεί να λυθεί με συμφωνία μεταξύ των μερών, 
                            θα επιλύεται με τη μέθοδο της διαμεσολάβησης του Ν. 3898/2010, όπως ισχύει. Εάν η διαφορά δεν επιλυθεί με διαμεσολάβηση ως ανωτέρω μέσα σε έξι μήνες 
                            ή στο χρονικό διάστημα που θα συμφωνήσουν εγγράφως τα μέρη, θα φέρεται προς εκδίκαση ενώπιον των δικαστηρίων των Αθηνών τα οποία συμφωνείται με το 
                            παρόν ότι θα είναι αρμόδια για την εκδίκαση αυτής, συμπεριλαμβανομένης της διαδικασίας των ασφαλιστικών μέτρων και των δικών σχετικά με την εκτέλεση. 
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Τελικές διατάξεις</span><br/><br/>
                    <ol>
                        <li>
                            Κάθε επικοινωνία/αλληλογραφία θα γίνεται γραπτώς – μέσω ταχυδρομείου με απόδειξη παραλαβής, ή μέσω ταχυμεταφοράς, ή μέσω ηλεκτρονικού 
                            ταχυδρομείου στην διεύθυνση που ορίζουν τα μέρη.
                        </li>
                        <li>
                            Τα μέρη δηλώνουν ότι η παρούσα σύμβαση αντανακλά πλήρως την αληθινή βούληση των μερών, αποτέλεσε αντικείμενο ατομικής διαπραγμάτευσης 
                            και καθιστά άκυρα τυχόν προηγούμενα από αυτή έγγραφα.
                        </li>
                        <li>
                            Η σύμβαση αυτή εγένετο σε δύο αντίγραφα και υπεγράφη από τους νόμιμους εκπροσώπους των μερών, ένα για την Up Hellas και το άλλο για τον Πελάτη.
                        </li>
                    </ol>
                </li>
            </ol>
        </F>
    },
    session: {
        EXPIRED: 'Το session έληξε. Παρακαλώ συνδεθείτε ξανά.'
    }
};

const en = {
    forceAcceptTerms: {
        TITLE: 'The terms &amp; conditions have changed.',
        PRETEXT: 'Before you can proceed you must read and accept the new Terms &amp; Conditions:',
        ACCEPT_TERMS_LABEL: (<F>
            I have read and agree to the <a href="/terms-app" target="_blank">app</a>'s and <a 
            href="/terms-card" target="_blank">card</a>'s Terms &amp; Conditions
        </F>),
        ACCEPT_PRIVACY_POLICY_LABEL: (<F>
            I have read and agree to the <a href="/privacy-policy" target="_blank">privacy policy</a>
        </F>),
        SUBMIT: 'SUBMIT',
        HELP: {
            TERMS_AND_PRIVACY: 'You must accept the terms and conditions, and privacy policy, in order to browse the application'
        }
    },
    account: {
        MY_ACCOUNT: 'My Account',
        HELLO: 'Hello ',
        CHANGE_NAME: 'Change your Name',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        CHANGE_PASSWORD: 'Change your Password',
        OLD_PASSWORD: 'Previous Password',
        NEW_PASSWORD: 'New Password',
        REPEAT_NEW_PASSWORD: 'Repeat New Password',
        BS02_INCORRECT_PASSWORD: 'Invalid password',
        BS03_INVALID_PASSWORD: 'Invalid password, must be more than 3 characters.',
        BS04_PASSWORD_ALREADY_USED: 'Password already used.',
        PASSWORD_CHANGE_SUCCESS: 'Password successfully changed.',
        CHANGE_EMAIL: 'Change your Email',
        NEW_EMAIL: 'New Email',
        CHANGE_QUESTION: 'Change Security Question',
        SECURITY_QUESTION_SUCCESS: 'Security question successfully changed.',
        CHANGE_LANGUAGE: 'Change Language',
        LANG_GR: 'Ελληνικά / Greek',
        LANG_EN: 'Αγγλικά / English',
        CHANGE_PERMISSIONS: 'Change Permissions',
        CANCEL: 'Cancel',
        SUBMIT: 'Save',
        PERMISSIONS: {
            TITLE: 'Choose which from the following permissions you want to change',
            LOCATION: 'Track my Location',
            MARKETING_EMAILS: 'I want to receive Marketing Emails',
            SURVEYS: 'I want to participate in Surveys',
            CLOSE_DIALOG: 'Close'
        },
        UNEXPECTED_ERROR: 'Oops! Unfortunately, there was a problem. Our team is already looking into it.',
        HELP: {
            EMAIL: 'Fill in a valid email, different than the one you currently use.',
            PASSWORD: 'Fill in your password.',
            NEW_PASSWORD: 'Choose a password that is more than 3 characters.',
            NEW_PASSWORD_CONFIRM: 'Repeat your password.',
            SECURITY_ANSWER: 'Fill in the answer to the question you have selected above between 3 and 100 characters.',
        }
    },
    activate: {
        FAILED: 'The verification token was not found or the verification failed. Please try again.',
        SUCCEED: 'You have successfully verified your email!',
        BUTTON_OK: 'OK',
    },
    addCard: {
        ADD_CARD_TITLE: 'Add new Card',
        ADD_CARD_TEXT: 'Insert new card number and expiration date as it appears on the card.',
        CARD_NUMBER: 'Card Number',
        CARD_EXPIRY: 'Card Expiration Date',
        CANCEL: 'Cancel',
        ADD: 'Add',
        HELP: {
            CARD_NUMBER: 'Insert your card number.',
            CARD_EXPIRY: 'Insert your card expiration date.'
        },
    },
    affiliates: {
        AFFILIATES_TITLE: 'Merchant Partners Map',
        SEARCH_TITLE: 'Our Merchant Partners',
        SEARCH_PLACEHOLDER: 'Search by location, type, name of store',
        UP_CARD: 'GoforEat Card',
        UP_VOUCHER: 'Cheques Dejeuner Voucher',
        METERS: 'm',
        KMETERS: 'km',
        SEARCH_HERE: 'Search here'
    },
    bottomBar: {
        HOME: 'Home',
        MANAGE: 'Card Management',
        ADD: 'Add Card',
        MAP: 'Map',
    },
    confirmationDialog: {
        YES: 'Yes',
        NO: 'No',
    },
    contact: {
        CONTACT_TEXT: <F> Complete the form and a representative from Up Hellas will contact you.
        </F>,
        PHONE: 'Phone',
        MESSAGE: 'Message',
        CONTACT_TITLE: 'Contact',
        SUBMIT: 'Send',
        MESSAGE_SENT: 'Your message has been successfully sent.',
        HELP: {
            PHONE: 'Insert your phone.',
            MESSAGE: 'Write your message.'
        }
    },
    crediCard: {
        VALID_THRU: 'VALID THRU',
    },
    dashboard: {
        HEROCORP: 'HeroCorp',
        MY_BALANCE: 'My Balance',
        MY_BONUS: 'Η επιστροφή μου',
        MY_CARDS: 'My Cards',
        TRANSACTIONS: 'My Transactions',
        FROM: 'From',
        TO: 'To',
        SEARCH: 'Search',
    },
    errorAlert: {
        TITLE: 'Oops!',
        SUBTITLE: 'Unfortunately, there was a problem. Our team is already looking into it!',
        BODY1: 'Refresh the page and try again.',
        BODY2: 'If the error persists, contact us.',
    },
    networkError: {
        ALERT: 'There was a problem accessing the network. Please check your connection.'
    },
    faqText: {
        FAQ_TITLE: 'FAQ',
    },
    forgotPassword: {
        PAGE_TEXT: 'Fill in the email you used to sign in and we will send you a link to reset your password.',
        BACK_LABEL: 'Back',
        SUBMIT: 'Send',

        PS01_EMAIL_NOT_FOUND: 'Email not found.',
        FORGOT_PASSWORD_EMAIL_SENT: 'An email with reset link was sent to you!',
        HELP: {
            EMAIL: 'Insert your email.'
        }
    },
    forgotPasswordComplete: {
        PAGE_TEXT: 'Fill in your new password to login.',
        PASSWORD_LABEL: 'Password',
        PASSWORD_CONFIRMATION_LABEL: ' Repeat your password.',
        BACK_LABEL: 'Back',
        SUBMIT: 'Confirm',

        PS03_INVALID_PASSWORD: 'Invalid password, must be more than 3 characters.',
        PS04_PASSWORD_ALREADY_USED: 'Password already used.',
        PS06_TOKEN_NOT_FOUND: ' Token not found ',
        UNEXPECTED_ERROR: 'Oops! Unfortunately, there was a problem. Our team is already looking into it!',
        PASSWORD_UPDATED: 'Your password has changed!',

        HELP: {
            PASSWORD: 'Choose a password that is more than 3 characters.',
            PASSWORD_CONFIRM: 'Password must be identical.',
        }
    },
    login: {
        EMAIL_LABEL: 'Email',
        PASSWORD_LABEL: 'Password',
        LANGUAGE_TOGGLE: 'Ελληνικά / English',
        SUBMIT: 'Login',
        FORGOT_PASSWORD: 'Forgot your password?',
        FORGOT_PASSWORD_LINK: 'Click here',
        REGISTER: "Don't have an account?",
        REGISTER_LINK: 'Create an account',
        REMEMBER: 'Keep me signed in',
        INVALID_CREDENTIALS: 'Invalid username or password',
        HELP: {
            EMAIL: 'Fill in your email.',
            PASSWORD: 'Fill in your password.'
        }
    },
    manageCards: {
        labels: {
            ENABLE: 'Unfreeze your Card',
            DISABLE: 'Freeze your Card',
            ENABLE_CONTACTLESS: 'Enable Contactless',
            DISABLE_CONTACTLESS: 'Disable Contactless',
            DELETE: 'Block your Card',
        },
        MANAGE_CARDS: 'Card Management',
        ADD_CARD: 'Add Card',
        CARD_DELETE_SUCCESS: 'Card successfully blocked!!',
        CARD_ADD_SUCCESS: 'Card successfully added!',
        DELETE_CARD_TITLE: 'Block card',
        DELETE_CARD_QUESTION: <F>Blocking your card is permanent and irreversible. You will not be able to use your card again.<br />Are you sure you wish to block your card?</F>,
        AS03_ACCOUNT_ALREADY_USED: 'This account is already used.',
        AS06_NO_USER_FOUND: 'No user found based on these card details.',
        AS11_CARD_BLOCKED: 'This card is blocked.',
        UNEXPECTED_ERROR: 'Oops! Unfortunately, there was a problem. Our team is already looking into it!'
    },
    register: {
        LANG_GR: 'Ελληνικά / Greek',
        LANG_EN: 'Αγγλικά / English',
        PAGE_TITLE: 'Create an Account',
        CARD_NOTE: (<F>
            Fill in the following information, <br />
            as they appear on your card.
        </F>),
        CARD_NOTE_2: '',
        EMAIL_LABEL: 'Email',
        CARD_NUMBER_LABEL: 'Card Number',
        CARD_EXPIRY_LABEL: 'Card Expiration Date',
        PASSWORD_LABEL: 'Password',
        PASSWORD_CONFIRM_LABEL: 'Repeat Password',
        SECURITY_QUESTION_LABEL: 'Security Question',
        SECURITY_ANSWER_LABEL: 'Security Question Answer',
        ACCEPT_TERMS_LABEL: (<F>
            I have read and agree to the <a href="/terms-app" target="_blank">app</a>'s and <a 
            href="/terms-card" target="_blank">card</a>'s Terms &amp; Conditions
        </F>),
        ACCEPT_PRIVACY_POLICY_LABEL: (<F>
            I have read and agree to the <a href="/privacy-policy" target="_blank">privacy policy</a>
        </F>),
        SUBMIT: 'Register',
        CANCEL: 'Cancel',

        LS03_INVALID_PASSWORD: 'Invalid password, must be more than 3 characters.',
        LS05_EMAIL_ALREADY_USED: 'This email is already taken.',
        LS06_NO_USER_FOUND: 'No user found based on these details.',
        LS07_USER_ALREADY_REGISTERED: 'User is already registered.',
        LS10_CARD_ALREADY_REGISTERED: 'Card is already registered.',
        LS11_CARD_BLOCKED: 'This card is blocked.',
        UNEXPECTED_ERROR: 'Oops! Unfortunately, there was a problem. Our team is already looking into it!',
        HELP: {
            EMAIL: 'Insert your email.',
            CARD_NUMBER: 'Insert your card number.',
            CARD_EXPIRY: 'Insert your card expiration date.',
            PASSWORD: 'Choose a password that is more than 3 characters.',
            PASSWORD_CONFIRM: 'Password must be identical.',
            SECURITY_ANSWER: 'Fill in the answer to the question you have selected above between 3 and 100 characters.',
            TERMS_AND_PRIVACY: 'You must accept the terms and conditions, and privacy policy, in order to browse the application'
        }
    },
    securityQuestions: {
        1: 'What is your favorite movie?',
        2: 'What was the name of your first/current/favorite pet?',
        3: 'What is your favorite food?',
        4: 'Where is your favorite place to vacation?'
    },
    sidebar: {
        LOGIN: 'Login',
        ADD_CARD: 'Add your Card',
        FAQ: 'FAQ',
        MAP: 'Merchant Partners Map',
        PRIVACY_POLICY: 'Privacy Policy',
        TERMS_APP: 'Terms of use of UP Application',
        TERMS_CARD: 'Terms of use of Card',
        CONTACT: 'Contact',
        LOGOUT: 'Logout'
    },
    storeDialog: {
        OFFERS: 'WHAT IT OFFERS',
        ADDRESS: 'ADDRESS',
        PHONE: 'PHONE',
        HOURS: 'BUSINESS HOURS',
        WEBSITE: 'WEBSITE',
        ACCEPTS: 'ACCEPTS',
        DELIVERY: 'Delivery',
        ONLINE_ORDER: 'Online Order',
        POS: 'POS',
        MOBILE_POS: 'Mobile POS'
    },
    terms: {
        PRIVACY_POLICY: 'Privacy Policy',
        TERMS_APP_TITLE: 'Terms of use of UP Application',
        TERMS_CARD_TITLE: 'Terms of use of Card'
    },
    verifyEmail: {
        EMAIL_ALREADY_REGISTERED_TITLE: 'The registration failed.',
        EMAIL_ALREADY_REGISTERED_CONTENT: 'This email is already taken.',
        VERIFY_EMAIL_TITLE: 'Email Verification',
        VERIFY_EMAIL_TEXT: ' We have sent a confirmation message to your email. Click on the link in this message to verify the email address.'
    },
    faq:
        [
            {
                q: 'I have just received my card – what should I do?',
                a: <F>
                    The first thing to do is to sign it at the back.<br />
                    After that, your card is ready to be used.
                </F>
            },
            {
                q: 'What kind of card is this?',
                a: <F>
                    The go for Eat card is a prepaid meal card. It can only be loaded by your employer.
                </F>
            },
            {
                q: 'How do I activate my card?',
                a: <F>
                    As soon as your employer has loaded the card for the first time, your card has been activated.
                </F>
            },
            {
                q: 'Does my card have a PIN?',
                a: <F>
                    The type of card that you have received (with or without PIN) is decided by your employer.<br /><br />
                    If you haven't received a PIN code at the same time as your card, then your card doesn't have a PIN.
                    Once you pay, you may be asked to sign your receipt, so don't be surprised if this happens.<br /><br />
                    If you have received a PIN code with your card, then you will be asked to insert your PIN code for a non-contactless transaction.
                </F>
            },
            {
                q: 'Can I use my card abroad?',
                a: <F>
                    No, the card is a limited access card, meaning it can only be used to buy food products and meals in Greece.
                </F>
            },
            {
                q: 'How do I freeze my card?',
                a: <F>
                    You can freeze your card from the mobile application or our website <a href='https://app.uphellas.gr'>app.uphellas.gr</a><br />
                    You can find this function <a href='/manage'>here</a>.
                </F>
            },
            {
                q: 'How do I block my card?',
                a: <F>
                    You can block your card from the mobile application or our website <a href='https://app.uphellas.gr'>app.uphellas.gr</a><br />
                    You can find this function <a href='/manage'>here</a>.
                </F>
            },
            {
                q: 'What is the difference between card blocking and card freezing?',
                a: <F>
                    Freezing your card allows you to temporarily deactivate all transactions. You can un-freeze your card just by tapping the freeze button again to re-activate all transactions.
                    This function allows you to freeze your card in case you have lost it.<br /><br />
                    Blocking your card is definite. You should use this option as soon as you know you have lost your card. Once blocked, your card can't be unblocked.
                    You will need to contact your employer for a new card.
                </F>
            },
            {
                q: 'What happens after you block your card?',
                a: <F>
                    You will need to tell your employer about it. A new card will be issued, your outstanding balance on the lost card will be transferred to the new one.<br />
                    The new card will appear automatically and replace the old one in the application.
                </F>
            },
            {
                q: 'Can I use contactless payment?',
                a: <F>
                    All of our go for Eat cards offer contactless payments. Just tap and pay.
                </F>
            },
            {
                q: 'Where can I use my card?',
                a: <F>
                    Your card can be used to pay for meals in restaurants, tavernas, cafés, fast food restaurants and to purchase food related products at supermarkets that accept MasterCard. <br /><br />
                    Find out our affiliate network <a href='/affiliates'>here</a>
                </F>
            },
            {
                q: 'Can I use my card online?',
                a: <F>
                    Yes! Although few Greek restaurants/supermarkets accept online payment, the online payment option is enabled on your card.<br />
                    Feel free to insert your card number, type your CVV code and start ordering and paying your lunch!
                </F>
            },
            {
                q: 'Should I sign the receipt?',
                a: <F>
                    Yes, it's possible for you to be asked to sign a receipt – so don't be surprised if this happens.
                </F>
            },
            {
                q: 'Why is my transaction duplicated?',
                a: <F>
                    Don't worry, this can happen (it's rare though).<br />
                    Our systems are able to detect this, and you should be refunded of the extra amount deducted.
                    Nevertheless, if after 2 days we still haven't recredited your account, please contact us.
                </F>
            },
            {
                q: 'How can I contact Up Hellas?',
                a: <F>
                    You can contact us:<br />
                    Through the app: Up Hellas<br />
                    Through the website: <a href="https://www.uphellas.gr">www.uphellas.gr</a><br />
                    Through phone: 210 321 1022<br /><br />

                    Or click <a href='/contact'>here</a>!
                </F>
            },
        ],
    terms_content: {
        PRIVACY_POLICY: <F>
            <p style={{ textDecoration: 'underline', textAlign: 'center' }}><strong>PERSONAL DATA PROCESSING – PRIVACY NOTICE</strong></p><br />
            <p>
                This Privacy Notice on the personal data processing applies to all your interactions, in your capacity as user, with our Up Hellas app,
                as described in this document. The app is meant to be used by holders of at least one of the Up Hellas products, in electronic format.
            </p>
            <p>
                Your personal data are processed by <strong>UP HELLAS S.A.</strong>, Greek company having its registered offices at Mitropoleos 3, 10557 Athens, registered
                with the Trade Register under registration no13666600100, fiscal identification number 800691552 (hereinafter referred to as 
                <strong>„The Company”</strong> or <strong>„UP HELLAS”</strong>).
            </p>
            <p>
                The safety and confidentiality of your personal data is of the utmost importance to us, <strong>UP HELLAS</strong>.
                Therefore, we shall take all necessary and reasonable measures in order to make sure that your personal data remain secure and 
                confidential and that their processing is carried out according to the applicable legal provisions, exclusively for the purposes 
                mentioned below.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Α. Personal Data Processed</strong></p><br />
            <p>
                Please note that we process the following data as part of your interactions with the mobile app:
            </p>
            <ol>
                <li>identification data (full name as on the card);</li>
                <li>contact data (e-mail address);</li>
                <li>card number (of one of the electronic format UP HELLAS products that you use);</li>
                <li>location data;</li>
                <li>data on the transactions carried out (amounts, sellers) and available balance.</li>
            </ol>
            <br /><p style={{ textDecoration: 'underline' }}><strong>Β. Personal Data Collection</strong></p><br />
            <p>
                We collect personal data directly from yourself, either via the mobile app, or in the Company’s capacity as value voucher issuer –
                as part of the value vouchers’ issuance, management and refund processes; in the latter case, we kindly remind you that your name
                and surname are transmitted to us by your employer, for the purpose of issuing the cards for you, in accordance with the applicable
                legal provisions.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>C. The Purposes and Legal Grounds of the Processing</strong></p><br />
            <span style={{ textDecoration: 'underline' }}>User Account Setting Up and Management</span><br /><br />
            <p>
                In order to enable you to set up a user account within the mobile app, we process your e-mail address, your first name and surname your 
                security question and the password you define, as part of you having entered into an agreement with us. In this context, agreeing to the
                Terms and Conditions means that you have also accepted the conditions regulating use of the mobile app.
            </p>
            <p>
                Account setting up also means that you will have to type in the number of your card (of one of the UP HELLAS products that you are the
                holder of). This is construed to be, in fact, a security measure to ensure that you are the only person having access to the mobile app.
                These data are already processed by us, given the Company’s legal duty to do so, given its core business and its main scope of activity
                and in accordance with the special legislation applicable in the case of issue of value vouchers, in paper format and respectively 
                electronic format.
            </p>
            <p>
                From the section dedicated to your account user, you can also change your contact data, in this case the new contact data will replace
                the information you have previously provided (if applicable) – i.e. filling out or, sending transactional notifications and also 
                obtaining your card PIN when your employer had contracted for you such additional services). Your contact data are processed in order
                to enable us to send you e-mails regarding the setting up of your account and, to send you notifications with regard to the topping
                up of your cards and to the transactions carried out (provided that your Employer has requested us to provide this additional service).
            </p>
            <p>
                When you create a user account by means of the Up Hellas app, such account may be accessed either via Up Hellas app or the Up Online
                platform (https://app.uphellas.gr/). Expressing your preferences as regards the communications sent by Up Hellas may be done using
                any of the two means, and the deletion of the account will imply the impossibility of accessing your account both by means of the
                Up Hellas app and the Up Online platform.
            </p>
            <span style={{ textDecoration: 'underline' }}>Data on the Transactions Carried Out and Available Balance</span><br /><br />
            <p>
                In our capacity as card issuer, we have access to the transactions carried out a, which we process based on the requirements enforced
                upon us by the applicable legislation in force <strong>(i.e. legal obligations), in order to carry out the complete circuit of value vouchers,
                from issue to reimbursement. These data are also placed at your disposal via your mobile app.</strong>
            </p>
            <p>
                In any case, uninstalling the mobile app will not produce any effects as regards the existence of your user account and your preferences
                as expressed by you. You may further access your account at any time using the Up Online platform or by reinstalling the mobile app.
            </p>
            <span style={{ textDecoration: 'underline' }}>Push Notifications</span><br /><br />
            <p>
                The app generates push notifications regarding your transactions, based on our legitimate interest to keep you updated with regard to
                the approved transactions and balance available on your cards, in order to improve your user experience. Also, by means of push 
                notifications, Up Hellas may send to you information regarding amendments of Terms & Conditions or of the present Privacy 
                Notice or information regarding technical issues of the app or of our systems, based on our legitimate interest to keep you informed
                and to better preserve your rights as conferred by the law.
            </p>
            <p>
                You have the option of turning off the push notifications from your device, by going to the section dedicated to permissions granted
                to the mobile app.
            </p>
            <span style={{ textDecoration: 'underline' }}>Commercial and Marketing Communications</span><br /><br />
            <p>
                Moreover, we also process your contact data in order to send commercial and marketing communications, once you have given your consent
                to do so, either when installing the app or subsequently, by going to the Settings → Marketing section within the mobile app. 
                You can choose to receive such notifications on any of the three available channels: push notifications, sms or e-mail. 
                You have the right to withdraw your consent either by accessing the same section within the mobile app, or by sending a request 
                using our contact data below. Furthermore, all messages received by e-mail include a quick link that you can use to unsubscribe.
            </p>
            <span style={{ textDecoration: 'underline' }}>Polls</span><br /><br />
            <p>
                You may occasionally be invited to take part in different polls, via push notifications generated by the app, these being prompted
                based on our legitimate interest in finding out how pleased you are with the services we provide. Participation to polls is carried
                out free of charge, voluntarily and anonymously.
            </p>
            <span style={{ textDecoration: 'underline' }}>Location Data</span><br /><br />
            <p>
                Your mobile app can access your location, but only provided that you have given your consent to do so, via the appropriate settings
                of your device. Location data are not stored either in the app or on Up servers. These data are only used to enable you to make a
                selection of our affiliates or to choose among offers that they may have available, to display results that are most relevant to you,
                for the purpose of providing a mobile app user experience as good and as easy as possible. 
            </p>
            <p>
                You can choose at any time to withdraw the permission granted to our app to use your location data. Selections operated previously
                among our affiliates and their available offers shall no longer be possible afterwards. 
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>D. Storage Duration</strong></p><br />
            <p>
                <strong>Your user account shall be valid for as long as you use our mobile app. Should we find out that your account has been inactive for
                at least 12 consecutive months, it shall be deactivated. Moreover, you can also delete your user account at any time using the mobile app.
                The deletion of the user account implies the impossibility to access it both my means of the mobile app and the Up Online platform.</strong>
            </p>
            <p>
                However, deletion of your account shall engender no consequences with regard to the processing of your data needed in order to issue
                value vouchers for you or data that might be required in order to provide additional notification services, according to your Employer’s
                option. Otherwise, deletion of your account shall lead to deletion of your e-log-in data from our database and to unsubscribing you 
                from all the commercial communications sent by Up Hellas should you have previously chosen to receive such communications.
            </p>
            <p>
                For marketing purposes, we shall process your contact data for a two-year period or, should you have no longer agree to receive 
                commercial messages, until withdrawal of the consent given previously. Upon expiry of the two-year period, we may request you to 
                <strong>renew your consent, in order to be able to further send you such messages.</strong>
            </p>
            <p>
                Location data used by the app to filter results according to relevance of our partners and their offers for you shall not be stored at all.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>E. Third Parties</strong></p><br />
            <p>
                The Company is currently not transferring any of your personal data to legal entities or individuals outside the company. 
                Should this happen, you shall be informed with regard to any transfer of your personal data, unless such transfer or disclosure
                occurrences are explicitly provided by the European Union laws or by the national legislation and result from our duty to 
                comply with the legal provisions in force.
            </p>
            <p>
                Nevertheless, the developer of the mobile app may access your account data for maintenance purposes – with this service provider acting
                as a processor of Up Hellas we have concluded a data processing agreement, ensuring the confidentiality and the security of your personal data.
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>F. Personal Data Security</strong></p><br />
            <p>
                The security, integrity and confidentiality of your personal data are of the utmost importance for us. The Company shall therefore
                take all the organizational and technical in this respect.
            </p>
            <p>
                Should we discover any personal data security breach that may result in risks to your rights and liberties, we shall notify the
                National Authority for the Personal Data Processing <strong>(„ANSPDCP”)</strong> within 72 hours. You shall also be informed about the respective
                security incident, should it be likely to result in high risks to your personal rights and liberties.    
            </p>
            <br /><p style={{ textDecoration: 'underline' }}><strong>G. Your Rights</strong></p><br />
            <p>
                You have the following rights related to the processing of your personal data:
            </p>
            <ol>
                <li><strong>Right to access personal data.</strong> You have the right to request access to the personal data processed.</li>
                <li><strong>Right to rectify or delete personal data.</strong> You have the right to request rectification of incorrect or not 
                up-to-date personal data or deletion of personal data, for example in the event of the processing not being necessary or not 
                being legal or in the event of withdrawal of your consent to process some data, consent that you have previously given.
                Personal data to be retained according to the legal provisions in force cannot be deleted.</li>
                <li>
                    <strong>Right to restrict processing.</strong> Under specific circumstances set forth in the applicable legislation, 
                    you have the right to obtain restriction of your personal data processing.
                </li>
                <li>
                    <strong>Right to data portability.</strong> You are entitled to request that copies of your personal data that the company
                    processes are forwarded to either yourself or other data controllers, should such processing be based on your consent or on
                    performance of any agreement you may have concluded with the Company.
                </li>
                <li>
                    <strong>Right to object.</strong> You have the right to object at any time, for reasons related to your particular situation,
                    to the processing of your personal data, whenever such processing is based on our legitimate interests, and you can request that
                    we cease processing your personal data.
                </li>
            </ol>
            <br /><p style={{ textDecoration: 'underline' }}><strong>H. Exert Your Rights</strong></p><br />
            <p>
                Should you have any queries with regard to processing of your personal data or should you have any requests or wish to exert any 
                of your rights related to the processing of your personal data, please feel free to contact  our personal Data Processing Officer
                at the following e-mail address: gdpr@uphellas.gr. Each request shall be analyzed as soon as possible, but no later than one month.
            </p>
            <p>
                Should you consider that we haven’t met all your requests or should you be unsatisfied with our answers, you can file a complaint
                with the ANSPDCP. Furthermore, you can also bring the matter before the competent legal courts.
            </p>
            <p>
                Any changes of the information included in this Privacy Notice shall be brought to your attention forthwith – no modification
                of the Privacy Notice shall affect in any way whatsoever the rights granted by the law.
            </p>
            <br />
            <p>[Version from February 2020]</p>
        </F>,
        TERMS_CARD: <F>
            <p style={{ textDecoration: 'underline', textAlign: 'center' }}><strong>General Terms and Conditions of Use of the “go for EAT” Card of UP Hellas </strong></p>
            <p>
                These terms and conditions apply to the “go for EAT” Card issued by the company under the name “UP HELLAS TRADING COMPANY” (with TIN 800691552, 
                with headquarters at 3 Mitropoleos Str., Athens P.C. 105 57, Tax Office: FAE of Athens (hereinafter "Card") and to all other cards that issued by
                UP Hellas and are accepted in the network of affiliated companies. 
            </p>
            <ol>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Definitions</span><br/><br/>
                    Card Number: The number on the front of the Card.<br/><br/>
                    Value Available: Value charged to the Card and available for meal or feed exchange in Contracted Businesses of the UP Hellas network.<br/><br/>
                    Up Hellas Network: Affiliates accepting the Cards and orders issued and uplifted by Up Hellas under this agreement.<br/><br/>
                    "Go for EAT" Card: A payment card issued by UP Hellas and granted by the employers to their employees so that the latter can use it in UP Hellas Network stores. 
                    The card is prepaid, nominal, rechargeable and contactless. The card can be operated, at the Customer's choice, either by using the PIN or with the signature 
                    of the Cardholder, is issued under MasterCard license and may be used at points of purchase within the Hellenic Republic (shops, hypermarkets, supermarkets, 
                    specialized stores, restaurants, cafes, canteens) to buy food and/or to pay for meals.<br/><br/>
                    Cardholder: It is the person designated by the Contracting Party for the use of the Card.<br/><br/>
                    Account: It is the online account linked to the Card.<br/><br/>
                    Customer: The natural and legal persons who purchase the Cards and buy orders issued by Up Hellas to grant them to their staff in order to use them to Up Hellas system.<br/><br/>
                    PIN: Means the Personal Identification Number of the Card.<br/><br/>
                    Contracting Party: Every business, including all its stores and sales points, providing meals, food products, ready-to-eat food and gift items and has joined 
                    the Up Hellas Network.<br/><br/>
                    Transaction: It means any legal action that Cardholders carry out with it.
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Use of the Card</span><br/><br/>
                    <ol>
                        <li>
                            The Card is not a credit card or a debit card. The Card is a rechargeable prepaid card, which means that its available balance is reduced when trading with it. 
                            In order to make a transaction possible, its value should be less than or equal to the available balance on the Card.
                        </li>
                        <li>
                            The Owner of the Card is the Cardholder.
                        </li>
                        <li>
                            The Cardholder is entitled to receive the Card and make use of it only if he has been selected and declared as Cardholder by the Customer to UP Hellas.
                        </li>
                        <li>
                            The Card is automatically activated as soon as it is credited with money from the Customer, who is the only one entitled to credit the Card. 
                            The Cardholder must sign in the signature field on the back of the Card. With the first use of the Card, the Customer and the Cardholder accept and agree to the terms 
                            and conditions of this Card.
                        </li>
                        <li>
                            In case of Card that operate with PIN code, the Cardholder will receive from the Customer a separate envelope with a four-digit PIN code enclosed, 
                            which he/she must always keep in a safe place and never disclose to anyone, not even the employees of the affiliated companies of UP Hellas.
                        </li>
                        <li>
                            The Card may be used in businesses of any kind offering meal or ready-to-eat food contracted by the UP Hellas network.
                        </li>
                        <li>
                            UP Hellas may limit, suspend, cancel, deny or discontinue the authorization to use the Card if the use of the Card suspects that it violates the present
                            terms or conditions, or if it has reason to suspect that either the Cardholder or any third party has committed or is about to commit an offense related 
                            to the Card. In the event that UP Hellas suspends or cancels a Card as mentioned above, it will inform the Cardholder accordingly through the Customer. 
                            If the Card has been cancelled or its use has been suspended, it is expressly forbidden to use it. 
                        </li>
                        <li>
                            In the event that a Card Transaction has to be investigated, the Cardholder should cooperate with UP Hellas or any other authorized person upon request.
                        </li>
                        <li>
                            The Cardholder will not allow any third party to use the Card.
                        </li>
                        <li>
                            The Cardholder is responsible for all transactions that he authorizes for use with his signature, PIN or online connection. 
                            The transaction date is the day on which the transaction is recorded on the POS terminal where the Card was used.
                        </li>
                        <li>
                            The Cardholder must immediately inform UP Hellas of any loss, theft, fraud or other risk of unauthorized use of the Card, or whether the Card is 
                            damaged or malfunctions.
                        </li>
                        <li>
                            The Card is for use only within Greece. Any transaction in a country outside Greece is forbidden.
                        </li>
                        <li>
                            UP Hellas reserves the right to unilaterally modify these General Terms and Conditions by making a relevant posting on its website or in the relevant 
                            UP application for mobile phones.
                        </li>
                        <li>
                            UP Hellas Application: In relation to the Card, the Cardholder may use the relevant UP Hellas mobile application, which is available free through the App Store, 
                            Google Play and the <a href="https://app.uphellas.gr">app.uphellas.gr</a> website. Through the application, the Cardholder may check the available balance of the Card, the card transaction history, 
                            permanently cancel or temporarily suspend the operation of the Card and return it to normal operation.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Expiration, termination and delivery of the Card</span><br/><br/>
                    <ol>
                        <li>
                            The expiration date of the Card is printed on the front of the card. The Card cannot be used after its expiration.
                        </li>
                        <li>
                            UP Hellas is entitled to cancel the Card at any time with immediate effect if the Cardholder violates these General Terms 
                            and Conditions or if the Cardholder uses or intends to use the Card in negligent or for fraudulent or other non-proprietary purposes.
                            UP Hellas is also entitled to cancel the Card at any time with immediate effect, provided that its contractual relationship with the 
                            Customer is terminated in any way.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Limitation of liability of UP Hellas</span><br/><br/>
                    <ol>
                        <li>
                            UP Hellas is not responsible for any loss of profits or commercial activity or for direct or indirect damages or for 
                            lost profits as well as for any special or indemnifying liability to the Customer or the Cardholder.
                        </li>
                        <li>
                            If a Card is defective or does not work well for any reason, UP Hellas's liability is limited to the obligation to replace the Card. 
                            If for any reason a Card is replaced by UP Hellas, then any amount that exists as a credit balance on the canceled Card is automatically 
                            transferred to the new Card.
                        </li>
                        <li>
                            In the event that the Cardholder has any dispute regarding any Transactions that he has made using the Card, he/she shall resolve it 
                            with the Contracted Enterprise. UP Hellas is not responsible for the quality, safety, legitimacy or for any other reason of the goods 
                            or services acquired with the Card by the UP Hellas-Contracted Companies Network.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Personal data</span><br/><br/>
                    <ol>
                        <li>
                            With the activation of the Card, the Customer and the Cardholder agree and consent to maintain and process UP Hellas personal information 
                            regarding the Customer and the Cardholder for the purposes related to the present.
                        </li>
                        <li>
                            UP Hellas declares that it complies with the General Data Protection Regulation (GDPR) and any relevant national law applicable to the 
                            processing of personal data in relation to data received from the Customer and the Cardholder.
                        </li>
                        <li>
                            UP Hellas declares that, unless required by law, it will not disclose the Cardholder's personal information and information beyond what 
                            is necessary for the operation of the present without the consent of the Cardholder.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>General</span><br/><br/>
                    <ol>
                        <li>
                            The tolerance or delay by UP Hellas does not in any way entail the amendment of the present and UP Hellas reserves the right to require at 
                            any time and without notice the implementation and full implementation of the present terms and conditions.
                        </li>
                        <li>
                            If any provision of this Agreement is found to be unlawful or unenforceable, the rest of the provisions will continue to be in full force and effect.
                        </li>
                        <li>
                            The present is governed solely by Greek law and is subject to the jurisdiction of the Greek courts.
                        </li>
                        <li>
                            It is expressly agreed that any dispute concerning the interpretation or execution of the present and which cannot be settled by agreement 
                            between the parties shall be settled by the mediation method of Law 3898/2010, as in force. If the dispute is not resolved through mediation 
                            as above within six months or at such time as the parties agree in writing, they will be sued in the courts of Athens, which are hereby agreed 
                            to be responsible for the trial, including the proceedings for interim measures and proceedings relating to enforcement.
                        </li>
                    </ol>
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>Final provisions</span><br/><br/>
                    <ol>
                        <li>
                            Any Communication/correspondence shall be made in writing –by mail and with postal delivery receipt, by courier or by telephone/email at the 
                            address as specified by the parties.
                        </li>
                        <li>
                            The parties declare that this agreement fully reflects the true will of the parties, has been individually negotiated and renders void any prior documents.
                        </li>
                        <li>
                            This contract was drafted in two copies and signed by the legal representatives of the contracting parties, one for Up Hellas and another for the Customer.
                        </li>
                    </ol>
                </li>
            </ol>
        </F>
    },
    session: {
        EXPIRED: 'Your session has expired. Please login again.'
    }
};

export default lang => {
    if(lang === 'el') {
        return el;
    }

    return en;
}
