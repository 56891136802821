import React from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { 
    Grid,
    Button,
    TextField,
    withStyles 
} from '@material-ui/core'

import { flow } from 'lodash'

import { Api } from '../../components/shared/main'
import { ErrorModal } from '../../components/ui/main'

import Layout from './components/Layout'
import Loader from '../../components/Loader'
import localize from '../../locale'
import UserNotification from '../../components/UserNotification'
import { withValidation } from '../../components/withValidation'

import styles from './ForgotPassword.styles'

const validationSchema = {
    email: ['required', 'email']
}

class ForgotPassword extends React.Component {
    state = {
        email: ''
    }

    onChange = e => {
        const value = e.target.name === "email" 
            ? e.target.value.toLowerCase() : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    }

    submit = async e => {
        e.preventDefault();
        
        if(!this.props.validation.update(this.state)) {
            return;
        }

        const locale = localize(this.props.appState.lang).forgotPassword;
        
        Loader.show();

        try {
            await Api.forgotPassword({
                email: this.state.email
            });
    
            UserNotification.display(locale.FORGOT_PASSWORD_EMAIL_SENT);
        } catch (error) {
            if (error.code) {
                UserNotification.display(locale.PS01_EMAIL_NOT_FOUND);
                return;
            }

            ErrorModal.invoke(error);
        } finally {
            Loader.hide();
        }
    }

    render() {
        const { appState, classes, validation } = this.props;
        const locale = localize(appState.lang).forgotPassword;
        const helperText = validation.errorText(locale.HELP);
        const actions = <Grid item xs={12} className={classes.footer}>
                            <Grid container justify="center">
                                <Link to="/login">{locale.BACK_LABEL}</Link>
                            </Grid>
                        </Grid>

        return <Layout actions={actions}>
                    <Grid container justify="center" className={classes.formWrap}>
                        <Grid item xs={12}>
                            <p className={classes.textPanel}>{locale.PAGE_TEXT}</p>
                            <TextField type="email" name="email" required autoFocus margin="normal" fullWidth
                                label="E-mail"
                                value={this.state.email} onChange={this.onChange}
                                error={validation.error.email}
                                helperText={helperText.email} />
                        </Grid>
                        <Grid item xs={12} className={classes.buttonWrap}>
                            <Button type="submit" onClick={this.submit}>
                                {locale.SUBMIT}
                            </Button>
                        </Grid>
                    </Grid>
                </Layout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema)
])(ForgotPassword);
