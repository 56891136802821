import {
    Account,
    ChangeEmail,
    ChangeLanguage,
    ChangePassword,
    ChangeSecurityQuestion
} from './Account'
import Activate from './Activate'
import Affiliates from './Affiliates'
import Contact from './Contact'
import {
    Dashboard,
    ManageCards
} from './Dashboard'
import Error from './Error'
import Faq from './Faq'
import {
    ForgotPassword,
    ForgotPasswordComplete,
    Login
} from './Login'
import NotFound from './NotFound'
import Register from './Register'

import {
    PrivacyPolicy,
    TermsApp,
    TermsCard
} from './Terms'

export default {
    Account,
    Activate,
    Affiliates,
    ChangeEmail,
    ChangeLanguage,
    ChangePassword,
    ChangeSecurityQuestion,
    Contact,
    Dashboard,
    Faq,
    ForgotPassword,
    ForgotPasswordComplete,
    Error,
    Login,
    ManageCards,
    NotFound,
    PrivacyPolicy,
    Register,
    TermsApp,
    TermsCard
}