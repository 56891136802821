export default theme => ({
    '@global': {
        body: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: 14,
            fontWeight: 400,
            backgroundColor: '#fff',
        },
        a: {
            '&:not(.but)': {
                textDecoration: 'underline',
            },
            '&:hover': {
                textDecoration: 'none',
            }
        },
        p: {
            padding: 0,
            margin: '0 0 10px 0',
        },
        img: {
            display: 'block',
            '& responsive': {
                display: 'inline-block'
            }
        },
        input: {
            fontSize: 18,
            fontWeight: 300,
            '&[type=submit]': {
                backgroundColor: '#f99c24',
                color: '#000',
            },
        },
        
        button: {
            fontSize: 18,
            fontWeight: 300,
            backgroundColor: '#485756',
            color: '#fff',
            '&[type=submit]': {
                backgroundColor: '#485756',
                color: '#fff',
            },
        },
        
    },
});


