import React from 'react'
import classNames from 'classnames'
import { observer, inject } from 'mobx-react'

import {
    Button,
    Grid,
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'

import { Api } from '../components/shared/main'

import Layout from './Login/components/Layout'
import Loader from '../components/Loader'
import localize from '../locale'

import styles from './Activate.styles'

class Activate extends React.Component {
    state = {
        success: null
    }

    async componentDidMount() {
        const { userId, hash } = this.props.match.params;

        Loader.show();

        try {
            const { appState } = this.props;
            const response = await Api.activate(userId, hash);

            this.setState({
                success: true
            });

            if (appState.profile && appState.profile.id === response.data.id) {
                appState.profile.email = response.data.email;
            }
        } catch(error) {
            this.setState({
                success: false
            });
        } finally {
            Loader.hide();
        }
    }

    renderFailure() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).activate;
        return <Grid item xs={12}>
            <p className={classNames(classes.textPanel, classes.textCenter)}>{locale.FAILED}</p>
            <Grid item xs={12} className={classes.buttonWrap}>
                <Button type="submit" onClick={() => this.props.history.push('/')}>{locale.BUTTON_OK}</Button>
            </Grid>
        </Grid>
    }

    renderSuccess() {
        const { appState, classes } = this.props;
        const locale = localize(appState.lang).activate;
        return <Grid item xs={12}>
            <p className={classNames(classes.textPanel, classes.textCenter)}>{locale.SUCCEED}</p>
            <Grid item xs={12} className={classes.buttonWrap}>
                <Button type="submit" onClick={() => this.props.history.push('/login')}>{locale.BUTTON_OK}</Button>
            </Grid>
        </Grid>
    }

    renderContent() {
        if(this.state.success === null) {
            return null;
        }

        if(this.state.success === false) {
            return this.renderFailure();
        }

        return this.renderSuccess();
    }

    render() {
        const { classes } = this.props;

        return <Layout>
            <Grid container justify="center" className={classes.formWrap}>
                {this.renderContent()}
            </Grid>
        </Layout>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles)
])(Activate);
