import {
    withStyles,
    withWidth
} from '@material-ui/core'
import {
    AcUnit as DisabledCardIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import { flow } from 'lodash'
import React from 'react'

import { getCardImg } from '../../../components/cardUtils'

const styles = theme => ({
    root: {
        position: 'relative',
        width: 414,
        height: 264,
        padding: 30,
        color: '#fff',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        [theme.breakpoints.down('xs')]: {
            width: 340,
            height: 217
        },
    },

    number: {
        fontWeight: 500,
        position: 'absolute',
        fontSize: 26,
        top: 176,
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            top: 144,
        },
    },

    name: {
        position: 'absolute',
        maxWidth: 280,
        overflow: 'hidden',
        fontSize: 18,
        bottom:22,
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            bottom:18,
        },
    },

    label: {
        fontSize: 7
    },

    cardOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: 15,
        backgroundColor: '#cececeb3',
        display: 'none',
        '&.cardActive_false': {
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    disabledIcon: {
        width: 90,
        height: 90,
    },
});

const CreditCard = ({ classes, width, ...props }) => {
    const isSmallScreen = width === 'xs'

    const cardImg = { backgroundImage: "url(" + getCardImg(props.type, isSmallScreen ? 'small' : 'large') + ")" }
    
    return (
        <div className={classes.root} style={cardImg}>
            <div className={classNames(classes.cardOverlay, 'cardActive_'+props.active)}><DisabledCardIcon className={classes.disabledIcon} /></div>
            <div className={classes.number}>
                {props.number.replace(/(.{4})/g, '$1 ')}
            </div>

            <div className={classes.name}>
                {props.name}
            </div>
        </div>
    )
}

export default flow([
    withStyles(styles),
    withWidth()
])(CreditCard);
