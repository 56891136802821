import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    withStyles,
    FormHelperText
} from '@material-ui/core'

import { Api } from '../../components/shared/main'
import { ErrorModal } from '../../components/ui/main'

import CreditCardInput from '../../components/CreditCardInput'
import CreditCardExpiryInput from '../../components/CreditCardExpiryInput'
import Loader from '../../components/Loader'
import localize from '../../locale'
import NotificationDialog from '../components/NotificationDialog'
import SecurityQuestionSelect from '../components/SecurityQuestionSelect'
import { disableAutocomplete } from '../../components/uifix'
import UserNotification from '../../components/UserNotification'
import { withValidation } from '../../components/withValidation'

import classNames from 'classnames'
import { flow } from 'lodash'

import styles from './Register.styles'
import logo from '../../assets/images/logo-inner.png'
import card from '../../assets/images/card-text.png'
import arrowDown from '../../assets/images/ico-arrow-down.png'

const termsAndPrivacyValidator = (name, value, model) => !model.terms.active || !model.privacy.active;

const validationSchema = {
    email: ['required', 'email', { maxLength: 54 }],
    cardNumber: 'required',
    cardExpiry: 'required',
    password: ['required', 'password'],
    passwordConfirm: ['required', 'password', { match: 'password' }],
    securityQuestion: 'required',
    securityAnswer: ['required', { minLength: 3, maxLength: 100 }],
    termsAndPrivacy: termsAndPrivacyValidator
};

class Register extends React.Component {
    state = {
        email: '',
        cardNumber: '',
        cardExpiry: '',
        password: '',
        passwordConfirm: '',
        securityQuestion: 1,
        securityAnswer: '',
        terms: { active: false },
        privacy: { active: false }
    }

    constructor(props) {
        super(props);
        this.notificationDialog = React.createRef();
    }

    onChange = e => {
        const value = e.target.name === "email" 
            ? e.target.value.toLowerCase() : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    }

    onChangeLanguage = e => {
        this.props.appState.lang = e.target.value;
    }

    onChangeCheckbox = e => {
        const {
            checked,
            name
        } = e.target;

        this.setState({
            [name]: {
                active: checked,
                date: checked ? new Date().toISOString() : null
            }
        });
    }

    getErrorMessage = code => {
        const locale = localize(this.props.appState.lang).register;
        var errorMsg = locale.UNEXPECTED_ERROR;

        switch (code) {
            case 'LS03':
                errorMsg = locale.LS03_INVALID_PASSWORD;
                break;
            case 'LS05':
                errorMsg = locale.LS05_EMAIL_ALREADY_USED;
                break;
            case 'LS06':
                errorMsg = locale.LS06_NO_USER_FOUND;
                break;
            case 'LS07':
                errorMsg = locale.LS07_USER_ALREADY_REGISTERED;
                break;
            case 'LS10':
                errorMsg = locale.LS10_CARD_ALREADY_REGISTERED;
                break;
            case 'LS11':
                errorMsg = locale.LS11_CARD_BLOCKED;
                break;
            default:
        }

        return errorMsg;
    }

    submit = async e => {
        const { appState } = this.props;

        e.preventDefault();

        if (!this.props.validation.update(this.state)) {
            return;
        }

        Loader.show();

        try {
            await Api.register({
                ...this.state,
                email: this.state.email.toLowerCase().trim(),
                lang: appState.lang
            });

            const locale = localize(appState.lang).verifyEmail;
            this.notificationDialog.current.open(() => {
                this.props.history.push('/login');
            }, locale.VERIFY_EMAIL_TITLE, locale.VERIFY_EMAIL_TEXT);
        } catch (error) {
            if (error.code) {
                UserNotification.display(this.getErrorMessage(error.code));
                return;
            }

            ErrorModal.invoke(error);
        } finally {
            Loader.hide();
        }
    }

    cancel = () => {
        this.props.history.push('/');
    }

    render() {
        const { appState, classes, validation } = this.props;
        const locale = localize(appState.lang).register;
        const helperText = validation.errorText(locale.HELP);

        return <div className={classes.root}>
            <header className={classes.header}>
                <div className={classes.logo}>
                    <img src={logo} alt="Up Hellas" />
                </div>
                <div className={classes.panel}>
                    <div className={classNames(classes.container, 'container')}>
                        <h5>{locale.PAGE_TITLE}</h5>
                        <div className={classes.card}>
                            <img src={card} alt="Up Hellas Card" />
                            <p>{locale.CARD_NOTE}</p>
                            <span>
                                <img src={arrowDown} alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </header>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <form noValidate className={classes.regForm}>
                        <div className={classes.section}>
                            <div className={classes.formRow}>
                                <Select name="lang" value={appState.lang} onChange={this.onChangeLanguage} fullWidth>
                                    <MenuItem value="el">{locale.LANG_GR}</MenuItem>
                                    <MenuItem value="en">{locale.LANG_EN}</MenuItem>
                                </Select>

                                <TextField name="email" type="email" required
                                    label={locale.EMAIL_LABEL}
                                    value={this.state.email} onChange={this.onChange}
                                    error={validation.error.email}
                                    helperText={helperText.email}
                                    margin="normal" fullWidth />

                                <TextField name="cardNumber" required
                                    InputProps={{
                                        inputComponent: CreditCardInput,
                                    }}
                                    label={locale.CARD_NUMBER_LABEL}
                                    value={this.state.cardNumber} onChange={this.onChange}
                                    error={validation.error.cardNumber}
                                    helperText={helperText.cardNumber}
                                    margin="normal" fullWidth />

                                <TextField name="cardExpiry" required
                                    InputProps={{
                                        inputComponent: CreditCardExpiryInput,
                                    }}
                                    label={locale.CARD_EXPIRY_LABEL}
                                    value={this.state.cardExpiry} onChange={this.onChange}
                                    error={validation.error.cardExpiry}
                                    helperText={helperText.cardExpiry}
                                    margin="normal" fullWidth />
                            </div>
                        </div>

                        <div className={classes.separator} />

                        <div className={classes.section}>
                            <div className={classes.formRow}>
                                <TextField name="password" type="password" required
                                    label={locale.PASSWORD_LABEL}
                                    value={this.state.password} onChange={this.onChange}
                                    error={validation.error.password}
                                    helperText={helperText.password}
                                    margin="normal" fullWidth />

                                <TextField name="passwordConfirm" type="password" required
                                    label={locale.PASSWORD_CONFIRM_LABEL}
                                    value={this.state.passwordConfirm} onChange={this.onChange}
                                    error={validation.error.passwordConfirm}
                                    helperText={helperText.passwordConfirm}
                                    margin="normal" fullWidth />

                                <SecurityQuestionSelect 
                                    value={this.state.securityQuestion} onChange={this.onChange} />

                                <TextField name="securityAnswer" required
                                    label={locale.SECURITY_ANSWER_LABEL}
                                    value={this.state.securityAnswer} onChange={this.onChange}
                                    error={validation.error.securityAnswer}
                                    helperText={helperText.securityAnswer}
                                    margin="normal" fullWidth />

                                <div>
                                    <FormControl required error={validation.error.termsAndPrivacy}>
                                        <FormControlLabel label={locale.ACCEPT_TERMS_LABEL}
                                            control={
                                                <Checkbox name="terms" checked={this.state.terms.active}
                                                    onChange={this.onChangeCheckbox} />
                                        }/>
                                        <FormControlLabel label={locale.ACCEPT_PRIVACY_POLICY_LABEL}
                                            control={
                                                <Checkbox name="privacy" checked={this.state.privacy.active}
                                                    onChange={this.onChangeCheckbox} />
                                        }/>
                                        {validation.error.termsAndPrivacy && 
                                            <FormHelperText>{locale.HELP.TERMS_AND_PRIVACY}</FormHelperText>}
                                    </FormControl>
                                </div>

                                <div className={classes.buttonsWrap}>
                                    <Button variant="contained"
                                        type="reset" onClick={this.cancel} className={classes.buttonReset}>
                                        {locale.CANCEL}
                                    </Button>

                                    <Button variant="contained"
                                        type="submit" onClick={this.submit} className={classes.buttonSubmit}>
                                        {locale.SUBMIT}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Grid>
            </Grid>

            <NotificationDialog innerRef={this.notificationDialog} />
        </div>
    }
}

export default flow([
    observer,
    inject('appState'),
    withStyles(styles),
    withValidation(validationSchema),
    disableAutocomplete
])(Register);