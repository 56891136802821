export default theme => ({
    headingRow: {
        minHeight: 393,
        position: 'relative',
        backgroundColor: '#ececec',
        //backgroundImage: "url(" + heroImage + ")",
        //backgroundRepeat: "no-repeat",
        //backgroundPosition: "top left",
        //backgroundSize: 'cover',
    },
    graphicLeft: {
        position: 'absolute',
        left: 0,
        bottom:0
    },
    graphicRight: {
        position: 'absolute',
        right: 0,
        bottom:0,
        [theme.breakpoints.down('1070')]: {
            display:'none',
        },
    },
    graphicRightMobile: {
        position: 'absolute',
        right: 0,
        bottom:0,
        display:'none',
        [theme.breakpoints.down('1070')]: {
            display:'block',
        },
    },
    mainRow: {
        position: 'relative',
    },
    slideWrap: {
        marginTop: 40,
    },
    slideCard: {
        display: 'inline-block',
        width: '50%',
        padding: '30px 20px',
        float: 'left',
        [theme.breakpoints.down('850')]: {
            width: '100%',
            padding: '30px 0',
            float: 'none',
        },
        '& div': {
            float:'right',
            [theme.breakpoints.down('850')]: {
                margin: '0 auto',
                float: 'none',
            },
        },

    },
    slideCardData: {
        display: 'inline-block',
        width: '50%',
        padding: '30px 20px',
        float: 'left',
        [theme.breakpoints.down('850')]: {
            display: 'table',
            width: 320,
            padding: '30px 15px',
            margin: '0 auto 60px auto',
            float: 'none',
            textAlign: 'center',
            backgroundColor: '#ffffffd9',
        },
        '& h2': {
            marginTop: 6,
            marginBottom: 6,
            fontSize: 22,
        },
        '& h1': {
            fontSize: 73,
            lineHeight: '60px',
            fontWeight: 600,
            color: '#f59100',
            margin: 0,
            '& span': {
                fontSize: 58,
                '& i': {
                    fontSize: 30,
                    fontStyle: 'normal',
                }
            }
        },
        '& h6': {
            marginTop: 6,
            marginBottom: 6,
            fontSize: 16,
        },
        '& h3': {
            fontSize: 55,
            lineHeight: '40px',
            fontWeight: 600,
            color: '#f59100',
            margin: 0,
            '& span': {
                fontSize: 40,
                '& i': {
                    fontSize: 26,
                    fontStyle: 'normal',
                }
            }
        },
    },
    cardsSlider: {
        zIndex: '8',
        '& .slick-dots': {
            display: 'none',
        },
    },
    heroCorpContainer: {
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        '& div': {
            display: 'flex',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            padding: '7px 35px 0 35px',
            backgroundColor: '#485756',
            color: '#fff',
            '& h2': {
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                lineHeight: '32px'
            },
            '& h1': {
                margin: 0,
                fontSize: 36,
                fontWeight: 600,
                lineHeight: '32px',
                '& span': {
                    fontSize: 28,
                    '& i': {
                        fontSize: 30,
                        fontStyle: 'normal',
                    }
                }
            }
        }
    },
    mainHead: {
        backgroundColor: '#f59100',
        borderTop: '7px solid #485756',
    },
    iconCalendar: {
        color: '#fff',
        width: 41,
        height: 45,
    },
    transactionsDateSelect: {
        padding: '20px 0',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 40,
        },
        '& span': {
            display:'inline-flex',
            margin: '0 10px'
        },
        '& div': {
            margin: '0 10px',
            paddingTop: 3,
            minWidth: 250,
            [theme.breakpoints.down('sm')]: {
                minWidth: 'auto',
            },
        },
        '& > div': {
            [theme.breakpoints.down('sm')]: {
                width: '25%',
                minWidth: 'auto',
            },
        }
    },
    buttonWrap: {
        display:'inline-flex',
        flexDirection: 'column',
        verticalAlign: 'top',
        paddingTop: 0,
        minWidth: 'auto !important',
        width: 'auto !important',
        '& button': {
            [theme.breakpoints.down('xs')]: {
                padding: 8,
            },
        }
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
        '& div.slick-slider': {
            zIndex:11,
            [theme.breakpoints.down('850')]: {
                margin: '0 !important',
            },
            '& div.slick-next:before': {
                display:'none'
            },
            '& div.slick-prev:before': {
                display:'none'
            },
        },
        '& div.slick-slider *': {
            outline: 'none',
        },
        '& ul.slick-dots li': {
            width: 10,
            height:10,
        },
        '& ul.slick-dots li button': {
            width: 10,
            height:10,
            border: '2px solid ' + theme.palette.primary.main,
            borderRadius: '100%',
            lineHeight: 1,
        },
        '& ul.slick-dots li.slick-active button': {
            backgroundColor: theme.palette.primary.main,
        },
        '& ul.slick-dots li button:before': {
            content: 'none',
        },
    },
    headerContainer: {
        minHeight: 393
    },
    tContainer: {
        maxWidth: 1280,
        margin: '0 auto',
        overflow: 'hidden',
        '& div': {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            borderBottom: '1px solid #dfdfe2',
            '& p': {
                boxSizing: 'border-box',
                flex: 1,
                padding: '12px 40px',
                margin:0,
                overflow: 'hidden',
                '& span': {
                    display: 'block',
                }
            }
        },
    },
    tRow: {
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
    },
    tCellLeft: {
        fontSize: 18,
        color: '#000',
        '& span': {
            fontSize: 14,
            color: '#8e8e93',
        }
    },
    tCellRight: {
        fontSize: 20,
        fontWeight: 600,
        color: '#92c94c',
        alignSelf: 'center',
        '& span.tCell-CREDIT': {
            textAlign: 'right'
        },
        '& span.tCell-DEBIT': {
            textAlign: 'right',
            color: '#485756'
        },
        '& span.tCell-CASHBACK': {
            textAlign: 'right',
            color: '#ff9e19'
        },
    },
})