import { withStyles } from '@material-ui/core'
import { flow } from 'lodash'
import React from 'react'

import { getCardImg } from '../../../components/cardUtils'

const styles = theme => ({
    root: {
        position: 'relative',
        width: 315,
        height: 200,
        padding: 18,
        color: '#fff',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },

    number: {
        fontWeight: 300,
        position: 'absolute',
        fontSize: 20,
        top: 120
    },
    expiry: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        fontSize: 11,
        textAlign: 'center',
        top: 30,
        right: 0,
    },

    name: {
        position: 'absolute',
        maxWidth: 200,
        overflow: 'hidden',
        fontSize: 13,
        bottom:8,
    },

    label: {
        fontSize: 7
    },
});

const CreditCard = ({ classes, ...props }) => {
    const cardImg = { backgroundImage: "url(" + getCardImg(props.type) + ")" }

    return (
        <div className={classes.root} style={cardImg}>

            <div className={classes.number}>
                {props.number.replace(/(.{4})/g, '$1 ')}
                <div className={classes.expiry}>
                    <div className={classes.label}>
                        VALID THRU
                    </div>
                    <div>
                        {props.expiry}
                    </div>
                </div>
            </div>

            <div className={classes.name}>
                {props.name}
            </div>
        </div>
    )
}

export default flow([
    withStyles(styles)
])(CreditCard);
