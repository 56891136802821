import headingBack from '../Login/assets/back-pattern.png'

export default theme => ({
    headingRow: {
        position: 'relative',
        backgroundColor: '#ececec',
        borderBottom: '3px solid #ccb7ac',
        backgroundImage: "url(" + headingBack + ")",
        backgroundRepeat: "repeat",
    },
    container: {
        position: 'relative',
        maxWidth: 1280,
        margin: '0 auto',
        padding: '0 15px',
        '& p': {
            fontSize: 15,
            textAlign: 'center',
            '& span': {
                color: theme.palette.primary.main,
            }
        }
    },
    mainRow: {
        position: 'relative',
        '& > div': {
            maxWidth: 800,
            paddingTop: 30,
            margin: '0 auto',
        }
    },
    headingContainer: {
        display: 'inline-block',
        width: '100%',
    },
    headingWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        marginBottom: 20,
        '& input': {
            [theme.breakpoints.down('xs')]: {
                width: '270px !important',
            },
        },
    },
    accountIconFull: {
        color: theme.palette.primary.main,
        width: 120,
        height: 95,
    },
    panelText: {
        fontSize: 16,
        fontWeight: 400,
        color: '#757575',
        backgroundColor: '#fff',
        padding: '50px 40px',
        '& h1': {
            fontSize: 30,
            textAlign: 'center',
        },
    },
    searchTitle: {
        fontWeight: 500,
        marginBottom:'14px',
        textAlign: 'center',
    },
    searchContainer: {
        display: 'flex',
        '& > div > div': {
            [theme.breakpoints.down('xs')]: {
                width: '270px !important',
            },
        },
    },
    searchInput: {
        background: '#fff',
        borderRadius: '20px',
        width: '500px',
        padding: '5px 5px 5px 20px',
        color: '#f59100'
    },
    searchButton: {
        marginLeft: '5px',
        color: '#fff'
    },
    filters: {
        borderTop: '10px solid #485756',
        position: 'absolute',
        width: '100%',
        zIndex: '899',
        display: 'flex',
        justifyContent: 'center',
        '& div': {
            paddingLeft: '10px',
            paddingRight: '10px',
            background: '#485756',
            borderRadius: '0 0 20px 20px'
        },
        '& > div': {
            marginTop: '-10px'
        },
        '& label': {
            [theme.breakpoints.down('580')]: {
                marginRight: 0,
            }, 
        }
    },
    filterLabel: {
        color: '#fff',
        flex: 1,
        display: 'block',
        [theme.breakpoints.down('580')]: {
            paddingLeft: '4px !important',
        }, 
        '& img': {
            width: '38px',
            height: '18px',
            float: 'left',
            marginRight: '5px'
        },
        '& span': {
            [theme.breakpoints.down('580')]: {
                display:'none'
            },
        }
    },
    searchHereContainer: {
        width: '80%',
        position: 'absolute',
        left : '10%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color: '#fff',
        zIndex: 1000,
        [theme.breakpoints.down('440')]: {
            width: '240px',
            left : '0'
        }, 
    },
    searchHereButton: {     
        color: '#fff',
        background: '#f59100',
        '&:hover': {
            background: '#e08700'
        }
    },
    expandButton: {
        marginLeft: 10
    },
    storesCollapse: {
        display: 'flex',
        flex: 2
    },
    storesCollapseWrapper: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    storesList: {
        display: 'flex',
        flex: 3,
        overflow: 'scroll',
        justifyContent: 'center',
    },
    storesListItem: {
        display: 'flex',
        width: '80%'
    },
    storesListItemInfo: {
        width: '95%'
    },
    storesListItemButton: {
        width: '5%'
    }
});