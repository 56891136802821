import React from 'react'
import { observer, inject } from 'mobx-react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    withStyles
} from '@material-ui/core'

import { flow } from 'lodash'

import CreditCardInput from '../../../components/CreditCardInput'
import CreditCardExpiryInput from '../../../components/CreditCardExpiryInput'
import { disableAutocompleteApplyFix } from '../../../components/uifix'
import localize from '../../../locale'
import { withValidation } from '../../../components/withValidation'

const styles = theme => ({

});

const validationSchema = {
    cardNumber: ['required'],
    cardExpiry: ['required']
};

class AddCardDialog extends React.Component {
    state = {
        open: false,

        cardNumber: '',
        cardExpiry: ''
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    onEntered = () => {
        disableAutocompleteApplyFix(this.ref);
    }

    open = (onClose) => {
        this.setState({
            open: true,
            onClose
        });
    }

    cancel = () => {
        this.setState({
            open: false
        });
    }

    submit = async e => {
        e.preventDefault();

        if (!this.props.validation.update(this.state)) {
            return;
        }

        if (this.state.onClose) {
            const { cardNumber, cardExpiry } = this.state

            const succeeded = await this.state.onClose({
                cardNumber,
                cardExpiry
            });

            if (succeeded) {
                this.setState({
                    open: false
                });
            }
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const { appState, validation } = this.props;
        const locale = localize(appState.lang).addCard;
        const helperText = validation.errorText(locale.HELP);
        return <Dialog innerRef={this.ref} onEntered={this.onEntered} maxWidth='sm' open={this.state.open} onClose={this.cancel}>
            <DialogTitle>{locale.ADD_CARD_TITLE}</DialogTitle>
            <form noValidate>
                <DialogContent>
                    <DialogContentText>{locale.ADD_CARD_TEXT}</DialogContentText>

                    <TextField name="cardNumber" required autoFocus margin="normal" fullWidth
                        InputProps={{
                            inputComponent: CreditCardInput,
                        }}
                        label={locale.CARD_NUMBER}
                        value={this.state.cardNumber}
                        onChange={this.onChange}
                        error={validation.error.cardNumber}
                        helperText={helperText.cardNumber} />

                    <TextField name="cardExpiry" required margin="normal" fullWidth
                        InputProps={{
                            inputComponent: CreditCardExpiryInput,
                        }}
                        label={locale.CARD_EXPIRY}
                        value={this.state.cardExpiry}
                        onChange={this.onChange}
                        error={validation.error.cardExpiry}
                        helperText={helperText.cardExpiry} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel} variant="contained" color="secondary">{locale.CANCEL}</Button>
                    <Button type="submit" onClick={this.submit} variant="contained" color="primary">{locale.ADD}</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default flow([
    withStyles(styles),
    observer,
    inject('appState'),
    withValidation(validationSchema)    
])(AddCardDialog);